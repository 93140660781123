import React, {useEffect, useMemo, useState} from "react";
import {getSubscriptions} from "../../../../services/AdminSapce/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {cancelShopSubscription, getMyShop} from "../../../../services/SellerSpace/API";
import {formatCurrency} from "../../../../utils/utils";
import ShopSubscriptionHistory from "../../../../components/ShopSubscriptionHistory/ShopSubscriptionHistory";
import dayjs from "dayjs";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";

export default function ShopSubscriptions() {

    const [subscriptions, setSubscriptions] = useState([])
    const [shop, setShop] = useState(null)

    useEffect(() => {
        getSubscriptions().then((response) => {
            setSubscriptions(response)
        }).catch((e) => {
            toast.error(<ToastError message=""/>, toastStyle)
        })

        getMyShop().then((response) => {
            setShop(response.data.shop)
        }).catch((e) => {
            toast.error(<ToastError message=""/>, toastStyle)
        })
    }, []);
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const [cancelPopUp, setCancelPopUp] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCancelSubscription = (e) => {
        setLoading(true)
        e.preventDefault();
        const formData = new FormData(e.target);
        cancelShopSubscription(formData)
            .then((response) => {
                if (response.status === 201) {
                    toast.success(<ToastSuccess message="Abonnement annulé avec succès"/>, toastStyle)
                }
            })
            .catch((e) => {
                toast.error(
                    <ToastError
                        message="Une demande d’annulation en attente existe déjà."/>, toastStyle
                )
            })
            .finally(() => {
                setLoading(false);
                updatePopUpVisible(false)
                setCancelPopUp(false)
            })
    }

    return (
        <div
            className="w-full min-h-[calc(91vh-40px)] max-h-[calc(91vh-40px)] overflow-y-auto noScrollBar flex flex-col p-4 bg-white ">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-col items-start gap-1">
                    <h1 className="text-xl font-medium">Facturation et abonnements</h1>
                    <p className="text-gray-500">Gérez vos forfaits et votre historique de facturation ici.</p>
                </div>
                <button
                    onClick={() => {
                        if (shop?.status === "subscribed") {
                            setCancelPopUp(true)
                            updatePopUpVisible(true)
                        }
                    }}
                    className={`w-[120px] sm:w-fit flex flex-row items-center justify-center ${shop?.status === "subscribed" ? "bg-primaryLight cursor-pointer":"bg-gray-500 cursor-not-allowed"} px-6 py-2 rounded min-h-12`}>
                    <p className="font-medium text-white text-ellipsis whitespace-nowrap overflow-hidden">Annuler
                        l'abonnement</p>
                </button>
            </div>
            <div className='flex flex-row flex-wrap gap-2 w-full items-center justify-center mt-8'>
                {shop?.status === "subscribed" ? subscriptions.map((subscription, index) => (
                        <SmallSubscriptionTile
                            shopData={{subscription_id: shop?.subscriptions[0]?.subscription_id}}
                            end_date={shop?.subscriptions[0]?.end_date}
                            key={index}
                            index={index}
                            name={subscription.name}
                            price={subscription.price}
                            description={subscription.description}
                            duration={subscription.duration_days}
                            handleSelect={() => {
                            }}
                            id={subscription.id}
                            displayActions={false}
                        />
                    ))
                    :
                    <div className="bg-primaryExtraLight rounded-lg flex items-center justify-center w-full h-[10vh]">
                        <p className="text-primaryLight font-medium">Vous n'avez pas d'abonnement actif</p>
                    </div>
                }
            </div>
            <ShopSubscriptionHistory maxHeight={"60vh"}/>
            <form
                onSubmit={handleCancelSubscription}
                style={{display: cancelPopUp ? "flex" : "none"}}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex-col bg-white border-border border rounded w-full sm:w-fit">
                <div className="min-h-14 flex items-center justify-between px-4 border-b border-border">
                    <p className="font-medium">Annuler l'abonnement</p>
                    <div
                        onClick={() => {
                            setCancelPopUp(false)
                            updatePopUpVisible(false)
                        }}
                        className="flex items-center justify-center rounded-full h-10 w-10 cursor-pointer hover:bg-gray-50">
                        <img src="/resources/close.svg" alt="close" className="w-4 cursor-pointer"/>
                    </div>
                </div>
                <div className="p-4">
                    <p className="text-gray-800 font-medium">Êtes-vous certain de vouloir annuler votre
                        abonnement&nbsp;?</p>
                    <p className="text-gray-500">Vous avez la liberté d'annuler votre abonnement à tout moment.
                        L'annulation sera traitée après approbation de l'administrateur, et un remboursement sera
                        effectué pour les jours non utilisés.</p>
                    <div className="flex flex-col gap-4 mt-4">
                        <textarea
                            name={"seller_reason"}
                            className="w-full p-2 border border-gray-300 rounded min-h-[20vh] resize-none focus:outline-primaryLight"
                            placeholder="Veuillez justifier la raison de l'annulation"
                            required
                        />
                        <div className="flex flex-row items-center justify-end gap-4">
                            <button
                                onClick={() => {
                                    setCancelPopUp(false)
                                    updatePopUpVisible(false)
                                }}
                                className="bg-gray-200 px-6 py-2 rounded min-h-12">
                                <p className="font-medium text-gray-500">Annuler</p>
                            </button>
                            <button
                                type={"submit"}
                                className="bg-primaryLight px-6 py-2 rounded min-h-12 flex flex-row gap-2 items-center justify-center">
                                {
                                    !loading ?
                                        <img src="/resources/save.svg" alt="save"/>
                                        :
                                        <div className="w-5">
                                            <div className="loader_white"></div>
                                        </div>
                                }
                                <p className="font-medium text-white">Confirmer</p>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export const SmallSubscriptionTile = ({
                                          name,
                                          price,
                                          description,
                                          duration,
                                          index,
                                          id,
                                          handleSelect,
                                          shopData,
                                          end_date,
                                          displayActions = true,
                                      }) => {
    // Calculate the number of days until the renewal date dynamically
    const daysUntilRenewal = useMemo(() => {
        if (!end_date) return null;
        const today = dayjs(); // Get today's date
        const renewalDate = dayjs(end_date); // Parse the end date
        return renewalDate.diff(today, 'day'); // Calculate the difference in days
    }, [end_date]);

    return (
        <div
            style={{borderColor: shopData.subscription_id === id ? "#E44826" : "#E5E7EB"}}
            onClick={() => handleSelect(id)}
            className="relative flex flex-col items-start justify-between w-[350px] h-fit bg-white rounded-lg shadow-md p-4 border-2 cursor-pointer"
        >
            <h2 className="font-semibold mb-2 text-gray-800">
                {name}
            </h2>
            {shopData.subscription_id === id && end_date ?
                <div
                    className="absolute top-0 right-0 border-border border font-bold text-sm px-6 py-2 bg-white rounded text-black">
                    renouveler dans {daysUntilRenewal} jours
                </div>
                :
                <div
                    className="absolute top-0 right-0 border-primaryLight border font-bold text-sm px-6 py-2 bg-primaryExtraLight rounded text-primaryLight">
                    {duration} jours
                </div>
            }
            <div className="flex flex-col items-start gap-4 mt-2">
                <p className="text-3xl font-bold text-gray-800 mb-4">{formatCurrency(price)}</p>
            </div>
            <p className="text-gray-600 leading-normal text-sm truncate max-w-full">{description}</p>
            {displayActions && <button
                onClick={() => handleSelect(id)}
                disabled={shopData.subscription_id === id}
                className={`mt-4 px-4 min-h-12 w-full py-2 rounded transition duration-300 
        ${shopData.subscription_id === id ? "bg-gray-200 cursor-not-allowed" : "bg-primaryLight hover:bg-primaryDark"}`}
            >
                <p className={`text-sm font-medium leading-none 
        ${shopData.subscription_id === id ? "text-gray-500" : "text-white"}`}>
                    {shopData.subscription_id === id ? "plan actuel" : "changer d'abonnement"}
                </p>
            </button>
            }

        </div>
    );
};
