import axios from "axios";
import Cookies from "js-cookie";

console.log("API URL", process.env.REACT_APP_API_URL);

export let api = axios.create({
    baseURL : process.env.REACT_APP_API_URL,
    headers : {
        "Authorization" : "Bearer my_token",
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
    },
})



api.interceptors.request.use(
    (request)=>{
        const token = Cookies.get("token") || "my_token";
        request.headers["Authorization"] = "Bearer " + token;
        return request;
    }
)

// used to
export let api2 = axios.create({
    baseURL : process.env.REACT_APP_API_URL,
    headers : {
        "Authorization" : "Bearer my_token",
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
    },
})