import WidgetElement from "../../components/WidgetElement/WidgetElement";
import SellerOrderTable from "../../../../components/SellerOrderTable/SellerOrderTable";
import {useEffect, useState} from "react";
import {getDashboardStats} from "../../../../services/SellerSpace/API";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {toast} from "sonner";
import {
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
    Area,
    AreaChart
} from "recharts";
import {calculatePercentageChange, formatCurrency} from "../../../../utils/utils";
import Cookies from "js-cookie";

export default function Dashboard() {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        getDashboardStats()
            .then((response) => {
                if (response.status === 200) {
                    Cookies.set("stats", JSON.stringify(response.data));
                    setStats(response.data);
                }
            }).catch((error) => {
            toast.error(<ToastError
                message={"une erreur s'est produite lors de la récupération des statistiques"}/>, toastStyle);
        });
    }, []);

    const chartData = stats?.monthly_revenue_stats || [];
    const chartData2 = stats?.monthly_stock_value_stats || [];

    // Helper to check if all data points are 0
    const isAllZero = (data, key) => {
        return data.every(item => item[key] === 0);
    };

    const hasRevenueData = !isAllZero(chartData, "revenue");
    const hasStockData = !isAllZero(chartData2, "stock_value");

    // Custom formatter for Y-axis values
    const formatYAxisValue = (value) => {
        return value >= 1000 ? `${(value / 1000).toFixed(1)}K` : value;
    };

    // Custom formatter for X-axis dates
    const formatXAxisDate = (dateString) => {
        const date = new Date(dateString);
        const options = {month: '2-digit', day: '2-digit'};
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <div
            className="grid grid-cols-1 xl:grid-cols-[80%_18%] max-h-[calc(91vh-40px)] noScrollBar overflow-y-auto py-2 px-1 sm:px-4 gap-4">
            {/* Main content column */}
            <div className="flex flex-col gap-4 max-h-full">
                {/* First row of content */}
                <div className="grid grid-cols-1 lg:grid-cols-[49%_49.5%] gap-4 min-h-[380px]">
                    <div className="bg-white border-border border rounded-lg flex flex-col gap-3 p-4 pb-0">
                        <div className="flex flex-col gap-1">
                            <p className="text-gray-500 font-medium text-lg">
                                Revenu total des ventes <span className="text-sm text-black font-medium">(hors commandes en attente *)</span>
                            </p>
                            <h2 className="text-3xl font-bold">
                                {formatCurrency(stats?.total_revenues) || "$0"}
                            </h2>
                        </div>
                        <ResponsiveContainer width="100%" height={300}>
                            {hasRevenueData ? (
                                <AreaChart data={chartData}>
                                    <defs>
                                        <linearGradient id="gradient" x1="0" y1="1" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#FFE9E4"/>
                                            <stop offset="50%" stopColor="#FF7354"/>
                                            <stop offset="100%" stopColor="#ff5722"/>
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="date" tickFormatter={formatXAxisDate} tick={{fontSize: 14}}/>
                                    <YAxis tickFormatter={formatYAxisValue} tick={{fontSize: 14}}/>
                                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`}/>
                                    <Area
                                        type="monotone"
                                        dataKey="revenue"
                                        stroke="url(#gradient)"
                                        fillOpacity={0.1}
                                        fill="url(#gradient)"
                                        strokeWidth={3}
                                        dot={{r: 5}}
                                        activeDot={{r: 8, stroke: "#fff", strokeWidth: 2}}
                                    />
                                </AreaChart>
                            ) : (
                                <div className="flex justify-center items-center h-full">
                                    <p className="text-gray-500">No data available</p>
                                </div>
                            )}
                        </ResponsiveContainer>
                    </div>
                    <div className="bg-white border-border border rounded-lg flex flex-col gap-3 p-4 pb-0">
                        <div className="flex flex-col gap-1">
                            <p className="text-gray-500 font-medium text-lg">
                                valeur totale de l'inventaire
                            </p>
                            <h2 className="text-3xl font-bold">
                                {formatCurrency(stats?.total_stock_value) || "$0"}
                            </h2>
                        </div>
                        <ResponsiveContainer width="100%" height={300}>
                            {hasStockData ? (
                                <AreaChart data={chartData2}>
                                    <defs>
                                        <linearGradient id="gradient" x1="0" y1="1" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#FFE9E4"/>
                                            <stop offset="50%" stopColor="#FF7354"/>
                                            <stop offset="100%" stopColor="#ff5722"/>
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="date" tickFormatter={formatXAxisDate} tick={{fontSize: 14}}/>
                                    <YAxis tickFormatter={formatYAxisValue} tick={{fontSize: 14}}/>
                                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`}/>
                                    <Area
                                        type="monotone"
                                        dataKey="stock_value"
                                        stroke="url(#gradient)"
                                        fillOpacity={0.1}
                                        fill="url(#gradient)"
                                        strokeWidth={3}
                                        dot={{r: 5}}
                                        activeDot={{r: 8, stroke: "#fff", strokeWidth: 2}}
                                    />
                                </AreaChart>
                            ) : (
                                <div className="flex justify-center items-center h-full">
                                    <p className="text-gray-500">No data available</p>
                                </div>
                            )}
                        </ResponsiveContainer>
                    </div>
                </div>
                {/* Second row with table */}
                <SellerOrderTable maxHeight={"60vh"}/>
            </div>

            {/* Side widgets */}
            <div className='flex flex-row lg:flex-col flex-wrap gap-4'>
                <WidgetElement title="Revenus d'aujourd'hui"
                               amount={formatCurrency(stats?.todays_revenues || 0)}
                               percentage={calculatePercentageChange(stats?.todays_revenues, stats?.yesterdays_revenues)}
                />
                <WidgetElement title="Commandes d'aujourd'hui"
                               amount={stats?.todays_orders || 0}
                               percentage={calculatePercentageChange(stats?.todays_orders, stats?.yesterdays_orders)}
                />
                <WidgetElement title="Services réservés aujourd'hui"/>
            </div>
        </div>
    );
}
