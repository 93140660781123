import React, {useEffect, useState} from "react";
import {getVehicules} from "../../services/SellerSpace/API";
import {create} from "zustand";
import {useNavigate} from "react-router";
import {useMarketplaceContext} from "../../pages/Marketplace";
import {useTranslation} from "react-i18next";
import GenericSelect from "../GenericSelect/GenericSelect";

export default function CompatibleVehicles() {
    const {t} = useTranslation();
    const hideMenu = () => {
        updateVisible(false);
        updatePopupVisible(false);
    }
    const [selectedType, setSelectedType] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedMotorisation, setSelectedMotorisation] = useState(null);
    const {
        visible,
        updateVisible,
        type,
        brand,
        model,
        motorisation,
        updateType,
        updateModelId,
        updateBrandId,
        updateMotorisationId,
    } = useCompatibleVehicleContext();
    const {updatePopupVisible} = useMarketplaceContext();

    useEffect(() => {
        if (type) {
            setSelectedType(type);
        }
        if (brand) {
            setSelectedBrand(brand);
        }
        if (model) {
            setSelectedModel(model);
        }
        if (motorisation) {
            setSelectedMotorisation(motorisation);
        }
    }, [brand, model, motorisation, type]);

    const [step, setStep] = useState(1);

    const handleBack = () => {
        if (step === 1){
            setStep(1);
            setSelectedType(null);
            updateType(null);
            hideMenu()
        }
        if (step === 2) {
            setStep(1);
            setSelectedBrand(null);
            updateModelId(null);
            updateBrandId(null);
            updateMotorisationId(null);
        }
        if (step === 3) {
            setStep(2);
            setSelectedModel(null);
            updateModelId(null);
            updateMotorisationId(null);
        }
        if (step === 4) {
            setStep(3);
            setSelectedMotorisation(null);
            updateModelId(null)
            updateMotorisationId(null);
        }
    }

    const [searchTerm, setSearchTerm] = useState('')
    const navigate = useNavigate();

    return (
        <div
            style={{
                right: visible ? 0 : '-100%',
            }}
            className="fixed top-0 h-screen bg-white z-[100] w-full sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] transition-all duration-500 ease-in-out flex flex-col"
        >
            {/* Header */}
            <div className="w-full h-14 flex items-center justify-between px-6 border-b border-gray-200">
                <div className="flex items-center gap-2">
                    <img src="/resources/car.svg" alt="car"/>
                    <p className="font-semibold">{t('searchbyvehicle')}</p>
                </div>
                <button
                    onClick={hideMenu}
                    className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200"
                >
                    <img src="/resources/close.svg" alt="close" className="w-3"/>
                </button>
            </div>

            <div className="flex flex-col h-full">
                <div className="flex flex-row justify-between items-center p-4 border-b border-b-border">
                    <ul className="flex flex-col gap-4">
                        <li className={`step ${selectedType ? 'font-semibold text-blue-500' : 'text-gray-400'}`}>1. type
                            : {type ? type === "cars" ? "Voiture" : type === "motorcycles" ? "Moto" : "Camion" : t('notSelected')}</li>
                        <li className={`step ${selectedBrand ? 'font-semibold text-blue-500' : 'text-gray-400'}`}>2. {t('brand')} : {brand ? brand.name : t('notSelected')}</li>
                        <li className={`step ${selectedModel ? 'font-semibold text-blue-500' : 'text-gray-400'}`}>3. {t('model')} : {model ? model.name : t('notSelected')}</li>
                        <li className={`step ${selectedMotorisation ? 'font-semibold text-blue-500' : 'text-gray-400'}`}>4. {t('motorisation')} : {motorisation ? motorisation.name : t('notSelected')}</li>
                    </ul>
                    <div
                        onClick={() => {
                            setSelectedType(null);
                            setSelectedBrand(null);
                            setSelectedModel(null);
                            setSelectedMotorisation(null);
                            updateType(null);
                            updateBrandId(null);
                            updateModelId(null);
                            updateMotorisationId(null);
                            setStep(1);
                        }}
                        className="px-2 gap-2 h-10 bg-primaryLight rounded cursor-pointer text-white font-medium flex items-center justify-center">
                        <img src="/resources/delete-black.svg" alt="cancel icon" className="white_filter w-5"/>
                        <p className="truncate max-w-[20vw]">Annuler les paramètres</p>
                    </div>
                </div>
                <div className="flex items-center justify-center w-full p-4">
                    <div
                        className="w-[34vw] sm:w-[17vw] h-14 bg-[#ECECEC] border-[#E5E7EB] border px-4 flex flex-row items-center rounded-lg justify-between relative">
                        <div className="flex flex-row items-center gap-2">
                            <input
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                                className="w-[25vw] sm:w-[10vw] lg:w-[10vw] h-9 bg-[#ECECEC] border-none focus:outline-none"
                                placeholder={t('search')}/>
                        </div>
                        <img src="/resources/search.svg" alt="search icon"/>
                    </div>
                </div>
                <VehiclesTable handleClose={hideMenu} stepState={[step, setStep]}
                               searchState={[searchTerm, setSearchTerm]}/>
                <div className="min-h-1/4 flex w-full flex-row border-t border-t-border p-6 justify-between">
                    <div
                        onClick={handleBack}
                        className="border border-border self-start rounded-lg flex flex-row gap-2 items-center justify-center w-fit px-4 min-h-10 hover:bg-gray-50 cursor-pointer">
                        <img src="/resources/left-line.svg" className="w-4" alt="arrow_right"/>
                        <p className="font-medium ">{t('back')}</p>
                    </div>
                    {step === 3
                        &&
                        <div
                            onClick={() => {
                                updateVisible(false);
                                navigate(`/search?brand=${selectedBrand.id}&model=${selectedModel.id}&motorisation=${selectedMotorisation.id}`)
                            }}
                            className="min-h-10 px-4 flex flex-row gap-2 cursor-pointer items-center justify-center bg-primaryLight w-fit rounded">
                            <img src="/resources/search.svg" alt="search icon" className="white_filter"/>
                            <p className="font-medium text-white text-sm">{t('search')}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const useCompatibleVehicleContext = create((set) => ({
    visible: false,
    updateVisible: (visible) => set(() => ({visible})),
    type: localStorage.getItem('type') ? JSON.parse(localStorage.getItem('type')) : null,
    brand: localStorage.getItem('brand') ? JSON.parse(localStorage.getItem('brand')) : null,
    model: localStorage.getItem('model') ? JSON.parse(localStorage.getItem('model')) : null,
    motorisation: localStorage.getItem('motorisation') ? JSON.parse(localStorage.getItem('motorisation')) : null,

    updateType: (type) => {
        localStorage.setItem('type', JSON.stringify(type));
        set(() => ({type}));
    },

    updateBrandId: (brand) => {
        localStorage.setItem('brand', JSON.stringify(brand));
        set(() => ({brand}));
    },

    updateModelId: (model) => {
        localStorage.setItem('model', JSON.stringify(model));
        set(() => ({model}));
    },

    updateMotorisationId: (motorisation) => {
        localStorage.setItem('motorisation', JSON.stringify(motorisation));
        set(() => ({motorisation}));
    },
}));

const vehicleTypes = [
    {value: "cars", label: "Voiture"},
    {value: "motorcycles", label: "Moto"},
    {value: "trucks", label: "Camion"},
]

const getType = (value) => {
    return vehicleTypes.find(type => type.value === value);
}


const VehiclesTable = ({handleClose, stepState, searchState}) => {
    const {t} = useTranslation();
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [motorisations, setMotorisations] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedMotorisation, setSelectedMotorisation] = useState('');
    const [step, setStep] = stepState;
    const [searchTerm, setSearchTerm] = searchState;
    const [isLoading, setIsLoading] = useState(false);
    const {
        updateBrandId,
        updateModelId,
        updateMotorisationId,
        updateType,
        brand,
        model,
        motorisation,
        type
    } = useCompatibleVehicleContext();

    useEffect(() => {
        if (type) {
            setIsLoading(true);
            getVehicules()
                .then((response) => {
                    let fetchedData = null;
                    if (type === "cars") {
                        fetchedData = response.data.cars;
                    } else if (type === "motorcycles") {
                        fetchedData = response.data.motorcycles;
                    } else if (type === "trucks"){
                        fetchedData = response.data.trucks;
                    }

                    const brandData = fetchedData.map(item => ({
                        id: item.motorisation.vehicle_model.vehicle_brand.id,
                        name: item.motorisation.vehicle_model.vehicle_brand.brand_name
                    }));

                    const modelData = fetchedData.map(item => ({
                        id: item.motorisation.vehicle_model.id,
                        name: item.motorisation.vehicle_model.name,
                        brandId: item.motorisation.vehicle_model.vehicle_brand.id
                    }));

                    const motorisationData = fetchedData.map(item => ({
                        id: item.motorisation.id,
                        name: item.motorisation.name,
                        modelId: item.motorisation.vehicle_model.id
                    }));

                    setBrands(brandData);
                    setModels(modelData);
                    setMotorisations(motorisationData);

                }).catch((err) => {
            }).finally(() => {
                setIsLoading(false)
            });
        }
    }, [type]);

    useEffect(() => {
        let temp = 1;

        if (brand !== null && model !== null && motorisation !== null) {
            setSelectedBrand(brand.id);
            setSelectedModel(model.id);
            setSelectedMotorisation(motorisation.id);
            temp = 4;
        } else if (brand !== null && model !== null && motorisation === null) {
            setSelectedBrand(brand.id);
            setSelectedModel(model.id);
            setSelectedMotorisation("")
            temp = 4;
        } else if (brand !== null && model === null && motorisation === null) {
            setSelectedBrand(brand.id);
            setSelectedModel("");
            setSelectedMotorisation("");
            temp = 3;
        } else if (brand === null && model === null && motorisation === null) {
            setSelectedBrand("");
            setSelectedModel("");
            setSelectedMotorisation("");
            temp = 1;
        }
        setStep(temp);
    }, [brand, model, motorisation, setStep]);

    const handleBrandSelect = (brand) => {
        setSelectedBrand(brand.id);
        updateBrandId(brand);
        setStep(3);
    };

    const handleModelSelect = (model) => {
        setSelectedModel(model.id);
        updateModelId(model);
        setStep(4);
    };

    const handleMotorisationSelect = (motorisation) => {
        setSelectedMotorisation(motorisation.id);
        updateMotorisationId(motorisation);
        handleClose();
    }

    const filteredBrands = brands
        .filter((brand) => brand.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .filter((brand, index, self) =>
            index === self.findIndex((b) => b.name.toLowerCase() === brand.name.toLowerCase())
        );

    return (
        <div className="bg-white w-full h-full p-4">
            {isLoading ? (
                <div className="text-center text-gray-500">{t('loading')}</div>
            ) : (
                <>
                    {step === 1 && (
                        <>
                            <div>
                                <h3 className="text-gray-600 mb-2">{t('mainBrands')}</h3>
                                <GenericSelect
                                    inputs={vehicleTypes}
                                    defaults={type ? getType(type) : []}
                                    handleChanges={(e) => {
                                        updateType(e.value)
                                        setStep(2);
                                    }}
                                    isMulti={false}
                                />
                            </div>
                        </>
                    )}
                    {/* Brand Selection Step */}
                    {step === 2 && (
                        <>
                            <div>
                                <h3 className="text-gray-600 mb-2">{t('mainBrands')}</h3>
                                {isLoading ?
                                    (<div className="w-5">
                                        <div className="loader"></div>
                                    </div>)
                                    :
                                    <ul className="space-y-2">
                                        {filteredBrands.length > 0 ? (
                                            filteredBrands.map((brand) => (
                                                <li
                                                    key={brand.id}
                                                    className={`cursor-pointer rounded-lg hover:bg-gray-50 px-4 py-2 min-h-10 ${selectedBrand === brand.id ? 'bg-primaryExtraLight text-primaryLight' : ''}`}
                                                    onClick={() => handleBrandSelect(brand)}
                                                >
                                                    {brand.name}
                                                </li>
                                            ))
                                        ) : (
                                            <li className="text-gray-500">{t('noBrandsFound')}</li>
                                        )
                                        }
                                    </ul>
                                }
                            </div>
                        </>
                    )}

                    {/* Model Selection Step */}
                    {step === 3 && (
                        <>
                            <div>
                                <h3 className="text-gray-600 mb-2">{t('chooseModel')}</h3>
                                <ul className="space-y-2">
                                    {models.filter(model => model.brandId === selectedBrand).map((model) => (
                                        <li
                                            key={model.id}
                                            className={`cursor-pointer p-2 rounded-lg hover:bg-primaryExtraLight px-4 py-2 min-h-10 ${selectedModel === model.id ? 'bg-primaryExtraLight text-primaryLight' : ''}`}
                                            onClick={() => handleModelSelect(model)}
                                        >
                                            {model.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}

                    {/* Motorisation Selection Step */}
                    {step === 4 && (
                        <>
                            <div>
                                <h3 className="text-gray-600 mb-2">{t('chooseEngine')}</h3>
                                <ul className="space-y-2">
                                    {motorisations.filter(motorisation => motorisation.modelId === selectedModel).map((motorisation) => (
                                        <li
                                            key={motorisation.id}
                                            className={`cursor-pointer rounded-lg hover:bg-gray-50 px-4 py-2 min-h-10 ${selectedMotorisation === motorisation.id ? 'bg-primaryExtraLight text-primaryLight font-medium' : ''}`}
                                            onClick={() => handleMotorisationSelect(motorisation)}
                                        >
                                            {motorisation.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </>
            )}

        </div>
    );
};