import {ChevronLeft, ChevronRight, ChevronsRight, ChevronsLeft} from "lucide-react";
import React, {useEffect, useRef} from "react";

export default function TablePaginationNavigation({table}) {
    const {
        nextPage,
        getCanPreviousPage,
        getCanNextPage,
        previousPage,
        getPageCount,
        setPageIndex,
        firstPage,
        lastPage,
    } = table;

    const totalPages = Math.max(1, getPageCount());

    const pageIndex = useRef(0);

    const safeSetPageIndex = (newPage) => {
        const boundedPage = Math.min(Math.max(0, newPage), totalPages - 1);
        pageIndex.current = boundedPage;
        setPageIndex(boundedPage);
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const NavButton = ({onClick, disabled, icon: Icon, label}) => (
        <button
            onClick={onClick}
            disabled={disabled}
            className="flex items-center justify-center h-12 w-12 text-gray-500 transition-colors duration-200 ease-in-out
                hover:text-gray-900 hover:bg-gray-50 disabled:opacity-50 disabled:hover:bg-transparent disabled:cursor-not-allowed
                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            aria-label={label}
        >
            <Icon size={20} aria-hidden="true"/>
        </button>
    );

    const PageButton = ({pageNum, isActive}) => (
        <button
            onClick={() => safeSetPageIndex(pageNum - 1)}
            className={`flex items-center justify-center h-12 w-12 transition-colors duration-200 ease-in-out
                focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2
                ${isActive
                ? 'bg-orange-50 border-y border-orange-200 font-semibold text-primaryLight hover:bg-orange-100'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
            }`}
            aria-current={isActive ? 'page' : undefined}
            aria-label={`Page ${pageNum}`}
        >
            {pageNum}
        </button>
    );

    const Ellipsis = ({position}) => (
        <span
            className="flex items-center justify-center h-12 w-8 text-gray-400"
            aria-hidden="true"
            title={`More pages ${position === 'start' ? 'before' : 'after'}`}
        >
            ⋯
        </span>
    );

    useEffect(() => {
        console.log(pageIndex.current);
    }, []);

    const safePrevious = () => {
        if (getCanPreviousPage()) {
            previousPage();
            pageIndex.current -= 1;
        }
    }

    const safeFirst = () => {
        firstPage();
        pageIndex.current = 0;
    }

    const safeLast = () => {
        lastPage();
        pageIndex.current = totalPages - 1;
    }

    const safeNext = () => {
        if (getCanNextPage()) {
            nextPage();
            pageIndex.current += 1;
        }
    }

    return (
        <nav
            className="flex items-center justify-center rounded-lg bg-white shadow-sm border border-gray-200"
            aria-label="Pagination navigation"
            role="navigation"
        >
            <NavButton
                onClick={safeFirst}
                disabled={!getCanPreviousPage()}
                icon={ChevronsLeft}
                label="First page"
            />
            <NavButton
                onClick={safePrevious}
                disabled={!getCanPreviousPage()}
                icon={ChevronLeft}
                label="Previous page"
            />

            <div className="flex items-center border-x border-gray-200" role="list">
                {pageNumbers.map((number) => (
                    <React.Fragment key={number}>
                        {number === 1 || number === pageIndex.current || number === totalPages || (number > pageIndex.current - 1 && number < pageIndex.current + 3) ? (
                            <PageButton pageNum={number} isActive={number === pageIndex.current + 1}/>
                        ) : number === pageIndex.current - 1 || number === pageIndex.current + 3 ? (
                            <span
                                className="flex items-center justify-center h-12 w-12 border-x-border border-x">...</span>
                        ) : null}
                    </React.Fragment>
                ))}
            </div>

            <NavButton
                onClick={safeNext}
                disabled={!getCanNextPage()}
                icon={ChevronRight}
                label="Next page"
            />
            <NavButton
                onClick={safeLast}
                disabled={!getCanNextPage()}
                icon={ChevronsRight}
                label="Last page"
            />
        </nav>
    );
}