import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function GenericDropDown({ parent = null, children }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="dropdown cta relative" ref={dropdownRef}>
            {parent === null ? (
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="cta dropdown w-full max-w-[40px] h-10 px-4 py-2 bg-white cursor-pointer flex items-center justify-center rounded-lg border border-gray-200"
                >
                    <div className="cta dropdown flex flex-col items-start gap-0.5 justify-self-start">
                        <div className="cta dropdown w-1 h-1 rounded-full bg-gray-500"></div>
                        <div className="cta dropdown w-1 h-1 rounded-full bg-gray-500"></div>
                        <div className="cta dropdown w-1 h-1 rounded-full bg-gray-500"></div>
                    </div>
                </button>
            ) : (
                <div onClick={() => setIsOpen(!isOpen)}>{parent}</div>
            )}

            <AnimatePresence>
                {isOpen && (
                    <motion.ul
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        className="absolute z-50 w-fit mt-1 bg-white border border-gray-300 rounded-md shadow-lg"
                        onClick={() => setIsOpen(false)}
                    >
                        {children}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
}