import {useCompareContext} from "../../hooks/context/context";
import {formatCurrency, getPhoto} from "../../utils/utils";
import AddToCart from "../AddToCart/AddToCart";
import {useEffect, useState} from "react";
import {getProductById} from "../../services/marketplace/API";
import {useNavigate} from "react-router";
import {useMarketplaceContext} from "../../pages/Marketplace";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import {useTranslation} from 'react-i18next';

const predefinedCharacteristics = [
    {title: "Condition", key: "Etat", unit: ""},
    {title: "Color", key: "Couleur", unit: ""},
    {title: "Origine", key: "Origine", unit: ""},
    {title: "Poids (kg)", key: "Poids_(Kg)", unit: " (kg)"},
];

export default function CompareFlyoutMenu() {
    const {t} = useTranslation();
    const {compareFlyoutMenuVisible, compareProducts, hideCompareFlyoutMenu} = useCompareContext();
    const {updatePopupVisible} = useMarketplaceContext();
    const [products, updateProducts] = useState([]);

    const allCharacteristicsKeys = [
        ...new Set(products.flatMap(product => Object.keys(product.characteristics || {}))),
    ];

    const additionalCharacteristics = allCharacteristicsKeys
        .filter((key) => !predefinedCharacteristics.some((char) => char.key === key))
        .map((key) => ({title: key, key, unit: ""})); // Assume no units for unknown characteristics


    useEffect(() => {
        const fetchProducts = async () => {
            const promises = compareProducts.map(async (product) => {
                return await getProductById(product.id)
                    .then((response) => response.data)
                    .catch(() => null);
            });
            const fetchedProducts = await Promise.all(promises);
            updateProducts(fetchedProducts.filter((product) => product !== null));
        };
        fetchProducts();
    }, [compareProducts]);

    return (
        <>
            {/* Background overlay */}
            <div
                onClick={() => {
                    hideCompareFlyoutMenu()
                    updatePopupVisible(false)
                }}
                style={{visibility: compareFlyoutMenuVisible ? "visible" : "hidden"}}
                className="h-full w-screen bg-black bg-opacity-50 absolute top-0 left-0 z-[99] transition-all duration-300 ease-in-out"
            ></div>

            {/* Flyout container */}
            <div
                style={{
                    right: compareFlyoutMenuVisible ? 0 : '-100%',
                }}
                className="fixed top-0 h-screen bg-white z-[100] w-full sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] transition-all duration-500 ease-in-out flex flex-col"
            >
                {/* Header */}
                <div className="w-full h-14 flex items-center justify-between px-6 border-b border-gray-200">
                    <div className="flex items-center gap-2">
                        <img src="/resources/compare-arrows.svg" alt="compare"/>
                        <p className="font-semibold">{t('compareProducts.title')}</p>
                    </div>
                    <button
                        onClick={() => {
                            updatePopupVisible(false)
                            hideCompareFlyoutMenu()
                        }}
                        className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200"
                    >
                        <img src="/resources/close.svg" alt="close" className="w-3"/>
                    </button>
                </div>

                {/* Body */}
                {compareProducts.length === 0 ? (
                    <div className="flex flex-col items-center justify-center flex-grow p-4">
                        <p className="font-medium text-gray-500">
                            {t('compareProducts.emptyState')}
                        </p>
                    </div>
                ) : (
                    <div className="w-full h-full p-4">
                        <ItemCardListing/>
                        <div className="w-full h-full max-h-[calc(100vh-385px)] overflow-y-auto">
                            <ItemCompare
                                comparisonTitle={t('compareProducts.reference')}
                                renderer={(value) => <p className="font-medium text-sm text-gray-500">{value}</p>}
                                values={products.map((product) => product.serial_number)}
                                calculateDiff={false}
                            />
                            <ItemCompare
                                comparisonTitle={t('compareProducts.customerReviews')}
                                renderer={(value) => (
                                    <div className="flex items-center gap-2">
                                        <img src="/resources/star.svg" alt="star"/>
                                        <p className="font-bold">{value[0]}</p>
                                        <p className="text-sm text-gray-500">({value[1]} {t('compareProducts.reviews')})</p>
                                    </div>
                                )}
                                values={products.map((product) => [product.average_rating, product.comments_count])}
                            />
                            <ItemCompare
                                comparisonTitle={t('compareProducts.price')}
                                renderer={(value) => (
                                    <p className="font-semibold text-lg">{formatCurrency(value)}</p>
                                )}
                                values={products.map((product) => product.price)}
                            />
                            <div className="w-full flex items-center justify-center py-4">
                                <h3 className="font-bold text-lg">Caractéristiques techniques</h3>
                            </div>
                            <ItemCompare
                                comparisonTitle={"Condition"}
                                renderer={(value) => (
                                    <p className="font-medium">{value}</p>
                                )}
                                values={products.map((product) => product?.characteristics?.Etat)}
                            />
                            <ItemCompare
                                comparisonTitle={"Color"}
                                renderer={(value) => (
                                    <p className="font-medium">{value}</p>
                                )}
                                values={products.map((product) => product?.characteristics?.Couleur)}
                            />
                            <ItemCompare
                                comparisonTitle={"Origine"}
                                renderer={(value) => (
                                    <p className="font-medium">{value}</p>
                                )}
                                values={products.map((product) => product?.characteristics?.Origine)}
                            />
                            <ItemCompare
                                comparisonTitle={"Poids (kg)"}
                                renderer={(value) => (
                                    <p className="font-medium">{value && value + " (kg)"}</p>
                                )}
                                values={products.map((product) => product?.characteristics?.["Poids_(Kg)"])}
                            />

                            {
                                additionalCharacteristics.map((characteristic) => (
                                    <ItemCompare
                                        key={characteristic.key}
                                        comparisonTitle={characteristic.title}
                                        renderer={(value) => (
                                            <p className="font-medium">
                                                {value ? `${value}${characteristic.unit}` : null}
                                            </p>
                                        )}
                                        values={products.map((product) => product?.characteristics?.[characteristic.key])}
                                    />
                                ))
                            }
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}


const ItemCardListing = () => {
    const {compareProducts, removeProductFromCompare} = useCompareContext();
    const renderer = (item) => {
        try {
            return (
                <div
                    className="w-1/4 h-full min-h-[270px] flex flex-col">
                    <ItemCard
                        image={getPhoto(item.first_photo)}
                        name={item.name}
                        id={item.id}
                        price={item.price}/>
                </div>
            )
        } catch (e) {
            removeProductFromCompare(item)
        }
    }

    return (
        <div
            className="w-full h-fit border border-border flex flex-row divide-x divide-border">
            {compareProducts.concat(Array(4).fill(null)).slice(0, 4).map((item) => item
                ?
                renderer(item)
                :
                <div className="w-1/4 h-full min-h-[300px] max-h-[300px] flex items-center justify-center">
                    <p className="font-medium text-[#747474]">-</p>
                </div>
            )}
        </div>
    )
}

const ItemCard = ({id, image, name}) => {
    const {removeProductFromCompare, hideCompareFlyoutMenu} = useCompareContext();
    const navigate = useNavigate();
    return (
        <div
            className="w-full h-full min-h-[300px] max-h-[300px] flex flex-col gap-3 p-2 items-center justify-between border-b border-border">
            <div className="w-full h-full flex flex-row items-center justify-center relative">
                <div
                    onClick={() => {
                        navigate(`/product/${id}`)
                        hideCompareFlyoutMenu()
                    }}
                    className="flex items-center justify-center w-[150px] h-[150px] overflow-hidden rounded-lg cursor-pointer">
                    <ImageWithFallback src={image} alt={name} className="w-full h-full object-contain"/>
                </div>
                <div
                    onClick={() => removeProductFromCompare({id: id})}
                    className="w-10 h-10 rounded-lg flex items-center justify-center cursor-pointer border-border border bg-white  absolute top-0 right-0 hover:bg-gray-50">
                    <img src="/resources/delete-black.svg" alt="close" className=""/>
                </div>
            </div>
            <p className="font-medium line-clamp-2 text-ellipsis overflow-hidden">{name}</p>
            <AddToCart ids={[id]}/>
        </div>
    )
}

const ItemCompare = ({comparisonTitle, renderer, values = [], calculateDiff = true}) => {
    // Determine if any values are different
    const isDifferent = calculateDiff ? values.some((value, index, array) => value !== array[0]) : false

    return (
        <div className="w-full h-fit flex flex-col border-b border-borderLight">
            <div className="w-full bg-[#EAEAEA] h-8 flex items-center justify-center">
                <p className="font-medium text-sm">{comparisonTitle}</p>
            </div>
            <div
                className={`w-full h-14 divide-x divide-borderLight flex flex-row items-center justify-between rounded-lg ${
                    isDifferent ? "border-primaryLight border-2" : "border-borderLight border"
                }`}
            >
                {values.concat(Array(4).fill(null)).slice(0, 4).map((value, index) => (
                    value ? (
                        <div key={index} className="w-1/4 h-full flex items-center justify-center">
                            {renderer(value)}
                        </div>
                    ) : (
                        <div key={index} className="w-1/4 h-full flex items-center justify-center">
                            <p className="font-medium text-[#747474]">-</p>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};
