import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProfileSection from '../components/ProfileSection';
import { createUser, showUser } from '../../../../../services/AdminSapce/API';
import {toast} from "sonner";
import ToastSuccess from "../../../../../components/Toasts/Success/ToastSuccess";
import ToastError, {toastStyle} from "../../../../../components/Toasts/Error/ToastError";

const BuyerForm = () => {
    const { id } = useParams();
    const [profileImage, setProfileImage] = useState(null);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        date_of_birth: '',
        phone_number: '',
        tiktok: '',
        street: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
    });
    const [isViewing, setIsViewing] = useState(false);

    useEffect(() => {
        if (id) {
            setIsViewing(true);
            fetchBuyerData(id);
        }
    }, [id]);

    const fetchBuyerData = async (buyerId) => {
        try {
            const response = await showUser(buyerId);
            const { user } = response;
            const contact = user.contact || {};
            const address = contact.address || {};

            setFormData({
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                username: user.username || '',
                email: user.email || '',
                date_of_birth: user.date_of_birth || '',
                phone_number: contact.phone_number || '',
                tiktok: contact.tiktok || '',
                street: address.street || '',
                city: address.city || '',
                state: address.state || '',
                postal_code: address.postal_code || '',
                country: address.country || '',
            });
        } catch (error) {
            toast.error(<ToastError message="Erreur lors du chargement des données"/>, toastStyle);
        }
    };

    const handleSave = async () => {
        if (!formData) {
            toast.error(<ToastError message="Veuillez remplir les informations de l'utilisateur"/>, toastStyle);
            return;
        }

        try {
            const formDataToSubmit = new FormData();

            // Add basic user data
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSubmit.append(key, value || '');
            });

            // Add role and status
            formDataToSubmit.append('role_id', 4);
            formDataToSubmit.append('status', 'active');

            // Add profile image only if it's a new file
            if (profileImage instanceof File) {
                formDataToSubmit.append('profile_photo', profileImage);
            }

            await createUser(formDataToSubmit);
            toast.success(<ToastSuccess message="Client ajouté avec succès"/>, toastStyle);
        } catch (error) {
            toast.error(<ToastError message="Erreur lors de la création du Client"/>, toastStyle);
        }
    };

    return (
        <div className="w-full flex flex-col overflow-y-auto">
            <div className="flex justify-between items-center p-4">
                <h1 className="text-2xl font-bold">
                    {isViewing ? 'Détails du Client' : 'Ajouter un Client'}
                </h1>
                {!isViewing && (
                    <button
                        className="bg-orange-500 text-white px-6 py-3 rounded-md font-bold"
                        onClick={handleSave}>
                        Sauvegarder
                    </button>
                )}
            </div>

            <div className="flex-grow flex justify-center items-center">
                <div className="w-full max-w-md">
                    <ProfileSection
                        profileImage={profileImage}
                        setProfileImage={setProfileImage}
                        showAddress={true}
                        formDataState={[formData, setFormData]}
                        readOnly={isViewing}
                    />
                </div>
            </div>
        </div>
    );
};

export default BuyerForm;
