import {FaCheckCircle, FaDollarSign, FaBell} from 'react-icons/fa';
import {formatDistanceToNow} from 'date-fns';
import {Outlet, useNavigate} from "react-router";
import CompareBanner from "../../components/CompareComps/CompareBanner";
import CompareFlyoutMenu from "../../components/CompareFlyoutMenu/CompareFlyoutMenu";
import {create} from "zustand";
import {Toaster} from "sonner";
import React, {forwardRef, useEffect, useRef, useState, useCallback} from "react";
import {useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import {getProfile, logoutUser} from "../../services/AuthAndSignUp/API";
import Logo from "../../components/Logo/Logo";
import GlobalSearch from "../../components/Header/GlobalSearch/GlobalSearch";
import {api} from "../../services/instance";
import {useMarketplaceContext} from "../../pages/Marketplace";
import SearchPopUp from "../../components/SearchPopUp/SearchPopUp";
import {AddProductPageContext} from "../../pages/SellerSpace/pages/AddProducts/AddProduct";
import {ImageWithFallback} from "../../components/ImageWithFallBack/ImageWithFallBack";
import {
    PackDetailsFlyOutMenu,
    usePackDetailsFlyOutMenuContext
} from "../../components/PackDetailsFlyOutMenu/PackDetailsFlyOutMenu";
import DeleteConfirmation, {useDeleteConfirmationContext} from "../../components/DeleteConfirmation/DeleteConfirmation";
import SellerNavMenuFlyOutMenu, {
    useSellerNavMenuFlyOutMenuContext
} from "../../pages/SellerSpace/components/SellerNavManuFlyOut/SellerNavMenuFlyOutMenu";
import TopBanner from "../../pages/Marketplace/components/TopBanner";
import {useTranslation} from "react-i18next";
import AddPromoCode, {useAddPromoCodeContext} from "../../pages/SellerSpace/pages/AddPromoCode/AddPromoCode";
import {
    AssociateProductToPromoCodesPopUp,
    EditPromotionCodes,
    useSellerPromoCodesContext
} from "../../pages/SellerSpace/pages/PromoCodes/PromoCodes";
import {useSellerSpaceRoutesContext} from "../SellerSpaceRoutes/SellerSpaceRoutes";

export default function NonMarketPlaceRoutes() {
    const {popUpVisible, loading, updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {updatePromoPopUpVisible} = AddProductPageContext();
    const {updatePackDetailsFlyOutMenuVisible} = usePackDetailsFlyOutMenuContext();
    const {showSearchBar, updateShowSearchBar} = useMarketplaceContext();
    const {updateDeletePopUpVisible} = useDeleteConfirmationContext();
    const {
        editPopUpVisible,
        setEditPopUpVisible,
        associateProductVisible,
        updateAssociateProductVisible
    } = useSellerPromoCodesContext();
    const {addPromoCodePopUpVisible, updateAddPromoCodePopUpVisible} = useAddPromoCodeContext();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userCookie = Cookies.get("user");

                if (userCookie) {
                    setUser(JSON.parse(userCookie));
                    return;
                }

                const response = await getProfile();
                if (response.status === 200) {
                    setUser(response.data.user);
                    Cookies.set("user", JSON.stringify(response.data.user), {
                        expires: 30,
                        secure: true,
                        sameSite: 'strict'
                    });
                } else {
                    console.error("Failed to fetch profile from API:", response.statusText || response.error);
                    // Handle additional error logic here
                }
            } catch (error) {
                console.error("Error during profile fetching:", error);
            }
        };

        fetchUserProfile();
    }, []);

    return (
        <div className={"relative w-screen h-screen flex flex-col"}>
            {showSearchBar && <SearchPopUp/>}
            {associateProductVisible && <AssociateProductToPromoCodesPopUp/>}
            {editPopUpVisible && <EditPromotionCodes/>}
            {loading && (
                <div className="w-screen h-screen flex items-center justify-center absolute bg-white top-0 left-0 z-50">
                    <div className="loader"></div>
                </div>
            )}
            {addPromoCodePopUpVisible && <AddPromoCode/>}
            {popUpVisible && (
                <div
                    onClick={() => {
                        updateAssociateProductVisible(false)
                        setEditPopUpVisible(false)
                        updateAddPromoCodePopUpVisible(false)
                        updatePackDetailsFlyOutMenuVisible(false)
                        updateShowSearchBar(false)
                        updatePopUpVisible(false)
                        updateDeletePopUpVisible(false)
                        updatePromoPopUpVisible(false)
                    }}

                    className="w-screen h-full bg-black opacity-50 fixed top-0 left-0 z-50"/>
            )}
            <div className={`flex-grow ${loading ? "invisible" : "visible"}`}>
                <TopBanner/>
                <NonMarketplaceHeader/>
                <Outlet/>
                <SellerNavMenuFlyOutMenu roleId={user?.role_id}/>
                <CompareFlyoutMenu/>
                <CompareBanner/>
                <PackDetailsFlyOutMenu/>
                <DeleteConfirmation/>
                <Toaster toastOptions={{
                    style: {
                        background: 'white',
                        border: '1px solid #d4d4d4',
                        borderRadius: "8px",
                    },
                    duration: Infinity,
                }}/>
            </div>
        </div>
    );
}

const Notification = forwardRef(({notificationState, setUnreadCount}, ref) => {
    const [notifications, setNotifications] = useState([]);
    const [notificationVisible, updateNotificationVisible] = notificationState;
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const isSameDay = useCallback((date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }, []);

    const isYesterday = useCallback((date) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return isSameDay(date, yesterday);
    }, [isSameDay]);

    const getNotificationsAndMarkRead = useCallback(async () => {
        setLoading(true);
        setUnreadCount(0)
        try {
            // First fetch notifications
            const response = await api.get("/api/notifications/unread");
            const fetchedNotifications = response.data || [];

            // Find the latest unread notification
            if (fetchedNotifications.length > 0) {
                const latestNotification = fetchedNotifications.reduce((latest, current) => {
                    return new Date(current.created_at) > new Date(latest.created_at) ? current : latest;
                }, fetchedNotifications[0]);

                // If the latest notification is unread, mark it as read
                if (latestNotification) {
                    await api.post(`/api/notifications/${latestNotification.id}/read`);

                    // Update the notification in our fetched data
                    const updatedNotifications = fetchedNotifications.map(notification =>
                        notification.id === latestNotification.id
                            ? {...notification, read_at: new Date().toISOString()}
                            : notification
                    );

                    setNotifications(updatedNotifications);
                } else {
                    // If no unread notifications, just set the fetched data
                    setNotifications(fetchedNotifications);
                }
            } else {
                setNotifications([]);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setNotifications([]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (notificationVisible && ref.current) {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    updateNotificationVisible(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [ref, notificationVisible, updateNotificationVisible]);

    useEffect(() => {
        let mounted = true;

        const loadData = async () => {
            if (notificationVisible) {
                if (mounted) {
                    await getNotificationsAndMarkRead();
                }
            }
        };

        loadData();

        return () => {
            mounted = false;
        };
    }, [notificationVisible, getNotificationsAndMarkRead]);

    const getNotificationIcon = useCallback((type) => {
        switch (type?.toLowerCase()) {
            case 'user_registration':
                return <FaBell className="text-gray-600 text-2xl"/>;
            case 'payment':
                return <FaDollarSign className="text-gray-600 text-2xl"/>;
            case 'reminder':
                return <FaCheckCircle className="text-gray-600 text-2xl"/>;
            default:
                return <FaBell className="text-gray-600 text-2xl"/>;
        }
    }, []);

    const classifyNotifications = useCallback((notificationsList) => {
        const today = new Date();

        return (notificationsList || []).reduce((acc, notification) => {
            const createdAt = new Date(notification.created_at);

            if (isSameDay(today, createdAt)) {
                acc.Today.push(notification);
            } else if (isYesterday(createdAt)) {
                acc.Yesterday.push(notification);
            } else {
                acc.Older.push(notification);
            }

            return acc;
        }, {Today: [], Yesterday: [], Older: []});
    }, [isSameDay, isYesterday]);

    const groupedNotifications = classifyNotifications(notifications);

    const NotificationItem = ({notification}) => {
        const [language,]  = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "fr");

        return (
            <div
                key={notification.id}
                className="flex items-start space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
            >
                <div className="flex-shrink-0">
                    {getNotificationIcon(notification.data?.type)}
                </div>

                <div className="flex-grow">
                    <div className="text-sm font-medium text-gray-800">
                        <p className="line-clamp-2">{language === "eng" ? notification.data?.message?.eng : notification.data?.message?.fr || 'No message available'}</p>
                    </div>
                    <div className="text-xs text-gray-500">
                        {formatDistanceToNow(new Date(notification.created_at))} ago
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div
            ref={ref}
            style={{display: notificationVisible ? 'block' : 'none'}}
            className="absolute top-[60px] right-0 bg-white min-w-[300px] w-full max-w-md rounded-lg shadow-lg border-border border"
        >
            <div className="flex justify-between items-center min-h-12 border-b px-4">
                <h1 className="font-medium">Notifications</h1>
            </div>

            {loading ? (
                <div className="min-h-[200px] flex items-center justify-center">
                    <div className="w-4">
                        <div className="loader"></div>
                    </div>
                </div>
            ) : (
                <>
                    {!groupedNotifications.Today.length &&
                    !groupedNotifications.Yesterday.length &&
                    !groupedNotifications.Older.length ? (
                        <div className="flex items-center justify-center p-4 min-h-[200px]">
                            <p className="text-gray-500">{t("none.notifications")}</p>
                        </div>
                    ) : (
                        <div className="flex flex-col max-h-[500px] overflow-y-auto">
                            {Object.entries(groupedNotifications).map(([section, sectionNotifications]) => (
                                sectionNotifications.length > 0 && (
                                    <div key={section}>
                                        <div
                                            className="flex flex-row items-center px-4 min-h-12 bg-gray-100 border-b sticky top-0">
                                            <h2 className="font-medium text-gray-700">
                                                {section === 'Today' ? "Today" :
                                                    section === 'Yesterday' ? "Yesterday" :
                                                        "Older"}
                                            </h2>
                                        </div>
                                        {sectionNotifications.map(notification => (
                                            <NotificationItem
                                                key={notification.id}
                                                notification={notification}
                                            />
                                        ))}
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </>
            )}

            <div className="min-h-12 flex flex-row items-center px-4 border-t border-t-border">
                <a href="/notifications" className="text-black font-medium text-sm hover:underline">
                    {t("sellerSpace.welcome.voirNotifications")}
                </a>
            </div>
        </div>
    );
});

const NonMarketplaceHeader = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const ref = useRef(null);
    const location = useLocation();

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userCookie = Cookies.get("user");

                if (userCookie) {
                    setUser(JSON.parse(userCookie));
                    return;
                }

                const response = await getProfile();
                if (response.status === 200) {
                    setUser(response.data.user);
                    Cookies.set("user", JSON.stringify(response.data.user), {
                        expires: 30,
                        secure: true,
                        sameSite: 'strict'
                    });
                } else {
                    console.error("Failed to fetch profile from API:", response.statusText || response.error);
                    // Handle additional error logic here
                }
            } catch (error) {
                console.error("Error during profile fetching:", error);
            }
        };

        fetchUserProfile();
    }, []);

    // Handle scroll behavior
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 88) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        // Add event listener only if we're on the home page
        if (location.pathname === "/") {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location.pathname]);

    // Render user profile picture
    const renderUserProfilePicture = () => {
        const profilePhoto = user?.profile_photo || "/images/blank-avatar.png";
        if (user?.profile_photo !== null) {
            return (
                <ImageWithFallback src={profilePhoto} alt="user profile" className="h-full w-full object-cover"/>
            )
        } else {
            return (
                <img
                    src={profilePhoto}
                    referrerPolicy="no-referrer"
                    alt="user profile"
                    className="h-full w-full object-cover"
                    onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop
                        e.target.src = "/images/blank-avatar.png";
                    }}
                />
            );
        }
    };

    const [logingOut, setLogingOut] = useState(false);

    function handleLogout() {
        setLogingOut(true);
        logoutUser().then(response => {
            if (response.status === 200) {
                setUser(null);
                Cookies.remove("token");
                Cookies.remove("user");
                Cookies.remove("shop");
                setLogingOut(false);
                navigate("/");
                setDropdownVisible(false);
            } else {
                console.error(response.error);
                setUser(null);
                Cookies.remove("token");
                Cookies.remove("user");
                Cookies.remove("shop");
                setLogingOut(false);
                navigate("/");
                setDropdownVisible(false);
                setDropdownVisible(false)
            }
        }).catch(() => {
            setUser(null);
            Cookies.remove("token");
            Cookies.remove("user");
            Cookies.remove("shop");
            setLogingOut(false);
            navigate("/");
            setDropdownVisible(false);
            setDropdownVisible(false)
        });
    }

    const [notificationVisible, updateNotificationVisible] = useState(false);
    const {t} = useTranslation();


    const notification_ref = useRef();

    const {updateMenuVisible} = useSellerNavMenuFlyOutMenuContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();


    const [unreadCount, setUnreadCount] = useState(0);

    const fetchNotifications = useCallback(async () => {
        try {
            const response = await api.get("/api/notifications/unread");
            const fetchedNotifications = response.data || [];
            setUnreadCount(fetchedNotifications.filter(n => !n.read_at).length);
        } catch (e) {
            if (e.response.status === 401 || e.response.status === 403) {
                navigate("/login", {state: {from: window.location.pathname}})
            }
        }
    }, [navigate])

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    const {updateRenewPlan, updateExpiredPopUpVisible} = useSellerSpaceRoutesContext();

    const [shop, setShop] = useState({})

    useEffect(() => {
        try {
            const shopCookie = Cookies.get("shop")
            if (shopCookie) {
                setShop(JSON.parse(shopCookie))
            }
        } catch (e) {

        }
    }, []);

    return (
        <div
            className={`w-screen h-[9vh] ${isFixed ? 'fixed' : 'relative'} top-0 left-0 bg-white z-40 border-b border-b-border pl-2 pr-4 sm:px-9 py-4 flex items-center justify-between transition-all duration-300 ease-in-out`}
        >
            <div
                onClick={() => {
                    updateMenuVisible(true)
                    updatePopUpVisible(true)
                }}
                className={`${user?.role_id === 4 ? "hidden " : "flex"} sm:hidden w-16 h-16 items-center justify-center`}>
                <img src="/resources/menu.svg" alt="menu"/>
            </div>
            <div
                className={`w-[30vw] sm:w-[20vw] lg:w-[18vw] ${user?.role_id === 4 ? "flex " : "hidden"} sm:flex items-center `}>
                <Logo/>
            </div>
            <div className="md:w-fit lg:w-[61vw] flex items-center justify-between gap-4">
                <GlobalSearch/>
                <div className="flex items-center gap-4 md:gap-10">
                    <div ref={notification_ref} className="flex items-center gap-4 relative">
                        <Buttons handleClick={() => updateNotificationVisible(!notificationVisible)}>
                            <img src="/resources/bell.svg" alt="bell"/>
                        </Buttons>
                        {unreadCount > 0 && (
                            <div
                                className="absolute left-8 top-8 bg-red-500 text-white rounded-full px-2 py-1 text-xs font-medium">
                                {unreadCount > 9 ? '9+' : unreadCount}
                            </div>
                        )}
                        <Notification notificationState={[notificationVisible, updateNotificationVisible]}
                                      setUnreadCount={setUnreadCount}
                                      ref={notification_ref}/>
                    </div>
                    {user ? (
                        <div ref={ref} className="relative w-fit h-fit">
                            <div onClick={() => setDropdownVisible(!dropdownVisible)}
                                 className="cursor-pointer h-[48px] w-[48px] flex items-center justify-center rounded-full overflow-hidden">
                                {renderUserProfilePicture()}
                            </div>
                            <div
                                className="z-50 w-[250px] h-fit bg-white shadow-lg rounded-lg absolute top-1 right-0 border-border border"
                                style={{transform: 'translateY(56px)', display: dropdownVisible ? "block" : "none"}}>
                                <div
                                    onClick={() => {
                                        navigate("profile")
                                        setDropdownVisible(false)
                                    }}
                                    className="cursor-pointer flex flex-row items-center gap-2 p-4">
                                    <div className="h-10 min-w-10 overflow-hidden rounded-full">
                                        {renderUserProfilePicture()}
                                    </div>
                                    <div className="flex flex-col w-[170px]">
                                        <p className="font-medium text-ellipsis whitespace-nowrap overflow-hidden">{user?.first_name} {user?.last_name}</p>
                                        <p className="text-sm text-borderDark text-ellipsis whitespace-nowrap overflow-hidden">{user?.email}</p>
                                    </div>
                                </div>
                                <div className="border-y-border border-y flex flex-col w-full pt-2">
                                    {
                                        user?.role_id !== 4 &&
                                        <div
                                            onClick={() => {
                                                if (user?.role_id === 1 || user?.role_id === 2) {
                                                    navigate("/admin")
                                                } else if (user?.role_id === 3) {
                                                    navigate("/seller/" + user?.id)
                                                    updateRenewPlan(false)
                                                    updateExpiredPopUpVisible(false)
                                                }
                                                setDropdownVisible(false)
                                            }}
                                            className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-50 min-h-12">
                                            <img src="/resources/dashboard.svg" alt="user icon"
                                                 className="w-5 mx-2"/>
                                            <p className="font-medium text-sm">{t("dashboard")}</p>
                                        </div>
                                    }
                                    <div
                                        onClick={() => {
                                            navigate("mes_annonce")
                                            setDropdownVisible(false)
                                        }}
                                        className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-50 min-h-12">
                                        <img src="/resources/car.svg" alt="car icon" className="w-5 mx-2"/>
                                        <p className="font-medium text-sm">{t("myAds")}</p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            navigate("profile")
                                            setDropdownVisible(false)
                                        }}
                                        className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-50 min-h-12">
                                        <img src="/resources/user.svg" alt="user icon" className="w-5 mx-2"/>
                                        <p className="font-medium text-sm">{t("myAccount")}</p>
                                    </div>
                                    {user?.role_id === 4 &&
                                        <>
                                            <div
                                                onClick={() => navigate("/my-orders")}
                                                className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-50 min-h-12">
                                                <img src="/resources/orders.svg" alt="order icon" className="w-5 mx-2"/>
                                                <p className="font-medium text-sm">{t("myOrders")}</p>
                                            </div>
                                            <div
                                                onClick={() => navigate("/my-reservations")}
                                                className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-50 min-h-12">
                                                <img src="/resources/plans.svg" alt="reservation icon"
                                                     className="w-5 mx-2"/>
                                                <p className="font-medium text-sm">{t("myReservations")}</p>
                                            </div>
                                        </>}
                                    {(user?.role_id === 3 && shop?.status === "unsubscribed") &&
                                        <div
                                            onClick={() => {
                                                navigate("/seller/" + user.id)
                                                updatePopUpVisible(false)
                                                updateRenewPlan(true)
                                                updateExpiredPopUpVisible(true)
                                                setDropdownVisible(false)
                                            }}
                                            className="bg-primaryLight flex flex-row gap-2 items-center text-white px-4 py-2 hover:bg-primaryDark cursor-pointer">
                                            <img src="/resources/navigate.svg" alt="navigate icon"
                                                 className="mx-2 w-5 white_filter"/>
                                            <p className="font-medium text-sm">{t('sellerSpace.subscription.expired.renewButton')}</p>
                                        </div>
                                    }
                                </div>
                                <div className="border-y-border border-y flex flex-col w-full">
                                    <div
                                        onClick={handleLogout}
                                        className="flex flex-row items-center gap-2 px-4 cursor-pointer hover:bg-gray-50 min-h-12">
                                        {logingOut ? <div className="w-5">
                                                <div className="loader_white white_filter"></div>
                                            </div> :
                                            <img src="/resources/logout.svg" alt="logout" className="w-5 mx-2"/>}
                                        <p className="font-medium text-sm">{t("logout")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => navigate("login")}
                             className="cursor-pointer h-[48px] w-[48px] flex items-center justify-center gap-2 border-border rounded-full border md:w-fit md:border-none">
                            <img src="/resources/user.svg" alt="user icon"/>
                            <p className="hidden md:block font-medium text-sm truncate">Me Connecter</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}

const Buttons = ({handleClick, children}) => (
    <div onClick={handleClick}
         className="h-[48px] w-[48px] flex items-center justify-center cursor-pointer hover:bg-[#f1f1f1] rounded-full border-border border">
        {children}
    </div>
);

export const useNonMarketPlaceRoutesContext = create((set) => ({
    popUpVisible: false,
    loading: true,
    updateLoading: (loading) => set(() => ({loading: loading})),
    updatePopUpVisible: (visible) => set(() => ({popUpVisible: visible})),
}));