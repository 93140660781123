import {api} from "../instance";

export function getAllBoats() {
    return api.get("/api/boats")
}

export function getAllEngins() {
    return api.get("/api/engins")
}

export function getMarketPlaceData(data) {
    return api.get("/api/marketplace", {params: data})
}

export function getPackPopUp(){
    return api.get("/api/popup");
}

export function GetTopProducts(data, category_id) {
    if (category_id !== null) {
        data = {...data, category_id: category_id};
    }

    try {
        return api.get('/api/marketplace/products/top', {params: data});
    } catch (error) {
        return error.response?.data || {message: "An error occurred"}; // Fallback for error handling
    }
}

export async function GetNewProducts(data = {}, category_id = null) {
    if (category_id) {
        data = {...data, category_id: category_id};
    }
    try {
        return api.get("api/marketplace/products/newest", {params: data});
    } catch (e) {
        console.log(e)
    }
}

export function getSubCategoryByCategoryId(id) {
    return api.get("/api/sub_categories/category/" + id);
}

export const getProductById = async (id) => {
    try {
        return api.get("/api/products/" + id)
    } catch (e) {
        console.log(e)
    }
}

export const getVehicleById = (id) => {
    return api.get("/api/cars/" + id)
}

export const getEnginById = (id) => {
    return api.get("/api/engins/" + id)
}

export const getBoatById = async (id) => {
    const response = await api.get(`/api/boats/${id}`);
    return response.data;
};


export const getServiceById = async (id) => {
    try {
        return api.get("/api/services/" + id)
    } catch (e) {
        console.log(e)
    }
}

export const orderProduct = async (data) => {
    try {
        return api.post("/api/orders", data)
    } catch (e) {
        console.log(e);
    }
}

export const orderPack = (data) => {
    return api.post("/api/orders/packs-product", data)
}

export const getPromoCodeInfo = async (code) => {
    try {
        return api.get("/api/promotions/code/" + code);
    } catch (e) {
        console.log(e);
    }
}

export const GetTopServices = async () => {
    try {
        return api.get("/api/marketplace/services/top");
    } catch (e) {
        console.log(e);
    }
}

export const GetNewServices = async () => {
    try {
        return api.get("/api/marketplace/services/newest");
    } catch (e) {
        console.log(e);
    }
}

export const getNewCars = () => {
    return api.get("/api/marketplace/cars/newest")
}

export const getNewBoats = () => {
    return api.get("/api/marketplace/boats/newest")
}

export const getNewEngins = () => {
    return api.get("/api/marketplace/engins/newest")
}

export const availableDays = (serviceId) => {
    try {
        return api.get(`/api/service/${serviceId}/available-dates`);
    } catch (error) {
        return error.response.data;
    }
}
export const reserveService = (data) => {
    try {
        return api.post("/api/reservations", data);
    } catch (error) {
        return error.response.data;
    }
}

export const getOfferById = (id) => {
    return api.get("/api/offers/" + id)
}

export const createEngin = (data) => {
    return api.post("/api/engins", data)
}

export const createBoat = (data) => {
    return api.post("/api/boats", data)
}

export const getEnginCategories = () => {
    return api.get("/api/engins-categories")
}

export const getEnginBrands = () => {
    return api.get("/api/engins-brands")
}

export const globalSearch = (page = 1, type, term, brand_id, model_id, motorisation_id) => {
    return api.get(`/api/search?page=${page}`, {
        params: {
            "type": type,
            "q": term,
            "vehicleBrandId": brand_id,
            "vehicleModelId": model_id,
            "motorisationId": motorisation_id
        }
    })
}

export const productSearch = (page, brand_id, category_id, motorisation_id, vehicle_model_id, vehicle_brand_id) => {
    return api.get(`/api/products-search?page=${page}`, {
        params: {
            "vehicle_brand_id": vehicle_brand_id,
            "motorisation_id": motorisation_id,
            "vehicle_model_id": vehicle_model_id,
            "category_id": category_id,
            "brand_id": brand_id,
        }
    })
}

export const shopSearch = (page = 1, type, term, shop_id) => {
    return api.get(`/api/search?page=${page}`, {params: {"type": type, "q": term, "shop_id": shop_id}})
}

export const writeProductComment = (product_id, comment, rating) => {
    return api.post("/api/product/" + product_id + "/comments", {body: comment, rating: rating})
}

export const getProductComments = (product_id) => {
    return api.get("/api/product/" + product_id + "/comments")
}

export const getProductCanComment = (product_id) => {
    return api.get("/api/product/" + product_id + "/canComment")
}

export const updateProductComment = (id, data) => {
    return api.put("/api/product/comments/" + id, data)
}

export const deleteProductComment = (id) => {
    return api.delete("/api/product/comments/" + id)
}

export const getShopDataById = (id) => {
    return api.get("/api/shops/" + id)
}

export const getSimilarProductUsingSubCategoryId = (sub_category_id) => {
    return api.get("/api/products-search", {params: {"sub_category_id": sub_category_id}})
}

export const getSimilarCars = (model_id, brand_id) => {
    return api.get("/api/cars-equivalents", {
        params: {
            "vehicle_model_id": model_id,
            "vehicle_brand_id": brand_id
        }
    })
}

export const getSimilarEngin = (brand_id, category_id) => {
    return api.get("/api/engins-equivalents", {
        params: {
            "brand_id": brand_id,
            "category_id": category_id
        }
    })
}

export const getSimilarBoats = (id) => {
    return api.get(`/api/boats/${id}/equivalents`)
}

export const getSimilarServices = (id) => {
    return api.get(`/api/services/${id}/equivalents`)
}

export const writeServiceComment = (product_id, comment, rating) => {
    return api.post("/api/service/" + product_id + "/comments", {body: comment, rating: rating})
}

export const getServiceComments = (product_id) => {
    return api.get("/api/service/" + product_id + "/comments")
}

export const getServiceCanComment = (product_id) => {
    return api.get("/api/service/" + product_id + "/canComment")
}

export const updateServiceComment = (id, data) => {
    return api.put("/api/service/comments/" + id, data)
}

export const deleteServiceComment = (id) => {
    return api.delete("/api/service/comments/" + id)
}