import ProductPictures from "./components/ProductPictures/ProductPictures";
import ShopCard from "../Marketplace/components/Cards/ShopCard";
import React, {useEffect, useState} from "react";
import {getProductById, getServiceById} from "../../services/marketplace/API";
import ProductGeneralCard from "./components/ProductDetailCard/ProductGeneralCard";
import PurchaseProduct from "./components/PurchaseProduct/PurchaseProduct";
import ProductDetailsTabs from "./components/ProductDetailCard/ProductDetailsTabs";
import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";

export default function ServiceDetails() {
    const [product, setProduct] = useState({});
    const [width, setWidth] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorSubtitle,setErrorSubtitle] = useState(null);
    const {t} = useTranslation();
    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
        return () => {
            window.removeEventListener('resize', () => {
                setWidth(window.innerWidth)
            })
        }
    }, []);

    const {id} = useParams();

    useEffect(() => {
        setLoading(true)
        getServiceById(id).then((response) => {
            if (response.status === 200) {
                setProduct(response.data , "data response")
                setError(null)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            if (e.response.status === 404) {
                setError("Service not found.")
                setErrorSubtitle("We couldn't find the service you're looking for. Please check the ID or try again later.")
            }
            setLoading(false)
        })
    }, [id]);

    const navigate = useNavigate();

    if (loading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div className="loader"></div>
            </div>
        )
    }
    if (error) {
        return (
            <div className="w-full h-full flex items-center justify-center flex-col gap-2">
                <img src="/images/undraw_no_data.svg" alt="No data" className="mb-4"/>
                <p className="text-xl text-primaryDark font-semibold text-center">{error}</p>
                <p className="text-center text-gray-500 mb-4">{errorSubtitle}</p>
                <div
                    onClick={() => navigate("/")}
                    className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryLight">
                    <p className="font-medium text-white">{t("productDetails.error.returnToHome")}</p>
                </div>
            </div>
        )
    }
    return (
        <div className="w-full h-full p-2 sm:px-6 2xl:px-[11vw] flex flex-col sm:flex-row sm:py-8 gap-4">
            <div className="flex flex-col items-center gap-8 w-full sm:w-[33vw] lg:w-[25vw] 2xl:w-[calc(19.5vw-4px)]">
                <ProductPictures photos={product.data?.photos}/>
                {width < 1024 && width > 640 ?
                    <PurchaseProduct id={id} product={product} type={"service"}/>
                    :
                    null
                }
                <ShopCard {...product.data?.shop}/>
            </div>
            <div className="flex flex-col gap-8 w-full sm:w-[60vw] lg:w-[45vw] 2xl:w-[calc(39vw-8px)] h-full">
                <ProductGeneralCard serivce={product.data}/>
                <ProductDetailsTabs serivce={product.data} ServiceConditions={product.data?.service_conditions}/>
            </div>
            {width > 1024 || width <= 640 ?
                <div
                    className="h-full w-full sm:w-[33vw] lg:w-[22vw] 2xl:w-[calc(19.5vw-4px)] flex flex-col items-center">
                    <PurchaseProduct id={id} service={product.data} type={"service"}/>
                </div>
                :
                null
            }
        </div>
    )
}