import React, { useEffect, useState } from "react";
import CustomTabs from "../Tabs/Tabs";
import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    DialogContentText,
} from "@mui/material";
import DataTable from "../dataTable/DataTable";
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import {
    getProducts,
    getServices,
    getUsers,
    updateProductStatus,
    updateServiceStatus,
    changeUserStatus, getOffers, updateOfferStatus
} from "../../../../services/AdminSapce/API";
import {useNavigate} from "react-router";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import { useTranslation } from 'react-i18next';

export default function Soumissions() {
    const { t } = useTranslation();

    const secondaryMenuItems = [
        { id: 'produit', label: t('adminPages.validation.secondaryMenuItems.product'), icon: '/resources/product.svg' },
        { id: 'service', label: t('adminPages.validation.secondaryMenuItems.service'), icon: '/resources/service.svg' },
        { id: 'sellers', label: t('adminPages.validation.secondaryMenuItems.sellers'), icon: '/resources/seller.svg' },
        { id: "offers", label: t('adminPages.validation.secondaryMenuItems.offers'), icon: "/resources/offer.svg"}
    ];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTab, setCurrentTab] = useState('produit');
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [justification, setJustification] = useState('');
    const [rowsProduit, setRowsProduit] = useState([]);
    const [rowsService, setRowsService] = useState([]);
    const [rowsSellers, setRowsSellers] = useState([]);
    const [rowsOffers, setRowsOffers] = useState([]);
    const [selectedAll, setSelectedAll] = useState([]);
    const navigate = useNavigate();

    const offerColumns = [
        {
            field: 'Offer',
            headerName: t('adminPages.validation.columns.offer'),
            width: 300,
        },
        {
            field: 'description',
            headerName: t('adminPages.validation.columns.description'),
            width: 300,
        },
        { field: 'discount', headerName: t('adminPages.validation.columns.discount'), width: 150 },
        { field: 'quantity', headerName: t('adminPages.validation.columns.quantity'), width: 150 },
        { field: 'price', headerName: t('adminPages.validation.columns.price'), width: 150 },
        { field: 'start_date', headerName: t('adminPages.validation.columns.startDate'), width: 150 },
        { field: 'end_date', headerName: t('adminPages.validation.columns.endDate'), width: 150 },
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const produitColumns = [
        {
            field: 'Produit',
            headerName: t('adminPages.validation.columns.product'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/product/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Produit} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Produit}
                    </Box>
                </a>
            ),
        },
        {
            field: 'Boutique',
            headerName: t('adminPages.validation.columns.shop'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/shop/${params.row.boutiqueId}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Boutique} src={params.row.avatarBoutiqueUrl} style={{ marginRight: 8 }} />
                        {params.row.Boutique}
                    </Box>
                </a>
            )
        },
        { field: 'dateSoumission', headerName: t('adminPages.validation.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const serviceColumns = [
        {
            field: 'Service',
            headerName: t('adminPages.validation.columns.service'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/service/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Service} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Service}
                    </Box>
                </a>
            )
        },
        {
            field: 'Boutique',
            headerName: t('adminPages.validation.columns.shop'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/shop/${params.row.boutiqueId}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Boutique} src={params.row.avatarBoutiqueUrl} style={{ marginRight: 8 }} />
                        {params.row.Boutique}
                    </Box>
                </a>
            )
        },
        { field: 'dateSoumission', headerName: t('adminPages.validation.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const sellerColumns = [
        {
            field: 'fullName',
            headerName: t('adminPages.validation.columns.fullName'),
            width: 200,
            renderCell: (params) => (
                <a onClick={() => navigate(`/admin/seller/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        {params.row.fullName}
                    </Box>
                </a>
            )
        },
        { field: 'email', headerName: t('adminPages.validation.columns.email'), width: 250 },
        { field: 'phone', headerName: t('adminPages.validation.columns.phone'), width: 150 },
        {
            field: 'address',
            headerName: t('adminPages.validation.columns.address'),
            width: 300,
            renderCell: (params) => `${params.row.address.street}, ${params.row.address.city}, ${params.row.address.country}`
        },
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex" onClick={(e) => e.stopPropagation()}>
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(e) => {
                                                 e.stopPropagation();
                                                 handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(e) => {
                                                 e.stopPropagation();
                                                 handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            switch(currentTab) {
                case 'produit':
                    const productResponse = await getProducts();
                    if (Array.isArray(productResponse)) {
                        const pendingProducts = productResponse?.filter(product => product.status === "pending");
                        const formattedProducts = pendingProducts.map((product) => ({
                            id: product.id,
                            Produit: product.name,
                            Boutique: product.shop?.name || 'N/A',
                            boutiqueId: product.shop?.id,
                            dateSoumission: product.created_at ? new Date(product.created_at).toLocaleDateString() : 'N/A',
                            avatarUrl: product.brand?.logo || '',
                            avatarBoutiqueUrl: product.shop?.logo || '',
                        }));
                        setRowsProduit(formattedProducts);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validation.toastMessages.fetchError')}/>, toastStyle);
                    }
                    break;

                case 'service':
                    const serviceResponse = await getServices();
                    if (serviceResponse && Array.isArray(serviceResponse.services)) {
                        const pendingServices = serviceResponse.services.filter(service => service.status === "pending");
                        const formattedServices = pendingServices.map((service) => ({
                            id: service.id,
                            Service: service.name,
                            Boutique: service.shop?.name || 'N/A',
                            dateSoumission: service.created_at ? new Date(service.created_at).toLocaleDateString() : 'N/A',
                            avatarUrl: service.photos.length > 0 ? service.photos[0].link : '',
                            avatarBoutiqueUrl: service.shop?.logo || '',
                            boutiqueId: service.shop?.id
                        }));
                        setRowsService(formattedServices);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validation.toastMessages.fetchError')}/>, toastStyle);
                    }
                    break;

                case 'sellers':
                    const usersResponse = await getUsers();
                    if (Array.isArray(usersResponse)) {
                        const pendingSellers = usersResponse.filter(user => user.role_id === 3 && user.status === "pending");
                        const formattedSellers = pendingSellers.map((seller) => ({
                            id: seller.id,
                            fullName: `${seller.first_name} ${seller.last_name}`,
                            email: seller.email,
                            phone: seller.contact?.phone_number || 'N/A',
                            address: seller.contact?.address || { street: 'N/A', city: 'N/A', country: 'N/A' },
                            status: seller.status,
                        }));
                        setRowsSellers(formattedSellers);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validation.toastMessages.fetchError')}/>, toastStyle);
                    }
                    break;

                case 'offers':
                    const offersResponse = await getOffers();
                    if (Array.isArray(offersResponse)) {
                        const pendingOffers = offersResponse.filter(offer => offer.status === "pending");
                        const formattedOffers = pendingOffers.map((offer) => ({
                            id: offer.id,
                            Offer: offer.offer_name,
                            Boutique: offer.products?.name || 'N/A',
                            description: offer.description,
                            discount: offer.discount_percentage,
                            quantity: offer.offer_quantity,
                            price: offer.price,
                            start_date: offer.start_date,
                            end_date: offer.end_date,
                            avatarUrl: offer.shop?.logo || '',
                        }));
                        setRowsOffers(formattedOffers);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validation.toastMessages.fetchError')}/>, toastStyle);
                    }
                    break;
            }
        } catch (error) {
            setError(t('adminPages.validation.toastMessages.fetchError'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentTab]);

    const handleApprove = async (row) => {
        try {
            if (currentTab === 'produit') {
                await updateProductStatus([row.id], 'verified', "Votre produit est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.productApproved')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus([row.id], 'verified', "Votre service est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.serviceApproved')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus([row.id],"active","Votre compte est activé");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.sellerApproved')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus([row.id], 'verified', "Votre offre est acceptée");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.offerApproved')}/>, toastStyle);
            }
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleApproveAll = async (ids) => {
        try {
            if (currentTab === 'produit') {
                await updateProductStatus(ids, 'verified', "Votre produit est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.productApproved')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus(ids, 'verified', "Votre service est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.serviceApproved')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus(ids,"active","Votre compte est activé");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.sellerApproved')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus(ids, 'verified', "Votre offre est acceptée");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.offerApproved')}/>, toastStyle);
            }
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleReject = (row) => {
        if (currentTab === 'sellers') {
            changeUserStatus([row.id],"rejected","Votre compte est disactivé")
                .then(() => fetchData())
                .catch(() => setError('Failed to update status'));
            toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.sellerRejected')}/>, toastStyle);
        } else {
            setSelectedRow(row);
            setOpenRejectDialog(true);
        }
    };

    const handleRejectAll = async (ids) => {
        try {
            if (currentTab === 'produit') {
                await updateProductStatus(ids, 'rejected', "votre produits est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.productRejected')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus(ids, 'rejected', "votre services est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.serviceRejected')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus(ids,"rejected","Votre compte est disactivé");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.sellerRejected')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus(ids, 'rejected', "votre offre est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.offerRejected')}/>, toastStyle);
            }
            setJustification('');
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleRejectSubmit = async () => {
        if (!selectedRow) return;
        try {
            if (currentTab === 'produit') {
                await updateProductStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.productRejected')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.serviceRejected')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validation.toastMessages.offerRejected')}/>, toastStyle);
            }
            setOpenRejectDialog(false);
            setJustification('');
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleCancel = () => {
        setOpenRejectDialog(false);
        setJustification('');
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
    };

    let columns, rows;
    switch (currentTab) {
        case 'produit':
            columns = produitColumns;
            rows = rowsProduit;
            break;
        case 'service':
            columns = serviceColumns;
            rows = rowsService;
            break;
        case 'sellers':
            columns = sellerColumns;
            rows = rowsSellers;
            break;
        case 'offers':
            columns = offerColumns;
            rows = rowsOffers;
            break;
        default:
            columns = [];
            rows = [];
            break;
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh" width="100%">
            <Box flex={1} overflow="hidden" display="flex" flexDirection="row">
                <Box flex={1} padding={3}>
                    <CustomTabs menuItems={secondaryMenuItems} onTabChange={handleTabChange} />
                    <Box display="flex" flexDirection="column" height="100%">
                        {loading ? (
                            <div className="flex items-center justify-center w-full h-full">
                                <div className="loader" />
                            </div>
                        ) : error ? (
                            <Typography color="error">{error}</Typography>
                        ) : (
                            <DataTable
                                key={currentTab}
                                rows={rows}
                                columns={columns}
                                setRows={
                                    currentTab === 'produit'
                                        ? setRowsProduit
                                        : currentTab === 'service'
                                            ? setRowsService
                                            : setRowsSellers
                                }
                                showActions={false}
                                showExportImport={false}
                                showAddButton={false}
                                onSelectionChange={(selected) => console.log('Selected:', setSelectedAll(selected))}
                                handleApproveAll={() => handleApproveAll(selectedAll)}
                                handleRejectAll={() => handleRejectAll(selectedAll)}
                                tabLabel={secondaryMenuItems.find((item) => item.id === currentTab)?.label}
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            <Dialog open={openRejectDialog} onClose={handleCancel}>
                <DialogTitle>Justification du rejet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez fournir une justification pour le rejet de cet élément.
                    </DialogContentText>
                    <textarea
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                        placeholder="Entrez la justification ici..."
                        rows={4}
                        className="w-full h-[115px] border border-border rounded px-4 py-2"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" style={{ color: '#FF5722', borderColor: '#FF5722' }}>
                        {t('adminPages.validation.buttons.cancel')}
                    </Button>
                    <Button onClick={handleRejectSubmit} variant="contained" style={{ backgroundColor: '#FF5722', color: '#FFF' }}>
                        {t('adminPages.validation.buttons.confirmRejection')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
