import React, { useEffect, useState } from 'react';
import {
    Box,
    Avatar,
    Typography,
    Modal
} from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {
    getCategories,
    getProductBrands,
    addCategory,
    addProductBrand,
    addSubCategory,
    getSubCategories,
    toggleMonthlyCategories,
    updateCategory,
    updateProductBrand,
    updateSubCategory,
    deleteCategory,
    deleteProductBrand,
    deleteSubCategory
} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import { toast } from "sonner";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import { useTranslation } from 'react-i18next';
import { useDeleteConfirmationContext } from '../../../../components/DeleteConfirmation/DeleteConfirmation';
import { useNonMarketPlaceRoutesContext } from '../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes';

const VerificationStatus = ({ status }) => {
    const { t } = useTranslation();
    return (
        <>
            {(status === t('adminPages.productConfig.statuses.not_selected') || status === 0) ? (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-red-100 border border-red-500 transition-all hover:bg-red-200'>
                    <p className="text-sm font-medium text-red-500">{status === 0 ? t('adminPages.productConfig.statuses.not_selected') : status}</p>
                </div>
            ) : (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-green-100 border border-green-500 transition-all hover:bg-green-200'>
                    <p className="text-sm font-medium text-green-500">{status}</p>
                </div>
            )}
        </>
    );
};

export default function ProductConfig() {
    const { t } = useTranslation();
    const {updateDeletePopUpVisible, updateItemInfo, updateDeleteFunc} = useDeleteConfirmationContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();

    const menuItems = [
        { id: 'product', label: t('adminPages.productConfig.tabs.brands'), icon: '/resources/product.svg' },
        { id: 'category', label: t('adminPages.productConfig.tabs.categories'), icon: '/resources/category.svg' },
        { id: 'sub_category', label: t('adminPages.productConfig.tabs.subcategories'), icon: '/resources/sub_category.svg' }
    ];

    const [currentTab, setCurrentTab] = useState('product');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [formData, setFormData] = useState({ name: '', category: '', description: '', logo: '', type: '', img: '' });
    const [categoriesList, setCategoriesList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleDelete = (item) => {
        let deleteFunction;
        let itemName = item.brand_name || item.name;
        let itemType;
        let confirmMessage;

        if (currentTab === 'product') {
            deleteFunction = (id) => deleteProductBrand(id);
            itemType = t('adminPages.productConfig.labels.brand');
            confirmMessage = t('adminPages.productConfig.messages.delete_brand_confirm');
        } else if (currentTab === 'category') {
            deleteFunction = (id) => deleteCategory(id);
            itemType = t('adminPages.productConfig.labels.category');
            confirmMessage = t('adminPages.productConfig.messages.delete_category_confirm');
        } else if (currentTab === 'sub_category') {
            deleteFunction = (id) => deleteSubCategory(id);
            itemType = t('adminPages.productConfig.labels.subcategory');
            confirmMessage = t('adminPages.productConfig.messages.delete_subcategory_confirm');
        }

        const func = (id) => deleteFunction(id)
            .then(() => {
                toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.delete_success')} />, toastStyle);
                fetchData(currentTab);
            })
            .catch(() => {
                toast.error(<ToastError message={t('adminPages.productConfig.messages.delete_error')} />, toastStyle);
            })
            .finally(() => {
                updateDeletePopUpVisible(false);
                updatePopUpVisible(false);
                updateItemInfo("","","");
            });

        updateDeleteFunc(() => func(item.id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(itemName, itemType, confirmMessage);
    };

    const getProductColumns = () => [
        {
            field: 'brand_name',
            headerName: t('adminPages.productConfig.labels.brand_name'),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Box display="flex" alignItems="center" className="gap-3">
                    <Avatar alt={params.row.brand_name} src={params.row.logo} className="w-10 h-10" />
                    <Typography className="font-medium">{params.row.brand_name}</Typography>
                </Box>
            ),
        },
        {
            field: 'created_at',
            headerName: t('adminPages.productConfig.labels.created_at'),
            flex: 0.7,
            minWidth: 150,
        },
        {
            field: 'actions',
            headerName: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <Box display="flex" className="justify-center w-full">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={2}
                                             handleClick={() => handleModify(params.row)}/>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={3}
                                             handleClick={() => handleDelete(params.row)}/>
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const getCategoryColumns = () => [
        { field: 'name', headerName: t('adminPages.productConfig.labels.category_name'), width: 100 },
        { field: 'description', headerName: t('adminPages.productConfig.labels.description'), width: 100 },
        { field: 'status', headerName: t('adminPages.productConfig.labels.status'), width: 100, renderCell: (params) => <VerificationStatus status={params.row.status} />, },
        {
            field: 'actions',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <Box display="flex" className="justify-center w-full">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={2}
                                             handleClick={() => handleModify(params.row)}/>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.pinned_category')} index={2}
                                             handleClick={() => handleToggleMonthly(params.row.id,params.row.status)}/>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={3}
                                             handleClick={() => handleDelete(params.row)}/>
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const getSubCategoryColumns = () => [
        { field: 'name', headerName: t('adminPages.productConfig.labels.subcategory_name'), flex: 1, minWidth: 100},
        { field: 'category', headerName: t('adminPages.productConfig.labels.category'), flex: 1, minWidth: 100 },
        { field: 'description', headerName: t('adminPages.productConfig.labels.description'), flex: 1, minWidth: 100 },
        {
            field: 'actions',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <Box display="flex" className="justify-center w-full">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={2}
                                             handleClick={() => handleModify(params.row)}/>
                        <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={3}
                                             handleClick={() => handleDelete(params.row)}/>
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const fetchProductBrands = async () => {
        try {
            setRows([]); // Clear rows before fetching new data
            const productBrands = await getProductBrands();
            setRows(productBrands
                .filter(brand => brand.type === 'product')
                .map(brand => ({
                    id: brand.id,
                    brand_name: brand.brand_name,
                    created_at: new Date(brand.created_at).toLocaleDateString('fr-FR'),
                    logo: brand.logo,
                })));
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.data_fetch_error')} />, toastStyle);
            setError('Failed to fetch data');
        }
    };

    const fetchCategories = async () => {
        try {
            setRows([]); // Clear rows before fetching new data
            const categories = await getCategories();
            const productCategories = categories?.productCategories;
            setRows(productCategories.map(category => ({
                id: category.id,
                name: category.category_name,
                description: category.description,
                status: category.monthly_category ? t('adminPages.productConfig.statuses.selected') : t('adminPages.productConfig.statuses.not_selected'),
                img: category.img
            })));
            setCategoriesList(productCategories);
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.data_fetch_error')} />, toastStyle);
            setError('Failed to fetch data');
        }
    };

    const fetchSubCategories = async () => {
        try {
            setRows([]); // Clear rows before fetching new data
            const subCategories = await getSubCategories();
            const productSubCategories = subCategories.filter(subCat => subCat.category.type === 'product');
            setRows(productSubCategories.map(subCat => ({
                id: subCat.id,
                name: subCat.name,
                category: subCat.category.category_name,
                description: subCat.description || 'N/A',
            })));
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.data_fetch_error')} />, toastStyle);
            setError('Failed to fetch data');
        }
    };

    const fetchData = async (tab) => {
        setLoading(true);
        setError(null);
        setRows([]); // Clear rows before switching tabs

        // Set columns based on tab immediately
        switch(tab) {
            case 'product':
                setColumns(getProductColumns());
                await fetchProductBrands();
                break;
            case 'category':
                setColumns(getCategoryColumns());
                await fetchCategories();
                break;
            case 'sub_category':
                setColumns(getSubCategoryColumns());
                await fetchCategories();
                await fetchSubCategories();
                break;
            default:
                break;
        }

        setLoading(false);
    };

    const handleToggleMonthly = async (categoryId, currentMonthlyStatus) => {
        try {
            if(currentMonthlyStatus === t('adminPages.productConfig.statuses.selected')){
                await toggleMonthlyCategories([categoryId], false);
                toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.monthly_status_updated')} />, toastStyle);
                fetchData(currentTab);}
            else{
                if (currentMonthlyStatus === t('adminPages.productConfig.statuses.not_selected')) {
                    await toggleMonthlyCategories([categoryId], true);
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.monthly_status_updated')} />, toastStyle);
                    fetchData(currentTab);}}
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.toggle_monthly_error')} />, toastStyle);
        }
    };

    const handleModify = (item) => {
        setSelectedItem(item);
        setFormData({
            name: item.brand_name || item.name,
            description: item.description,
            logo: item.logo,
            category: item.category,
            img: item.img
        });
        setIsEditing(true);
        setOpenAddDialog(true);
    };

    useEffect(() => {
        fetchData(currentTab);
    }, [currentTab]);

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
        localStorage.setItem('currentTab', tabId);
        setRows([]); // Clear rows before switching tabs
        fetchData(tabId);
    };

    const handleAddClick = () => {
        setOpenAddDialog(true);
        setFormData({ name: '', category: '', description: '', logo: '', type: '', img: '' });
        setIsEditing(false);
    };

    const validateForm = () => {
        if (!formData.name) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.name_required')} />, toastStyle);
            return false;
        }
        if (currentTab === 'product' && !formData.logo) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.logo_required')} />, toastStyle);
            return false;
        }
        if (currentTab === 'category' && !formData.img) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.image_required')} />, toastStyle);
            return false;
        }
        if (currentTab === 'category' && !formData.description) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.description_required')} />, toastStyle);
            return false;
        }
        if(currentTab==='sub_category' && !formData.category){
            toast.error(<ToastError message={t('adminPages.productConfig.messages.category_required')} />, toastStyle);
            return false;
        }
        return true;
    };

    const handleAddSubmit = async () => {
        if (!validateForm()) return
        try {
            if (isEditing) {
                if (currentTab === 'product') {
                    await updateProductBrand(selectedItem.id, { brand_name: formData.name, logo: formData.logo, type: 'product' });
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.update_brand_success')} />, toastStyle);
                } else if (currentTab === 'category') {
                    await updateCategory(selectedItem.id, { category_name: formData.name, description: formData.description, type: 'product', img: formData.img });
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.update_category_success')} />, toastStyle);
                } else if (currentTab === 'sub_category') {
                    await updateSubCategory(selectedItem.id, {
                        name: formData.name,
                        category_id: formData.category,
                        description: formData.description,
                    });
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.update_subcategory_success')} />, toastStyle);
                }
            } else {
                if (currentTab === 'product') {
                    await addProductBrand({ brand_name: formData.name, logo: formData.logo, type: 'product' });
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.add_brand_success')} />, toastStyle);
                } else if (currentTab === 'category') {
                    await addCategory({ category_name: formData.name, description: formData.description, type: 'product', img: formData.img });
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.add_category_success')} />, toastStyle);
                } else if (currentTab === 'sub_category') {
                    await addSubCategory({
                        name: formData.name,
                        category_id: formData.category,
                        description: formData.description,
                    });
                    toast.success(<ToastSuccess message={t('adminPages.productConfig.messages.add_subcategory_success')} />, toastStyle);
                }
            }
            setOpenAddDialog(false);
            fetchData(currentTab);
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.productConfig.messages.data_add_error')} />, toastStyle);
            setError('Failed to add data');
        }
    };

    const handleCancel = () => {
        setOpenAddDialog(false);
        setSelectedItem(null);
        setIsEditing(false);
    };

    return (
        <Box className="w-full h-full flex flex-col">
            <Box className="p-2 md:p-4">
                <CustomTabs menuItems={menuItems} onTabChange={handleTabChange} />
            </Box>
            <Box  className="p-3 md:p-6 flex-1 flex flex-col ">
                {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="loader"></div>
                    </div>
                ) : error ? (
                    <Typography color="error" className="text-center">{error}</Typography>
                ) : (
                    <DataTable
                        key={currentTab}
                        rows={rows}
                        columns={columns}
                        showActions={false}
                        showExportImport={false}
                        showAddButton={true}
                        showSelectAll={false}
                        showApproveRejectButtons={false}
                        onAddRow={handleAddClick}
                        tabLabel={menuItems.find((item) => item.id === currentTab)?.label}
                    />
                )}
            </Box>

            <Modal
                open={openAddDialog}
                onClose={handleCancel}
                className="flex items-center justify-center p-4"
            >
                <div className="bg-white rounded-lg p-4 md:p-6 w-full max-w-md mx-auto shadow-lg">
                    <h3 className="text-lg md:text-xl font-semibold text-center mb-6">
                        {isEditing ? t('adminPages.productConfig.buttons.modify') : (
                            <>
                                {currentTab === 'product' && t('adminPages.productConfig.buttons.add_brand')}
                                {currentTab === 'category' && t('adminPages.productConfig.buttons.add_category')}
                                {currentTab === 'sub_category' && t('adminPages.productConfig.buttons.add_subcategory')}
                            </>
                        )}
                    </h3>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.productConfig.labels.brand_name')}</label>
                            <input
                                type="text"
                                placeholder={menuItems.find((item) => item.id === currentTab)?.label}
                                className="w-full h-11 border border-gray-300 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                required
                            />
                        </div>

                        {currentTab === 'product' && (
                            <div>
                                <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.productConfig.labels.logo_url')}</label>
                                <input
                                    placeholder={t('adminPages.productConfig.labels.logo_url')}
                                    type="text"
                                    className="w-full h-11 border border-gray-300 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
                                    value={formData.logo}
                                    onChange={(e) => setFormData({ ...formData, logo: e.target.value })}
                                />
                            </div>
                        )}

                        {currentTab === 'category' && (
                            <>
                                <div>
                                    <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.productConfig.labels.description')}</label>
                                    <textarea
                                        placeholder={t('adminPages.productConfig.labels.description')}
                                        className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all resize-none"
                                        value={formData.description}
                                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                        rows={4}
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.productConfig.labels.image_url')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('adminPages.productConfig.labels.image_url')}
                                        className="w-full h-11 border border-gray-300 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
                                        value={formData.img}
                                        onChange={(e) => setFormData({ ...formData, img: e.target.value })}
                                    />
                                </div>
                            </>
                        )}

                        {currentTab === 'sub_category' && (
                            <>
                                <div>
                                    <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.productConfig.labels.category')}</label>
                                    <GenericSelect
                                        isMulti={false}
                                        inputs={categoriesList.map(category => ({ value: category.id, label: category.category_name }))}
                                        defaults={categoriesList.filter(category => formData.category === category.id).map(category => ({ value: category.id, label: category.category_name }))}
                                        handleChanges={(selectedOption) => setFormData({ ...formData, category: selectedOption.value })}
                                        setWidth="100%"
                                        placeholder={t('adminPages.productConfig.labels.select_category')}
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.productConfig.labels.description')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('adminPages.productConfig.labels.description')}
                                        className="w-full h-11 border border-gray-300 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
                                        value={formData.description}
                                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="flex justify-end mt-6 gap-4">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="px-6 py-2.5 text-orange-500 font-medium hover:bg-orange-50 rounded-lg transition-colors"
                        >
                            {t('adminPages.productConfig.buttons.cancel')}
                        </button>
                        <button
                            onClick={handleAddSubmit}
                            type="button"
                            className={`px-6 py-2.5 rounded-lg text-white font-medium transition-all
                                ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-600 active:bg-orange-700'}`}
                            disabled={loading}
                        >
                            {isEditing ? t('adminPages.productConfig.buttons.save') : t('adminPages.productConfig.buttons.add')}
                        </button>
                    </div>
                </div>
            </Modal>
        </Box>
    );
}
