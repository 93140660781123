import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Modal,
} from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {
    getCategories,
    addCategory,
    addSubCategory,
    getSubCategories,
    getConditions,
    addCondition,
    toggleMonthlyCategories,
    updateCategory,
    updateSubCategory,
    updateCondition,
    deleteCategory,
    deleteSubCategory,
    deleteCondition
} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import { toast } from "sonner";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import { useTranslation } from 'react-i18next';
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";

const VerificationStatus = ({ status }) => {
    const { t } = useTranslation();
    return (
        <>
            {(status === t('adminPages.serviceConfig.statuses.not_selected') || status === 0) ? (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-red-100 border border-red-500'>
                    <p className="text-sm font-medium text-red-500">{status === 0 ? t('adminPages.serviceConfig.statuses.not_selected') : status}</p>
                </div>
            ) : (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-green-100 border border-green-500'>
                    <p className="text-sm font-medium text-green-500">{status}</p>
                </div>
            )}
        </>
    );
};

export default function ConfigService() {
    const { t } = useTranslation();
    const menuItems = [
        { id: 'category', label: t('adminPages.serviceConfig.tabs.categories'), icon: '/resources/category.svg' },
        { id: 'sub_category', label: t('adminPages.serviceConfig.tabs.sub_categories'), icon: '/resources/sub_category.svg' },
        { id: 'condition', label: t('adminPages.serviceConfig.tabs.conditions'), icon: '/resources/conditions.svg' },
    ];

    const [currentTab, setCurrentTab] = useState('category');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [formData, setFormData] = useState({ name: '', category: '', description: '', type: 'service', conditionType: '', img: '' });
    const [categoriesList, setCategoriesList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const handleDelete = async (row) => {
        try {
            if (currentTab === 'category') {
                await deleteCategory(row.id);
            } else if (currentTab === 'sub_category') {
                await deleteSubCategory(row.id);
            } else if (currentTab === 'condition') {
                await deleteCondition(row.id);
            }
            toast.success(<ToastSuccess message={t('adminPages.serviceConfig.success_messages.data_deleted')} />, toastStyle);
            fetchData(currentTab);
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.delete_data')} />, toastStyle);
        }
    };

    const getColumnsForTab = (tab) => {
        const baseColumns = {
            category: [
                { field: 'name', headerName: t('adminPages.serviceConfig.labels.category_name'), width: 250, sortable: true, filterable: true },
                { field: 'description', headerName: t('adminPages.serviceConfig.labels.description'), width: 200, sortable: true, filterable: true },
                { field: 'status', headerName: t('adminPages.serviceConfig.labels.status'), width: 150, renderCell: (params) => <VerificationStatus status={params.row.status} />, },
                {
                    field: 'actions',
                    headerName: '',
                    width: 150,
                    sortable: true,
                    filterable: true,
                    renderCell: (params) => (
                        <Box display="flex" >
                            <GenericDropDown>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                     handleClick={() => handleEdit(params.row)} />
                                <GenericDropDownItem value={t('adminPages.serviceConfig.actions.pinned_category')} index={2}
                                                     handleClick={() => handleToggleMonthly(params.row.id, params.row.status)} />
                                <GenericDropDownItem value={t('adminPages.serviceConfig.actions.delete')} index={3}
                                                     handleClick={() => handleDelete(params.row)} />
                            </GenericDropDown>
                        </Box>
                    ),
                }
            ],
            sub_category: [
                { field: 'name', headerName: t('adminPages.serviceConfig.labels.sub_category_name'), width: 250, sortable: true, filterable: true },
                { field: 'category', headerName: t('adminPages.serviceConfig.labels.category_name'), width: 200, sortable: true, filterable: true },
                { field: 'description', headerName: t('adminPages.serviceConfig.labels.description'), width: 200, sortable: true, filterable: true },
                {
                    field: 'actions',
                    headerName: '',
                    width: 150,
                    sortable: true,
                    filterable: true,
                    renderCell: (params) => (
                        <Box display="flex" >
                            <GenericDropDown>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                     handleClick={() => handleEdit(params.row)} />
                                <GenericDropDownItem value={t('adminPages.serviceConfig.actions.delete')} index={2}
                                                     handleClick={() => handleDelete(params.row)} />
                            </GenericDropDown>
                        </Box>
                    ),
                }
            ],
            condition: [
                { field: 'name', headerName: t('adminPages.serviceConfig.labels.condition_name'), width: 250, sortable: true, filterable: true },
                { field: 'description', headerName: t('adminPages.serviceConfig.labels.description'), width: 200, sortable: true, filterable: true },
                {
                    field: 'actions',
                    headerName: '',
                    width: 150,
                    sortable: true,
                    filterable: true,
                    renderCell: (params) => (
                        <Box display="flex" >
                            <GenericDropDown>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                     handleClick={() => handleEdit(params.row)} />
                                <GenericDropDownItem value={t('adminPages.serviceConfig.actions.delete')} index={2}
                                                     handleClick={() => handleDelete(params.row)} />
                            </GenericDropDown>
                        </Box>
                    ),
                }
            ]
        };

        return baseColumns[tab] || [];
    };

    const fetchData = async (tab) => {
        setLoading(true);
        setError(null);
        setColumns(getColumnsForTab(tab));
        try {
            if (tab === 'category') {
                const categories = await getCategories();
                const serviceCategories = categories?.serviceCategories;
                setRows(serviceCategories.map(category => ({
                    id: category.id,
                    name: category.category_name,
                    description: category.description,
                    img: category.img,
                    status: category.monthly_category ? t('adminPages.serviceConfig.statuses.selected') : t('adminPages.serviceConfig.statuses.not_selected'),
                })));
                setCategoriesList(serviceCategories);
            } else if (tab === 'sub_category') {
                const subCategories = await getSubCategories();
                const serviceSubCategories = subCategories.filter(subCat => subCat.category.type === 'service');
                setRows(serviceSubCategories.map(subCat => ({
                    id: subCat.id,
                    name: subCat.name,
                    category: subCat.category.category_name,
                    category_id: subCat.category.id,
                    description: subCat.description || 'N/A',
                })));
            } else if (tab === 'condition') {
                const conditions = await getConditions();
                setRows(conditions.map(condition => ({
                    id: condition.id,
                    name: condition.name,
                    description: condition.condition_description || 'N/A',
                })));
            }
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.fetch_data')} />, toastStyle);
            setError(t('adminPages.serviceConfig.errors.fetch_data'));
            setRows([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentTab);
    }, [currentTab]);

    const handleToggleMonthly = async (categoryId, currentMonthlyStatus) => {
        try {
            if (currentMonthlyStatus === t('adminPages.serviceConfig.statuses.selected')) {
                await toggleMonthlyCategories([categoryId], false);
                toast.success(<ToastSuccess message={t('adminPages.serviceConfig.success_messages.status_updated')} />, toastStyle);
                fetchData(currentTab);
            } else {
                if (currentMonthlyStatus === t('adminPages.serviceConfig.statuses.not_selected')) {
                    await toggleMonthlyCategories([categoryId], true);
                    toast.success(<ToastSuccess message={t('adminPages.serviceConfig.success_messages.status_updated')} />, toastStyle);
                    fetchData(currentTab);
                }
            }
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.toggle_status')} />, toastStyle);
        }
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
        localStorage.setItem('currentTab', tabId);
        fetchData(tabId);
    };

    const handleEdit = (row) => {
        setIsEditing(true);
        setFormData({
            id: row.id,
            name: row.name,
            description: row.description,
            category: row.category_id,
            img: row.img,
            type: 'service'
        });
        setOpenAddDialog(true);
    };

    const handleAddClick = () => {
        setIsEditing(false);
        setOpenAddDialog(true);
        setFormData({ name: '', category: '', description: '', type: 'service', conditionType: '', img: '' });
    };

    const validateForm = () => {
        if (!formData.name) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.required_name')} />, toastStyle);
            return false;
        }

        if (currentTab === 'category' && !formData.description) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.required_description')} />, toastStyle);
            return false;
        }
        if (currentTab === 'category' && !formData.img) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.required_image')} />, toastStyle);
            return false;
        }

        if (currentTab === 'sub_category' && !formData.category) {
            toast.error(<ToastError message={t('adminPages.serviceConfig.errors.select_category')} />, toastStyle);
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            if (currentTab === 'category') {
                const data = {
                    category_name: formData.name,
                    description: formData.description,
                    img: formData.img,
                    type: 'service',
                };
                if (isEditing) {
                    await updateCategory(formData.id, data);
                } else {
                    await addCategory(data);
                }
            } else if (currentTab === 'sub_category') {
                const data = {
                    name: formData.name,
                    category_id: formData.category,
                    description: formData.description,
                };
                if (isEditing) {
                    await updateSubCategory(formData.id, data);
                } else {
                    await addSubCategory(data);
                }
            } else if (currentTab === 'condition') {
                const data = {
                    name: formData.name,
                    condition_description: formData.description,
                };
                if (isEditing) {
                    await updateCondition(formData.id, data);
                } else {
                    await addCondition(data);
                }
            }
            toast.success(<ToastSuccess message={isEditing ? t('adminPages.serviceConfig.success_messages.data_updated') : t('adminPages.serviceConfig.success_messages.data_added')} />, toastStyle);
            setOpenAddDialog(false);
            fetchData(currentTab);
        } catch (err) {
            toast.error(<ToastError message={isEditing ? t('adminPages.serviceConfig.errors.update_data') : t('adminPages.serviceConfig.errors.add_data')} />, toastStyle);
            setError(isEditing ? t('adminPages.serviceConfig.errors.update_data') : t('adminPages.serviceConfig.errors.add_data'));
        }
    };

    const handleCancel = () => {
        setOpenAddDialog(false);
        setIsEditing(false);
    };

    return (
        <Box className="w-full h-full flex flex-col">
            <Box p={2}>
                <CustomTabs menuItems={menuItems} onTabChange={handleTabChange} />
            </Box>
            <Box p={3} flex="1" display="flex" flexDirection="column" >
                {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="loader"></div>
                    </div>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <DataTable
                        key={currentTab}
                        rows={rows}
                        columns={columns}
                        showActions={false}
                        showExportImport={false}
                        showAddButton={true}
                        onAddRow={handleAddClick}
                        showSelectAll={false}
                        showApproveRejectButtons={false}
                        tabLabel={menuItems.find((item) => item.id === currentTab)?.label}
                    />
                )}
            </Box>

            <Modal open={openAddDialog} onClose={handleCancel}>
                <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <h3 className="text-lg font-semibold text-center mb-4">
                        {isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.productConfig.buttons.add')} {menuItems.find((item) => item.id === currentTab)?.label}
                    </h3>

                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.serviceConfig.modal.name')}</label>
                        <input
                            type="text"
                            placeholder={currentTab === 'category' ? t('adminPages.serviceConfig.placeholders.category_name') :
                                currentTab === 'sub_category' ? t('adminPages.serviceConfig.placeholders.sub_category_name') :
                                    t('adminPages.serviceConfig.placeholders.condition_name')}
                            className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </div>

                    {currentTab === 'sub_category' && (
                        <div className="mb-6">
                            <label className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.serviceConfig.labels.category_name')}</label>
                            <GenericSelect
                                isMulti={false}
                                inputs={categoriesList.map(category => ({
                                    value: category.id,
                                    label: category.category_name
                                }))}
                                defaults={categoriesList.filter(category => formData.category === category.id).map(category => ({
                                    value: category.id,
                                    label: category.category_name
                                }))}
                                handleChanges={(selectedOption) => setFormData({
                                    ...formData,
                                    category: selectedOption.value
                                })}
                                setWidth="100%"
                                placeholder={t('adminPages.serviceConfig.labels.select_category')}
                            />
                        </div>
                    )}

                    {currentTab === 'category' && (
                        <div className="mb-6">
                            <label className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.serviceConfig.labels.image_url')}</label>
                            <input
                                type="text"
                                placeholder={t('adminPages.serviceConfig.placeholders.image_url')}
                                className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                                value={formData.img}
                                onChange={(e) => setFormData({ ...formData, img: e.target.value })}
                            />
                        </div>
                    )}

                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.serviceConfig.modal.description')}</label>
                        <textarea
                            className="w-full border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                            placeholder={currentTab === 'category' ? t('adminPages.serviceConfig.placeholders.category_description') :
                                currentTab === 'sub_category' ? t('adminPages.serviceConfig.placeholders.sub_category_description') :
                                    t('adminPages.serviceConfig.placeholders.condition_description')}
                            value={formData.description}
                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            rows={4}
                        />
                    </div>

                    <div className="flex justify-end">
                        <button
                            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded mr-2"
                            onClick={handleCancel}
                        >
                            {t('adminPages.serviceConfig.actions.cancel')}
                        </button>
                        <button
                            className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded"
                            onClick={handleSubmit}
                        >
                            {isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.serviceConfig.actions.add')}
                        </button>
                    </div>
                </div>
            </Modal>
        </Box>
    );
}
