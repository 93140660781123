import React, { useEffect, useState } from "react";
import CustomTabs from "../Tabs/Tabs";
import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    DialogContentText,
} from "@mui/material";
import DataTable from "../dataTable/DataTable";
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import {
    changeBoatStatus,
    getCars,
    updateCarStatus,
    UpdateEnginStatus
} from "../../../../services/AdminSapce/API";
import {useNavigate} from "react-router";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import { useTranslation } from "react-i18next";

export default function VehicleSubmissions() {
    const { t } = useTranslation();

    const secondaryMenuItems = [
        { id: 'cars', label: t('adminPages.validationVehicles.menu.cars'), icon: '/resources/car.svg' },
        { id: 'motorcycles', label: t('adminPages.validationVehicles.menu.motorcycles'), icon: '/resources/motorcycle.svg' },
        { id: 'boats', label: t('adminPages.validationVehicles.menu.boats'), icon: '/resources/boat.svg' },
        { id: 'engines', label: t('adminPages.validationVehicles.menu.engines'), icon: '/resources/engine.svg' }
    ];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTab, setCurrentTab] = useState('cars');
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [justification, setJustification] = useState('');
    const [rowsCars, setRowsCars] = useState([]);
    const [rowsMotorcycles, setRowsMotorcycles] = useState([]);
    const [rowsBoats, setRowsBoats] = useState([]);
    const [rowsEngines, setRowsEngines] = useState([]);
    const [selectedAll, setSelectedAll] = useState([]);
    const navigate = useNavigate();

    const carColumns = [
        {
            field: 'Vehicle',
            headerName: t('adminPages.validationVehicles.columns.vehicle'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/vehicle/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Vehicle} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Vehicle}
                    </Box>
                </a>
            ),
        },
        { field: 'Year', headerName: t('adminPages.validationVehicles.columns.year'), width: 100 },
        { field: 'Mileage', headerName: t('adminPages.validationVehicles.columns.mileage'), width: 150 },
        { field: 'Energy', headerName: t('adminPages.validationVehicles.columns.energy'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const motorcycleColumns = [
        {
            field: 'Vehicle',
            headerName: t('adminPages.validationVehicles.columns.vehicle'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/motorcycle/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Vehicle} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Vehicle}
                    </Box>
                </a>
            ),
        },
        { field: 'Year', headerName: t('adminPages.validationVehicles.columns.year'), width: 100 },
        { field: 'Mileage', headerName: t('adminPages.validationVehicles.columns.mileage'), width: 150 },
        { field: 'Energy', headerName: t('adminPages.validationVehicles.columns.energy'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const boatColumns = [
        {
            field: 'Boat',
            headerName: t('adminPages.validationVehicles.menu.boats'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/boat/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Boat} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Boat}
                    </Box>
                </a>
            )
        },
        { field: 'Type', headerName: t('adminPages.validationVehicles.columns.type'), width: 150 },
        { field: 'Width', headerName: t('adminPages.validationVehicles.columns.width'), width: 100 },
        { field: 'Length', headerName: t('adminPages.validationVehicles.columns.length'), width: 100 },
        { field: 'Material', headerName: t('adminPages.validationVehicles.columns.material'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const engineColumns = [
        {
            field: 'Engine',
            headerName: t('adminPages.validationVehicles.menu.engines'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/engine/${params.row.id}`)}>
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Engine} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Engine}
                    </Box>
                </a>
            )
        },
        { field: 'Type', headerName: t('adminPages.validationVehicles.columns.type'), width: 150 },
        { field: 'description', headerName: t('adminPages.validationVehicles.columns.description'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const fetchData = async (tabType) => {
        setLoading(true);
        setError(null);

        try {
            const response = await getCars();

            switch(tabType) {
                case 'cars':
                    if (Array.isArray(response.cars)) {
                        const pendingCars = response.cars?.filter(car => car.status === "pending");
                        const formattedCars = pendingCars.map((car) => ({
                            id: car.id,
                            Vehicle: car.name,
                            Year: car.year || 'N/A',
                            Mileage: car.mileage ? `${car.mileage} km` : 'N/A',
                            Energy: car.energie || 'N/A',
                            submissionDate: car.created_at ? new Date(car.created_at).toLocaleDateString() : 'N/A',
                            avatarUrl: car.first_photo?.link || '',
                        }));
                        setRowsCars(formattedCars);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validationVehicles.messages.fetchErrorCars')}/>, toastStyle);
                    }
                    break;

                case 'motorcycles':
                    if (Array.isArray(response.motorcycles)) {
                        const pendingMotorcycles = response.motorcycles?.filter(motorcycle => motorcycle.status === "pending");
                        const formattedMotorcycles = pendingMotorcycles.map((motorcycle) => ({
                            id: motorcycle.id,
                            Vehicle: motorcycle.name,
                            Year: motorcycle.year || 'N/A',
                            Mileage: motorcycle.mileage ? `${motorcycle.mileage} km` : 'N/A',
                            Energy: motorcycle.energie || 'N/A',
                            submissionDate: motorcycle.created_at ? new Date(motorcycle.created_at).toLocaleDateString() : 'N/A',
                            avatarUrl: motorcycle.first_photo?.link || '',
                        }));
                        setRowsMotorcycles(formattedMotorcycles);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validationVehicles.messages.fetchErrorMotorcycles')}/>, toastStyle);
                    }
                    break;

                case 'boats':
                    if (Array.isArray(response.boats)) {
                        const pendingBoats = response.boats?.filter(boat => boat.status === "pending");
                        const formattedBoats = pendingBoats.map((boat) => ({
                            id: boat.id,
                            Boat: boat.name,
                            Type: boat.type || 'N/A',
                            Width: boat.width ? `${boat.width}m` : 'N/A',
                            Length: boat.length ? `${boat.length}m` : 'N/A',
                            Material: boat.characteristics?.material || 'N/A',
                            submissionDate: boat.created_at ? new Date(boat.created_at).toLocaleDateString() : 'N/A',
                            avatarUrl: boat.first_photo?.link || '',
                        }));
                        setRowsBoats(formattedBoats);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validationVehicles.messages.fetchErrorBoats')}/>, toastStyle);
                    }
                    break;

                case 'engines':
                    if (Array.isArray(response.engins)) {
                        const pendingEngines = response.engins.filter(engine => engine.status === "pending");
                        const formattedEngines = pendingEngines.map((engine) => ({
                            id: engine.id,
                            Engine: engine.name,
                            Type: engine.type || 'N/A',
                            description: engine.description || 'N/A',
                            submissionDate: engine.created_at ? new Date(engine.created_at).toLocaleDateString() : 'N/A',
                            avatarUrl: engine.first_photo || '',
                        }));
                        setRowsEngines(formattedEngines);
                    } else {
                        toast.error(<ToastError message={t('adminPages.validationVehicles.messages.fetchErrorEngines')}/>, toastStyle);
                    }
                    break;
            }

        } catch (error) {
            setError("Failed to fetch data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentTab);
    }, [currentTab]);

    const handleApprove = async (row) => {
        try {
            if (currentTab === 'cars') {
                await updateCarStatus([row.id], 'verified', "Votre véhicule est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus([row.id], 'verified', "Votre moto est acceptée");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus([row.id], 'verified', "Votre bateau est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus([row.id], 'verified', "Votre engin est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessEngine')}/>, toastStyle);
            }
            await fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleApproveAll = async (ids) => {
        try {
            if (currentTab === 'cars') {
                await updateCarStatus(ids, 'verified', "Votre véhicule est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus(ids, 'verified', "Votre moto est acceptée");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus(ids, 'verified', "Votre bateau est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus(ids, 'verified', "Votre engin est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessEngine')}/>, toastStyle);
            }
            await fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleReject = (row) => {
        setSelectedRow(row);
        setOpenRejectDialog(true);
    };

    const handleRejectAll = async (ids) => {
        try {
            if (currentTab === 'cars') {
                await updateCarStatus(ids, 'rejected', "Votre véhicule est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus(ids, 'rejected', "Votre moto est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus(ids, 'rejected', "Votre bateau est  non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus(ids, 'rejected', "Votre engin est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessEngine')}/>, toastStyle);
            }
            setJustification('');
            await fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleRejectSubmit = async () => {
        if (!selectedRow) return;
        try {
            if (currentTab === 'cars') {
                await updateCarStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessEngine')}/>, toastStyle);
            }
            setOpenRejectDialog(false);
            setJustification('');
            await fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleCancel = () => {
        setOpenRejectDialog(false);
        setJustification('');
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
    };

    let columns, rows;
    switch (currentTab) {
        case 'cars':
            columns = carColumns;
            rows = rowsCars;
            break;
        case 'motorcycles':
            columns = motorcycleColumns;
            rows = rowsMotorcycles;
            break;
        case 'boats':
            columns = boatColumns;
            rows = rowsBoats;
            break;
        case 'engines':
            columns = engineColumns;
            rows = rowsEngines;
            break;
        default:
            columns = [];
            rows = [];
            break;
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh" width="100%">
            <Box flex={1} overflow="hidden" display="flex" flexDirection="row">
                <Box flex={1} padding={3}>
                    <CustomTabs menuItems={secondaryMenuItems} onTabChange={handleTabChange} />
                    <Box display="flex" flexDirection="column" height="100%">
                        {loading ? (
                            <div className="flex items-center justify-center w-full h-full">
                                <div className="loader" />
                            </div>
                        ) : error ? (
                            <Typography color="error">{error}</Typography>
                        ) : (
                            <DataTable
                                key={currentTab}
                                rows={rows}
                                columns={columns}
                                setRows={
                                    currentTab === 'cars' ? setRowsCars :
                                        currentTab === 'motorcycles' ? setRowsMotorcycles :
                                            currentTab === 'boats' ? setRowsBoats :
                                                setRowsEngines
                                }
                                showActions={false}
                                showExportImport={false}
                                showAddButton={false}
                                onSelectionChange={(selected) => setSelectedAll(selected)}
                                handleApproveAll={() => handleApproveAll(selectedAll)}
                                handleRejectAll={() => handleRejectAll(selectedAll)}
                                tabLabel={secondaryMenuItems.find((item) => item.id === currentTab)?.label}
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            <Dialog open={openRejectDialog} onClose={handleCancel}>
                <DialogTitle>{t('adminPages.validationVehicles.dialogs.rejectTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('adminPages.validationVehicles.dialogs.rejectMessage')}
                    </DialogContentText>
                    <textarea
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                        placeholder="Entrez la justification ici..."
                        rows={4}
                        className="w-full h-[115px] border border-border rounded px-4 py-2"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" style={{ color: '#FF5722', borderColor: '#FF5722' }}>
                        {t('adminPages.validationVehicles.dialogs.cancel')}
                    </Button>
                    <Button onClick={handleRejectSubmit} variant="contained" style={{ backgroundColor: '#FF5722', color: '#FFF' }}>
                        {t('adminPages.validationVehicles.dialogs.confirmReject')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}