export default function WidgetElement({title = "Commandes d'aujourd'hui", amount = "0", percentage = "0.00%",weekRange=null}) {
    return (
        <div
            className='w-full min-h-[150px] bg-white border border-border rounded-lg p-4 flex flex-col items-start justify-between'>
            <div className="flex flex-row items-center justify-between w-full">
                <p className="text-[#797979] text-sm">{title}</p>
                {weekRange === null ? <p
                    className="text-sm font-medium">{new Date().toLocaleDateString('fr-FR', {
                    day: 'numeric',
                    month: 'long'
                })}</p> : <p className="text-sm font-medium">{weekRange}</p>}

            </div>
            <div className="flex flex-col gap-2 items-start w-full">
                <p className="text-3xl font-medium">{amount}</p>
                <div className="flex flex-row items-center gap-2">
                    {
                        percentage === "0.00%" ?
                            <p className="font-medium text-gray-500">--</p>
                            :
                            percentage.includes('-') ?
                                <img src="/resources/graph-down.svg" alt="graph" className="red_filter"/>
                                :
                                <img src="/resources/graph-up.svg" alt="graph" className="yellow_green_filter"/>
                    }
                    <span style={{color:percentage.includes("-") ? "red" : "yellowgreen"}} className="font-medium"> {percentage}
                        <span className="font-medium text-black"> Qu`hier</span>
                    </span>
                </div>
            </div>
        </div>
    )
}