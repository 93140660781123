import React, {useEffect, useRef, useState} from "react";
import CommentsSection from "../../../../components/CommentsSection/CommentsSection";
import Carousel from "../../../Marketplace/components/Carousel/Carousel";
import {getSimilarProductUsingSubCategoryId} from "../../../../services/marketplace/API";
import {useParams} from "react-router";
import ProductCard from "../../../Marketplace/components/Cards/ProductCard";
import {useTranslation} from "react-i18next";

export default function ProductDetailsTabs({product = null}) {

    const {id} = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const ref = useRef();
    const [equivalentProduct, updateEquivalentProduct] = useState([])

    useEffect(() => {
        getSimilarProductUsingSubCategoryId(product?.sub_category?.id).then((res) => {
            updateEquivalentProduct(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }, [id, product?.sub_category?.id]);
    const {t} = useTranslation();
    return (
        <div className="flex flex-col 2xl:w-[calc(39vw-8px)] h-full">
            <div
                className="flex flex-row items-center w-full px-6 lg:px-16 h-16 min-h-16 border border-border rounded-2xl">
                <div
                    onClick={() => setSelectedTab(0)}
                    style={{
                        borderBottom: selectedTab === 0 ? "2px solid #E44826" : "none",
                        color: selectedTab === 0 ? "#E44826" : "black"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium">{t("productDetails.detailsTab.characteristics")}</p>
                </div>
                <div
                    onClick={() => setSelectedTab(1)}
                    style={{
                        borderBottom: selectedTab === 1 ? "2px solid #E44826" : "none",
                        color: selectedTab === 1 ? "#E44826" : "black"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium">{t("compareProducts.customerReviews")}</p>
                </div>
                <div
                    onClick={() => setSelectedTab(2)}
                    style={{
                        borderBottom: selectedTab === 2 ? "2px solid #E44826" : "none",
                        color: selectedTab === 2 ? "#E44826" : "black"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium">{t("productDetails.detailsTab.equivalence")}</p>
                </div>
            </div>
            <div ref={ref}
                 className="flex flec-row items-center py-4 max-w-[95vw] sm:max-w-[60vw] lg:max-w-[45vw] 2xl:max-w-[calc(39vw-8px)]">
                {selectedTab === 0 &&
                    <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                        <thead
                            className="bg-gray-100 sticky top-0 z-10 min-h-14 h-14 border-b border-border max-h-14">
                        <tr>
                            <th className="px-6 py-3 text-gray-500 text-left text-sm font-medium border-b border-gray-300 border-r">
                                {t("productDetails.detailsTab.characteristics")}
                            </th>
                            <th className="px-6 py-3 text-gray-500 text-left text-sm font-medium border-b border-gray-300">
                                {t("productDetails.detailsTab.value")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {product && product.characteristics && Object.keys(product.characteristics).length > 0 ? (
                            Object.keys(product.characteristics).map((key) => (
                                <tr key={key} className="border-b border-border">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                                        {key}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.characteristics[key]}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="px-6 py-4 text-center text-gray-500">
                                    {t("productDetails.detailsTab.NoCharAvailable")}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
                {selectedTab === 1 && <CommentsSection itemType="product"/>}
                {selectedTab === 2 &&
                    (equivalentProduct.length === 0 ?
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="font-medium">No Similar product found</p>
                        </div>
                        :
                        <Carousel ref={ref}
                                  renderItem={(product) => (<ProductCard {...product}/>)}
                                  items={equivalentProduct}
                                  itemsWidth={300}
                        />
                    )
                }
            </div>
        </div>
    )
}