import {api} from "../instance";

export const getVehicules = () => {
    try {
        return api.get('/api/vehicles');
    } catch (error) {
        return error.response.data;
    }
}

export const getVehicleSpecById = (id) => {
    return api.get("/api/vehicles/" + id)
}

export const getCategory = () => {
    try {
        return api.get('/api/categories');
    } catch (error) {
        return error.response.data;
    }
}
export const getCategoryhasSub = () => {
    try {
        return api.get('/api/categories/hasSub');
    } catch (error) {
        return error.response.data;
    }
}

export const getSubCategory = (category) => {
    try {
        return api.get('/api/sub_categories/category/' + category);
    } catch (error) {
        return error.response.data;
    }
}

export const getAllBrands = () => {
    try {
        return api.get('/api/brands');
    } catch (error) {
        return error.response.data;
    }
}

export const getProductBrands = () => {
    return api.get("/api/brands/products")
}

export const createProduct = (product) => {
    try {
        return api.post('/api/products', product);
    } catch (error) {
        return error.response.data;
    }
}

export const updateProduct = (id, product) => {
    return api.post('/api/products/' + id, product);
}

export const getSellerProducts = () => {
    try {
        return api.get(`/api/my-products`);
    } catch (error) {
        return error.response.data;
    }
}

export const createPack = (pack) => {
    try {
        return api.post('/api/offers', pack);
    } catch (error) {
        return error.response.data;
    }
}

export const deletePack = (id) => {
    return api.delete("/api/offers/" + id)
}

export const generatePromoCode = (promoCode) => {
    try {
        return api.post('/api/promotions', promoCode);
    } catch (error) {
        return error.response.data;
    }
}

export const assignPromoCode = (id, promoCode) => {
    console.log("promoCode", promoCode)
    return api.put('/api/promotions/' + id, promoCode);
}

export const updateProductPhotos = (id, data) => {
    return api.post("/api/products/" + id, data)
}

export const updatePromoCode = (id, promoCode) => {
    return api.put('/api/promotions/' + id, promoCode);
}

export const deletePromoCode = (id) => {
    return api.delete("/api/promotions/" + id, {data: {promotion_ids: [id]}})
}

export const deleteMultiplePromoCodes = (ids) => {
    return api.delete("/api/promotions/delete-multiple", {data: {promotion_ids: ids}});
};

export const getPromoCodes = () => {
    return api.get("/api/My-shop/promotions")
}

export const getPacks = () => {
    try {
        return api.get('/api/offers');
    } catch (error) {
        return error.response.data;
    }
}

export const getOrders = () => {
    try {
        return api.get('/api/seller/Myshop-orders');
    } catch (error) {
        return error.response.data;
    }
}

export const deleteProduct = (id) => {
    return api.delete('/api/products/' + id);
}

export const hideProduct = (id, hide = true) => {
    try {
        return api.patch('/api/products-toggle-hidden/', {action: hide ? "hide" : "unhide", product_ids: [id]});
    } catch (error) {
        return error.response.data;
    }
}

export const getDashboardStats = () => {
    return api.get('/api/seller/dashboard');
}
export const getServiceStats = () => {
    return api.get('/api/services-stat');
}
export const getSubscriptionHistory = () => {
    return api.get("/api/My-shop/subscription-history")
}

export const setShopSubscription = (id, data) => {
    return api.post(`/api/shop-subscriptions/update/${id}`, data, {headers: {"Content-Type": "multipart/form-data"}})
}

export const cancelShopSubscription = (data) => {
    return api.post(`/api/subscription_cancellation`, data)
}

export const restockProduct = (id, stock) => {
    return api.patch("/api/products/restock/" + id, {quantity: stock})
}

export const updateProductPrice = (id, price) => {
    return api.put("/api/products/" + id, {price: price})
}

export const getOfferOrders = () => {
    return api.get("/api/offers")
}

export const getServices = () => {
    try {
        return api.get('/api/services');
    } catch (error) {
        return error.response.data;
    }
}
export const addService = (service) => {
    try {
        return api.post('/api/services', service);
    } catch (error) {
        return error.response.data;
    }
}
export const getMyServices = () => {
    try {
        return api.get('/api/my-services');
    } catch (error) {
        return error.response.data;
    }
}
export const deleteService = (id) => {
    try {
        return api.delete('/api/services/' + id);
    } catch (error) {
        return error.response.data;
    }
}
export const hideService = (id) => {
    try {
        return api.get(`/api/services/${id}/hide-service`);
    } catch (error) {
        return error.response.data;
    }
}
export const daysOff = (id, data) => {
    return api.post(`/api/services/${id}/day-offs`, data);
}
export const showService = (id) => {
    try {
        return api.get(`/api/services/${id}`);
    } catch (error) {
        return error.response.data;
    }
}
export const UpdateService = (id, data) => {
    return api.put(`/api/services/${id}`, data);
}
export const UpdateSchedule = (id, data) => {
    return api.patch(`/api/services/${id}/update-schedule`, data);
}

export const getConditions = async () => {
    try {
        return api.get('/api/conditions');
    } catch (error) {
        return error.response.data;
    }
}

export const getMyShop = () => {
    return api.get('/api/My-shop');
}

export const createShopSubscription = (data) => {
    return api.post("/api/shop-subscriptions", data, {headers: {"Content-Type": "multipart/form-data"}})
}

export const createShop = (data) => {
    try {
        return api.post('/api/shops', data);
    } catch (error) {
        return error.response.data;
    }
}

export const updateShop = (id, data) => {
    return api.post(`/api/shops/${id}`, data, {params: {_method: "PUT"}});
}

export const updateShopPhotos = (id, data) => {
    return api.post(`/api/shops/${id}/photos`, data)
}

export const getSellerReservations = async () => {
    try {
        return api.get('/api/all-reservations');
    } catch (error) {
        return error.response.data;
    }
};

export const getMyOffers = () => {
    return api.get("/api/My-offers");
}
export const uploadProductFile = (data) => {
    return api.post("/api/import-products", data)
}