import {useMarketplaceContext} from "../../pages/Marketplace";
import {useEffect, useState} from "react";
import {formatCurrency, getPhoto} from "../../utils/utils";
import AddToCart from "../AddToCart/AddToCart";
import {create} from "zustand";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";

export const PackDetailsFlyOutMenu = () => {
    const {
        updatePackDetailsFlyOutMenuVisible,
        selectedOffer,
        packDetailsFlyOutMenuVisible
    } = usePackDetailsFlyOutMenuContext();
    const {
        updatePopupVisible
    } = useMarketplaceContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const [offer, updateOffer] = useState(selectedOffer);
    const [loading, updateLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        let total = 0;
        offer?.products.forEach((product) => {
            total += product.price * product.pivot.quantity;
        });
        setTotalPrice(total);
    }, [offer?.products]);

    useEffect(() => {
        updateOffer(selectedOffer)
    }, [selectedOffer])

    if (packDetailsFlyOutMenuVisible && loading) {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <div className="w-5">
                    <div className="loader"></div>
                </div>
            </div>
        )
    }

    return (
        <>
            {/* Background overlay */}
            <div
                onClick={() => {
                    updatePopupVisible(false)
                    updatePopupVisible(false)
                    updatePackDetailsFlyOutMenuVisible(false)
                }}
                style={{visibility: packDetailsFlyOutMenuVisible ? "visible" : "hidden"}}
                className="h-full w-screen bg-black bg-opacity-50 absolute top-0 left-0 z-[99] transition-all duration-300 ease-in-out"
            ></div>

            {/* Flyout container */}
            <div
                style={{
                    right: packDetailsFlyOutMenuVisible ? 0 : '-100%',
                }}
                className="fixed top-0 h-screen bg-white z-[100] w-full sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] transition-all duration-500 ease-in-out flex flex-col"
            >
                {/* Header */}
                <div className="w-full h-14 flex items-center justify-between px-6 border-b border-gray-200">
                    <div className="flex items-center gap-2">
                        <p className="font-semibold">{offer?.offer_name}</p>
                        <p className="px-4 py-2 bg-primaryExtraLight text-primaryLight font-medium rounded border border-primaryLight">{offer?.discount_percentage} %</p>
                    </div>
                    <button
                        onClick={() => {
                            updatePackDetailsFlyOutMenuVisible(false)
                            updatePopupVisible(false)
                            updatePopUpVisible(false)
                        }}
                        className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200"
                    >
                        <img src="/resources/close.svg" alt="close" className="w-3"/>
                    </button>
                </div>
                <div className="h-full w-full flex flex-col gap-2 overflow-y-auto max-h-[calc(100vh-118px)] p-4">
                    {offer?.products?.map((product, index) => (
                        <PackElementItem product={product} key={index}/>
                    ))}
                </div>
                <div className="flex flex-row items-center justify-end h-16 gap-2 px-4 py-2 border-t border-t-border">
                    <div className="flex flex-row items-center gap-2 px-4 py-2">
                        <span className="font-medium text-gray-500 line-through">{formatCurrency(totalPrice)}</span>
                        <span
                            className="text-2xl font-bold">{formatCurrency(totalPrice - (offer?.discount_percentage / 100) * totalPrice)}</span>
                    </div>
                    <AddToCart ids={offer?.products.map(product => product.id)} offer={{
                        id: offer?.id,
                        offer_name: offer?.offer_name,
                        discount_percentage: offer?.discount_percentage,
                        products: offer?.products
                    }}/>
                </div>

            </div>

        </>
    );
}

const PackElementItem = ({product}) => {
    return (
        <div className="flex flex-row items-center gap-2">
            <div className="flex flex-row items-end justify-center gap-1 min-w-[40px] max-w-[40px]">
                <p className="text-xl sm:text-2xl font-medium text-primaryLight leading-none">{product.pivot.quantity}</p>
                <p className="font-semibold leading-none">X</p>
            </div>
            <div
                className="h-16 sm:h-24 flex flex-row items-center justify-between p-2 gap-2 sm:gap-6 border border-border rounded-lg w-full">
                <div className="flex flex-row gap-4 items-center">
                    {

                        <ImageWithFallback src={getPhoto({link:product.first_photo.link,type:"img"})} alt="product"
                                           className="h-12 w-12 sm:w-16 sm:h-16 object-contain rounded"/>
                    }
                    <div className="flex flex-col items-start">
                        <p className="font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[100px] sm:max-w-[162px]">{product.name}</p>
                        <div className="flex flex-row items-center gap-2">
                            <p className="font-medium truncate">Stock :</p>
                            <input className="h-9 w-16 border-border border rounded-lg px-2" type="number"
                                   value={product.stock_quantity}/>
                        </div>
                    </div>
                </div>
                <p className="text-[#FFD3CA] text-xl sm:text-2xl font-semibold">{formatCurrency(product.price)}</p>
            </div>
        </div>
    )
}

export const usePackDetailsFlyOutMenuContext = create((set) => ({
    selectedOffer: null,
    updateSelectedOffer: (offer) => set({selectedOffer: offer}),
    packDetailsFlyOutMenuVisible: false,
    updatePackDetailsFlyOutMenuVisible: (visible) => set({packDetailsFlyOutMenuVisible: visible}),
}));