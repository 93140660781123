import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-[#303841] text-white w-full py-10 px-[6vw] sm:px-[11vw]">
            <div className="lg:flex lg:justify-between">
                {/* Left Section */}
                <div className="mb-8 lg:mb-0 lg:w-1/3">
                    <img src="/resources/white_Logo.svg" alt="white-logo" className="w-[130px]"/>
                    <p className="my-4 text-sm">
                        {t('footer.description')}
                    </p>
                    <a href="mailto:contact@mecanext.com" className="text-white underline">contact@mecanext.com</a>
                </div>

                {/* Right Section */}
                <div className="lg:flex lg:gap-12">
                    {/* Informations */}
                    <div className="mb-6 lg:mb-0">
                        <h3 className="text-lg font-semibold mb-4">{t('footer.information.title')}</h3>
                        <ul className="space-y-2 text-sm">
                            <li><a href="#contact" className="hover:underline">{t('footer.information.contact')}</a></li>
                            <li><a href="#signup" className="hover:underline">{t('footer.information.sellerSignup')}</a></li>
                            <li><a href="#privacy" className="hover:underline">{t('footer.information.privacy')}</a></li>
                        </ul>
                    </div>

                    {/* Service client */}
                    <div className="mb-6 lg:mb-0">
                        <h3 className="text-lg font-semibold mb-4">{t('footer.customerService.title')}</h3>
                        <ul className="space-y-2 text-sm">
                            <li><a href="#support" className="hover:underline">{t('footer.customerService.support')}</a></li>
                            <li><a href="#contact" className="hover:underline">{t('footer.customerService.contact')}</a></li>
                            <li><a href="#claim" className="hover:underline">{t('footer.customerService.sellerClaim')}</a></li>
                            <li><a href="#privacy" className="hover:underline">{t('footer.customerService.privacy')}</a></li>
                        </ul>
                    </div>

                    {/* My Account */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4">{t('footer.myAccount.title')}</h3>
                        <ul className="space-y-2 text-sm">
                            <li><a href="#login" className="hover:underline">{t('footer.myAccount.login')}</a></li>
                            <li><a href="#order" className="hover:underline">{t('footer.myAccount.trackOrder')}</a></li>
                            <li><a href="#help" className="hover:underline">{t('footer.myAccount.help')}</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="mt-8 border-t border-gray-800 pt-4 text-center">
                <p className="text-sm">{t('footer.copyright.title')}</p>
            </div>
        </footer>
    );
};

export default Footer;
