import {create} from "zustand";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useState} from "react";
import {useMarketplaceContext} from "../../pages/Marketplace";
import {useTranslation} from "react-i18next";

export default function DeleteConfirmation() {
    const {deletePopUpVisible, itemInfo, updateDeletePopUpVisible, deleteFunc} = useDeleteConfirmationContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {updatePopupVisible} = useMarketplaceContext();
    const handleClose = () => {
        updateDeletePopUpVisible(false)
        updatePopUpVisible(false)
        updatePopupVisible(false)
    }

    const [loading, setLoading] = useState(false);

    const handleDelete = async ()=>{
        setLoading(true);
        await deleteFunc();
        setLoading(false)
    }
    const {t} = useTranslation();
    return (
        <>
            <div style={{display: deletePopUpVisible ? "block" : "none"}}
                 className="fixed bg-white w-full lg:w-1/3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 p-8 rounded-lg border border-border max-w-[500px]">
                <img src="/images/undraw_delete.svg" alt="icône de suppression" className="w-32 h-32 mx-auto mb-3"/>
                <h1 className="text-xl font-semibold text-center mb-3">{itemInfo.title}</h1>
                <h1 className="font-medium text-gray-500 text-center mx-3">{t('DeleteConfirmation.Usure')} <p className="truncate">{itemInfo.name} ?</p>  {t('DeleteConfirmation.NonReversable')}</h1>
                <div className="flex flex-row items-center justify-between mt-4 w-full">
                    <button
                        className="flex items-center justify-center min-h-10 bg-white border border-border text-gray-500 px-4 rounded-lg"
                        onClick={handleClose}>{t('DeleteConfirmation.Cancel')}
                    </button>
                    <button
                        onClick={handleDelete}
                        className="flex items-center justify-center min-h-10 bg-red-500 text-white px-4 py-2 rounded-lg">
                        {
                            loading ?
                                <div className="w-full h-full flex items-center justify-center">
                                    <div className="w-5">
                                        <div className="loader_white"></div>
                                    </div>
                                </div>
                                :
                                `${t('DeleteConfirmation.yesDelete')} ${itemInfo.type}`
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export const useDeleteConfirmationContext = create((set) => ({
    deletePopUpVisible: false,
    updateDeletePopUpVisible: (visible) => {
        set({deletePopUpVisible: visible})
    },
    deleteFunc: () => {
    },
    updateDeleteFunc: (func) => {
        set({deleteFunc: func})
    },
    itemInfo: {name: "", type: "",title:""},
    updateItemInfo: (name, type,title) => set({itemInfo: {name, type,title}})
}))