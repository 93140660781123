import React, {useState, useMemo, useEffect} from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    filterFns,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import TablePaginationNavigation from "../TablePaginationNavigation/TablePaginationNavigation";
import {getSubscriptionHistory} from "../../services/SellerSpace/API";
import {useNavigate} from "react-router";

const ROW_HEIGHT = 81;

const convertVhToPx = (vhValue) => {
    if (vhValue?.endsWith("vh")) {
        const vh = parseFloat(vhValue);
        return (vh / 100) * window.innerHeight;
    }
    return vhValue;
}

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        maxHeight = (vhValue / 100) * window.innerHeight;
    }

    return Math.floor((maxHeight - ROW_HEIGHT) / ROW_HEIGHT);
};

const ShopSubscriptionHistory = ({maxHeight}) => {
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sort, setSort] = useState([]);
    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));
    const [error, setError] = useState(null);

    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    const navigate = useNavigate();
    const fetchData = () => {
        setError(null);
        setLoading(true)
        getSubscriptionHistory().then(response => {
            if (response.status === 200) {
                console.log(response.data);
                setFetchedData(response.data.shop.subscriptions);
            } else {
                setError(response.data);
            }
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login')
            }
            setError(error);
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchData()
    }, []);

    const data = useMemo(
        () =>
            fetchedData.map(invoice => ({
                id: "Facture #" + invoice.id,
                status: invoice.status,
                start_date: new Date(invoice.start_date || invoice.updated_at).toLocaleDateString('fr-FR'),
                end_date: new Date(invoice.end_date || invoice.updated_at).toLocaleDateString('fr-FR'),
                receipt: invoice?.receipt ? process.env.REACT_APP_API_URL === "/" ? window.location.origin + invoice.receipt : `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/${invoice.receipt?.replace(/^\/+/, "")}` : null,

            })),
        [fetchedData]
    );


    const isSmallScreen = window.innerWidth <= 640; // Or use a media query hook for React

    const columns = useMemo(
        () => {
            const allColumns = [
                {
                    accessorKey: 'id',
                    header: 'Facture ID',
                    cell: ({getValue}) => <span className="font-medium">{getValue()}</span>,
                },
                {
                    accessorKey: "status",
                    header: "États",
                    cell: ({getValue}) => <VerificationStatus status={getValue()}/>,
                },
                {
                    accessorKey: 'start_date',
                    header: 'Date de début',
                    cell: ({getValue}) => <span>{getValue()}</span>,
                },
                {
                    accessorKey: 'end_date',
                    header: 'Date de fin',
                    cell: ({getValue}) => <span>{getValue()}</span>,
                },
                {
                    accessorKey: 'receipt',
                    header: 'Reçu',
                    cell: ({row,getValue}) => {
                        return (
                            row.original.receipt ? (
                                <a href={getValue()} target="_blank" rel="noopener noreferrer"
                                   className="text-blue-500">
                                    Voir le reçu
                                </a>
                            ) : (
                                <span className="text-gray-500">Aucun reçu</span>
                            )
                        )
                    },
                },
            ];
            const essentialColumns = ["id", "status", "receipt"];
            return isSmallScreen
                ? allColumns.filter(col => essentialColumns.includes(col.accessorKey))
                : allColumns;

        },
        [isSmallScreen]
    );


    const handleFilterChange = useMemo(
        () =>
            debounce((value) => {
                setGlobalFilter(value);
            }, 300),
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter: globalFilter,
            sorting: sort
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSort,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: filterFns.includesString,
        initialState: {
            columnVisibility: {
                "productSku": false
            },
            pagination:
                {pageSize: pageSize}
        }
    });


    const [height, setHeight] = useState(0);
    useEffect(() => {
        setHeight(convertVhToPx(maxHeight));
    }, []);

    if (loading) {
        return <div className="w-full min-h-[calc(100vh-380px)] flex items-center justify-center">
            <div className="loader"></div>
        </div>
    }

    return (
        <div className="relative flex flex-col items-center w-full">

            {
                error !== null ?
                    <div style={{minHeight: (height)}}
                         className="w-full h-full flex flex-col gap-2 items-center justify-center">
                        <div className="p-4 text-center text-gray-500">
                            Une erreur inattendue s'est produite, veuillez réessayer .
                        </div>
                        <button
                            onClick={fetchData}
                            className="bg-primaryLight min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 flex flex-row gap-2 items-center"
                        >
                            <img src="/resources/retry.svg" alt="retry icon" className="white_filter"/>
                            <p className="font-medium text-white leading-none">Réessayer</p>
                        </button>

                    </div>
                    :
                    data.length === 0 ? (
                            <div style={{minHeight: (height)}}
                                 className="w-full h-full flex flex-col items-center justify-center">
                                <img src="/images/undraw_no_data.svg" alt="empty" className="w-32 mb-8"/>
                                <p className="p-4 text-center text-gray-500">
                                    Aucun produit trouvé.
                                </p>
                            </div>
                        )

                        :

                        (
                            <div style={{minHeight: (height)}}
                                 className="relative flex flex-col items-center justify-between w-full">
                                <div className="flex flex-col w-full h-full">
                                    <div
                                        className="flex flex-row items-center justify-between gap-4 border-b-border border-b min-h-16 w-full ">
                                        <h1 className="text-xl font-medium">Factures précédentes</h1>
                                        <div
                                            className="w-[30vw] sm:w-[15vw] h-12 bg-[#FCFCFC] border-[#D4D4D4] border rounded-lg px-2 sm:px-4 flex items-center justify-between">
                                            <input
                                                value={globalFilter}
                                                onChange={e => setGlobalFilter(e.target.value)}
                                                placeholder="Recherche"
                                                className="w-[calc(30vw-40px)] sm:w-[calc(15vw-56px)] h-9 bg-[#FCFCFC] border-none focus:outline-none"
                                            />
                                            <img src="/resources/search.svg" alt="search"/>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <table
                                            className="min-w-full bg-white border border-gray-200 rounded-lg table-fixed">
                                            <thead
                                                className="bg-gray-100 sticky top-0 z-10 min-h-14 h-14 border-b border-border max-h-14">
                                            {table.getHeaderGroups().map(headerGroup => (
                                                <tr key={headerGroup.id} className="text-left">
                                                    {headerGroup.headers.map(header => (
                                                        <th
                                                            key={header.id}
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            className="px-6 py-3 text-[#979797] text-sm font-medium border-b border-gray-200"
                                                        >
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                            {{
                                                                asc: " ↑",
                                                                desc: " ↓"
                                                            }[header.column.getIsSorted() ?? null]}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                            </thead>
                                            <tbody>
                                            {table.getRowModel().rows.map(row => (
                                                <tr key={row.id} className="hover:bg-gray-50">
                                                    {row.getVisibleCells().map(cell => (
                                                        <td key={cell.id}
                                                            className="cursor-pointer px-6 py-4 border-b border-gray-200 font-medium max-h-14">
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <TablePaginationNavigation table={table}/>
                            </div>
                        )}
        </div>
    );
};

export default ShopSubscriptionHistory;

const VerificationStatus = ({status}) => {
    return (
        <>
            {(status === "canceled" || status === "rejected") ?
                (
                    <div
                        className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-red-100 border border-red-500'>
                        <p className="text-sm font-medium text-red-500">{status}</p>
                    </div>
                )
                :
                (
                    <div
                        className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-green-100 border border-green-500'>
                        <p className="text-sm font-medium text-green-500">{status}</p>
                    </div>
                )
            }
        </>
    )
        ;
}