import CheckBox from "../../../../components/Checkbox";
import React, {useEffect, useRef, useState} from "react";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import {create} from "zustand";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {
    createProduct,
    getCategoryhasSub, getSubCategory,
    getVehicules, getProductBrands, updateProduct
} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Cookies from "js-cookie";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {getProductById} from "../../../../services/marketplace/API";

export default function AddProduct() {
    const [loading, setLoading] = useState(false);

    const {
        updateProductInfo,
        vehicle_brand_ids,
        vehicle_model_ids,
        vehicle_motorisation_ids,
        setVehicle_motorisation_id,
        setVehicle_brand_id,
        setVehicle_model_id,
        productInfo,
        addNameValue,
        clearProductInfo
    } = AddProductPageContext();

    const navigate = useNavigate();

    useEffect(() => {
        clearProductInfo();
    }, []);

    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                if (shopData) {
                    const shopInfo = JSON.parse(shopData);
                    if (shopInfo?.status !== "subscribed") {
                        navigate(-1);
                    }
                }
            }
        } catch (e) {

        }
    }, [navigate]);

    const handleSave = async () => {
        // Input validation
        if (productInfo.stock_quantity <= 0) {
            toast.error(<ToastError message="La quantité doit être supérieure à 0"/>, toastStyle);
            return;
        }
        if (productInfo.price <= 0) {
            toast.error(<ToastError message="Le prix doit être supérieur à 0"/>, toastStyle);
            return;
        }
        if (!productInfo.name) {
            toast.error(<ToastError message="Le nom du produit est requis"/>, toastStyle);
            return;
        }
        if (!productInfo.description) {
            toast.error(<ToastError message="La description du produit est requise"/>, toastStyle);
            return;
        }
        if (!productInfo.category_id) {
            toast.error(<ToastError message="La catégorie du produit est requise"/>, toastStyle);
            return;
        }
        if (!productInfo.sub_category_id) {
            toast.error(<ToastError message="La sous-catégorie du produit est requise"/>, toastStyle);
            return;
        }
        if (!productInfo.brand_id) {
            toast.error(<ToastError message="La marque du produit est requise"/>, toastStyle);
            return;
        }
        if (!productInfo.mainPhoto) {
            toast.error(<ToastError message="Une image principale est requise"/>, toastStyle);
            return;
        }
        if (!productInfo.photos || productInfo.photos.length === 0) {
            toast.error(<ToastError message="Au moins une image est requise"/>, toastStyle);
            return;
        }

        setLoading(true);

        let fetchedData = []
        if (productInfo.type) {
            const response = await getVehicules();
            if (productInfo.type === "car") {
                fetchedData = response.data.cars
            } else if (productInfo.type === "motorcycle") {
                fetchedData = response.data.motorcycles
            } else if (productInfo.type === "truck") {
                fetchedData = response.data.trucks
            }
        } else {
            toast.error(<ToastError message="Veuillez sélectionner le type de véhicule"/>, toastStyle);
            return
        }
        // Filter for compatible vehicles
        const compatibleVehicles = fetchedData.filter(vehicle =>
            vehicle_brand_ids.includes(vehicle.motorisation.vehicle_model.vehicle_brand.id) &&
            vehicle_model_ids.includes(vehicle.motorisation.vehicle_model.id) &&
            vehicle_motorisation_ids.includes(vehicle.motorisation.id)
        );

        const vehicleIds = compatibleVehicles.map(vehicle => vehicle.id);

        const newProductData = {...productInfo, vehicle_ids: vehicleIds};
        updateProductInfo(newProductData);

        // Prepare form data for submission
        let data = new FormData();
        data.append("name", newProductData.name);
        data.append("description", newProductData.description);
        data.append("price", newProductData.price);
        data.append("stock_quantity", newProductData.stock_quantity);
        data.append("category_id", newProductData.category_id);
        data.append("sub_category_id", newProductData.sub_category_id);
        data.append("brand_id", newProductData.brand_id);
        data.append("condition", newProductData.condition);
        data.append("payment_type", newProductData.payment_type);
        newProductData.vehicle_ids.forEach(vehicleId =>
            data.append("vehicle_ids[]", vehicleId)
        );
        data.append("type", newProductData.type)

        if (newProductData.mainPhoto?.image_file) {
            data.append("photos[0][image_file]", newProductData.mainPhoto.image_file);
        } else if (newProductData.mainPhoto?.photo_link) {
            data.append("photos[0][photo_link]", newProductData.mainPhoto.photo_link);
        }
        data.append("photos[0][type]", 'img');

        productInfo.photos.forEach((photo, index) => {
            if (photo.image_file) {
                data.append(`photos[${index + 1}][image_file]`, photo.image_file);
            }
            if (photo.photo_link) {
                data.append(`photos[${index + 1}][photo_link]`, photo.photo_link);
            }
            data.append(`photos[${index + 1}][type]`, photo.type || 'img');
        });

        // Append characteristics
        Object.keys(productInfo.characteristics).forEach((key) => {
            data.append(`characteristics[${key}]`, productInfo.characteristics[key]);
        });

        if (id) {
            try {
                // Update product
                data.append("_method", "PUT");
                const updateResponse = await updateProduct(id, data);
                if (updateResponse.status === 200) {
                    toast.success(<ToastSuccess message="Produit modifié avec succès"/>, toastStyle);
                } else {
                    toast.success(<ToastSuccess message="Produit modifié avec succès"/>, toastStyle);
                }
            } catch (error) {
                handleCreateProductError(error);
            } finally {
                setLoading(false);
            }

        } else {
            try {
                // Create product
                const createResponse = await createProduct(data);
                if (createResponse.status === 201) {
                    toast.success(<ToastSuccess message="Produit ajouté avec succès"/>, toastStyle);
                } else {
                    toast.success(<ToastSuccess message="Produit ajouté avec succès"/>, toastStyle);
                }
            } catch (error) {
                handleCreateProductError(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCreateProductError = (error) => {
        let errorMessage = "Une erreur inattendue s'est produite, veuillez réessayer.";
        if (error.response?.data?.errors) {
            const firstErrorKey = Object.keys(error.response.data.errors)[0];
            errorMessage = error.response.data.errors[firstErrorKey];
        } else if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
        }
        toast.error(<ToastError message={errorMessage}/>, toastStyle);
    };

    const location = useLocation();
    const {id} = location.state || {};

    useEffect(() => {
        if (id) {
            getProductById(id)
                .then((response) => {
                    if (response.status === 200) {
                        addNameValue("name", response.data.name)
                        addNameValue("description", response.data.description)
                        addNameValue("price", response.data.price)
                        addNameValue("payment_type", response.data.payment_type)
                        addNameValue("stock_quantity", response.data.stock_quantity)
                        addNameValue("category_id", response.data.sub_category.category_id)
                        addNameValue("sub_category_id", response.data.sub_category_id)
                        addNameValue("brand_id", response.data.brand_id)
                        addNameValue("photos", response.data?.photos?.slice(1)?.map(photo => ({
                            photo_link: photo.link,
                            type: photo.type
                        })))
                        addNameValue("mainPhoto", response.data?.photos?.[0] ? {
                            photo_link: response.data.photos[0].link,
                            type: response.data.photos[0].type
                        } : null);
                        addNameValue("characteristics", response.data.characteristics)
                        addNameValue("type", response.data.type)
                        addNameValue("Etat", response.data.characteristics.Etat)
                        addNameValue("Color", response.data.characteristics.Couleur)
                        addNameValue("Weight", response.data.characteristics["Poids_(Kg)"])
                        addNameValue("Origine", response.data.characteristics.Origine)
                        addNameValue("OEM", response.data.characteristics.OEM)

                        if (response.data.type) {
                            getVehicules().then((vehicleResponse) => {
                                let fetchedData = []
                                if (response.data.type === "car") {
                                    fetchedData = vehicleResponse.data.cars
                                } else if (response.data.type === "motorcycle") {
                                    fetchedData = vehicleResponse.data.motorcycles
                                } else if (response.data.type === "truck") {
                                    fetchedData = vehicleResponse.data.trucks
                                }
                                const motorisation_ids = response.data.vehicles.map(vehicle => vehicle.motorisation_id)
                                let vehicle_brand_id = []
                                let vehicle_model_id = []
                                fetchedData
                                    .filter((vehicle) => motorisation_ids.includes(vehicle.motorisation.id))
                                    .map(vehicle => {
                                        vehicle_model_id.push(vehicle.motorisation.vehicle_model.vehicle_brand.id)
                                        vehicle_brand_id.push(vehicle.motorisation.vehicle_model.id)
                                    })

                                const vehicle_ids = fetchedData.filter(vehicle =>
                                    vehicle_brand_id.includes(vehicle.motorisation.vehicle_model.vehicle_brand.id) &&
                                    vehicle_model_id.includes(vehicle.motorisation.vehicle_model.id) &&
                                    motorisation_ids.includes(vehicle.motorisation.id)
                                ).map(vehicle => vehicle.id);

                                addNameValue("vehicle_ids", vehicle_ids)
                                setVehicle_motorisation_id(motorisation_ids)
                                setVehicle_brand_id(vehicle_brand_id)
                                setVehicle_model_id(vehicle_model_id)
                            })
                        }

                    }
                })
                .catch(() => {
                })
                .finally(() => {
                });
        }
    }, [addNameValue, id]);

    return (
        <div className="w-full h-full flex flex-col bg-white">
            <div className="h-16 flex flex-row items-center justify-between px-4 border-b border-border">
                <div className="flex flex-row items-center gap-2">
                    <div className="p-4 hover:bg-gray-100 cursor-pointer rounded flex items-center justify-center">
                        <img src="/resources/arrow_right.svg" className="transform -rotate-180" alt="back icon"
                             onClick={() => navigate(-1)}/>
                    </div>
                    <p className='sm:text-2xl font-semibold'>Ajouter un Produit</p>
                </div>
                <div
                    onClick={handleSave}
                    className="bg-primaryLight flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer">
                    {loading ?
                        <div className="w-6">
                            <div className="loader_white"></div>
                        </div> :
                        <img src="/resources/save.svg" alt="save icon"/>
                    }
                    <p className="text-sm font-semibold text-white">Sauvegarder</p>
                </div>
            </div>
            <div
                className='w-full h-full flex flex-col sm:flex-row items-start max-h-[calc(91vh-112px)] min-h-[calc(91vh-112px)] overflow-y-auto'>
                <div
                    className="w-full sm:w-[30%] h-full flex flex-col border-r border-border gap-6 p-4 sm:overflow-y-auto sm:max-h-[calc(91vh-112px)] sm:min-h-[calc(91vh-112px)] items-start">
                    <AddProductPictures/>
                </div>
                <div
                    className="w-full sm:w-[70%] h-full flex flex-col py-4 px-2 sm:px-4 md:px-8 lg:px-16 gap-4 sm:overflow-y-auto sm:max-h-[calc(91vh-112px)] sm:min-h-[calc(91vh-112px)] items-start">
                    <GeneralInformation/>
                    <Characteristics/>
                </div>
            </div>
        </div>
    );
}

const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

const AddProductPictures = () => {
    const [mainImage, setMainImage] = useState(null);
    const [additionalMedia, setAdditionalMedia] = useState([]);
    const [tiktokMedia, setTiktokMedia] = useState([]);
    const [showUrlModal, setShowUrlModal] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [isMainImage, setIsMainImage] = useState(true);
    const [isVideo, setIsVideo] = useState(false);
    const {addPhoto, removePhoto, productInfo} = AddProductPageContext();

    const additionalMediaRef = useRef(null);

    const handleMainImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            addPhoto({image_file: file, type: "img"}, true);
        }
    };

    const handleAdditionalMediaUpload = async (e) => {
        const files = Array.from(e.target.files);
        const newMedia = files.map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
            type: "img"
        }));

        setAdditionalMedia(prev => [...prev, ...newMedia]);

        for (const {file} of newMedia) {
            try {
                await addPhoto({image_file: file, type: "img"});
            } catch (error) {
                console.error(`Error uploading image: ${file.name}`, error);
                toast.error(<ToastError message={`Erreur lors de l'upload de l'image ${file.name}.`}/>, toastStyle);
            }
        }
        e.target.value = null;
    };

    const handleUrlMediaSubmit = () => {
        if (mediaUrl) {
            if (isVideo) {
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
                const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

                if (!youtubeRegex.test(mediaUrl) && !tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message="Veuillez entrer une URL YouTube ou TikTok valide."/>, toastStyle);
                    return;
                }
                if (youtubeRegex.test(mediaUrl)) {
                    setAdditionalMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    });
                } else {
                    setTiktokMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        index_at_photos: productInfo.photos.length
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "tiktok"
                    });
                }
            } else if (isMainImage) {
                setMainImage(mediaUrl);
                addPhoto({photo_link: mediaUrl, type: "img"}, true);
            } else {
                setAdditionalMedia(prev => [...prev, {url: mediaUrl, name: mediaUrl, type: "img"}]);
                addPhoto({photo_link: mediaUrl, type: "img"});
            }
            setMediaUrl('');
            setShowUrlModal(false);
        }
    };

    const removeAdditionalMedia = (index) => {
        const updatedMedia = additionalMedia.filter((_, i) => i !== index);
        removePhoto(index);
        setAdditionalMedia(updatedMedia);
    };

    const renderMediaItem = (item, index) => {
        if (item.type === "video") {
            const isYouTube = item.url.includes("youtube.com") || item.url.includes("youtu.be");
            if (isYouTube) {
                return (
                    <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                        <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.url)}`}
                            className="w-full h-full"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <button
                            onClick={() => removeAdditionalMedia(index)}
                            className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                        >
                            <img src="/resources/close.svg" alt="close icon"/>
                        </button>
                    </div>
                )
            }
        } else {
            return (
                <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                    <img src={item.url} alt={`additional-${index}`} className="w-full h-full object-contain"/>
                    <button
                        onClick={() => removeAdditionalMedia(index)}
                        className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                    >
                        <img src="/resources/close.svg" alt="close icon"/>
                    </button>
                </div>
            );
        }
    };

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    const removeTikTokMedia = (indexToRemove, index_at_photos) => {
        setTiktokMedia(prevMedia => prevMedia.filter((_, index) => index !== indexToRemove));
        removePhoto(index_at_photos)
    };


    const isYouTubeVideo = (url) => url.includes('youtube.com') || url.includes('youtu.be');

    const location = useLocation();
    const {id} = location.state || {}

    useEffect(() => {
        if (id) {
            if (productInfo.photos && productInfo.photos.length > 0) {
                const additionalPhotos = productInfo.photos;
                setAdditionalMedia(additionalPhotos.map((photo, index) => {
                    if (photo.type === "img" && !isYouTubeVideo(photo.photo_link) && !tiktokRegex.test(photo.photo_link)) {
                        return (
                            {
                                url: photo?.photo_link,
                                name: photo?.photo_link,
                                type: "img",
                                index_at_photos: index
                            }
                        )
                    } else if (isYouTubeVideo(photo.photo_link)) {
                        return {
                            url: photo?.photo_link,
                            name: photo?.photo_link,
                            type: "video",
                            origin: "youtube",
                            index_at_photos: index
                        }
                    } else {
                        return null;
                    }
                }).filter(item => item !== null));
                setTiktokMedia(additionalPhotos.map((photo, index) => {
                    if (tiktokRegex.test(photo.photo_link)) {
                        return {
                            url: photo?.photo_link,
                            name: photo?.photo_link,
                            type: "video",
                            origin: "tiktok",
                            index_at_photos: index
                        }
                    } else {
                        return null
                    }
                }).filter(item => item !== null))
            }
        }
    }, [id, productInfo.photos]);

    useEffect(() => {
        if (id) {
            if (productInfo.mainPhoto) {
                setMainImage(productInfo.mainPhoto.photo_link);
            }
        }
    }, [id, productInfo.mainPhoto]);

    return (
        <div className="flex flex-col gap-4 w-full pb-4">
            <p className="leading-none pb-4 border-b border-border font-medium text-xl">Images et vidéos du produit</p>

            {/* Main Image Section */}
            <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium truncate max-w-fit">Image principale </p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">Ajouter
                                une image</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis une URL"
                            index={2}
                            handleClick={() => {
                                setIsMainImage(true);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => document.getElementById('mainImageInput').click()}
                        />
                    </GenericDropDown>
                    <input
                        id="mainImageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={handleMainImageUpload}
                    />
                </div>
                <img src={mainImage || "/images/placeholder.png"} alt="product"
                     className="w-full h-[200px] object-contain bg-gray-100"/>
            </div>

            {/* Additional Media Section */}
            <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium truncate max-w-fit">Images et vidéos supplémentaires</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">Ajouter
                                des médias</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis une URL"
                            index={2}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => additionalMediaRef.current.click()}
                        />
                        <GenericDropDownItem
                            value="Vidéo YouTube ou Tiktok"
                            index={4}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(true);
                                setShowUrlModal(true);
                            }}
                        />
                    </GenericDropDown>
                    <input
                        ref={additionalMediaRef}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        style={{display: 'none'}}
                        onChange={handleAdditionalMediaUpload}
                    />
                </div>

                {/* Display additional media or placeholders */}
                <div className="flex flex-row flex-wrap items-center gap-4 w-full">
                    {additionalMedia.map((item, index) => renderMediaItem(item, index))}
                    {Array.from({length: Math.max(0, 3 - additionalMedia.length)}).map((_, index) => (
                        <div key={`placeholder-${index}`} className="relative w-[31%] h-32 bg-gray-100">
                            <img src="/images/placeholder.png" alt="placeholder"
                                 className="w-full h-full object-contain"/>
                        </div>
                    ))}
                </div>
                {tiktokMedia.map((item, index) => (
                    <div key={index} className="flex flex-col border border-border rounded">
                        <div
                            className="flex flex-row items-center min-h-12 border-b-border border-b pl-4 pr-2 justify-between">
                            <p className="font-medium">tiktok video N° {index + 1}</p>
                            <button
                                onClick={() => removeTikTokMedia(index, item.index_at_photos)}
                                className="bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                            >
                                <img src="/resources/close.svg" alt="close icon"/>
                            </button>
                        </div>
                        <iframe
                            key={index}
                            src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.url)}`}
                            className="w-full"
                            width="100%"
                            height="100%"
                            allowFullScreen
                        />
                    </div>
                ))}
            < /div>

            {/* URL Modal */}
            {showUrlModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 border-border border rounded-lg shadow-lg w-[400px]">
                        <h2 className="text-lg font-semibold mb-4">
                            {isVideo ? "Ajouter une vidéo YouTube ou Tiktok" : "Ajouter une image depuis une URL"}
                        </h2>
                        <input
                            type="text"
                            className="w-full border p-2 rounded mb-4 h-10 placeholder:text-sm focus:outline-primaryLight"
                            placeholder={isVideo ? "Entrez l'URL de la vidéo YouTube ou Tiktok" : "Entrez l'URL de l'image"}
                            value={mediaUrl}
                            onChange={(e) => setMediaUrl(e.target.value)}
                        />
                        <div className="flex justify-end gap-4">
                            <button
                                className="border border-border text-black px-4 py-2 rounded font-medium hover:bg-gray-50"
                                onClick={() => setShowUrlModal(false)}
                            >
                                Annuler
                            </button>
                            <div
                                onClick={handleUrlMediaSubmit}
                                className="bg-primaryLight flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer"
                            >
                                <img src="/resources/save.svg" alt="save icon"/>
                                <p className="text-sm font-semibold text-white">Ajouter</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const GeneralInformation = () => {
    const [paiement, setPaiement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [motorisations, setMotorisations] = useState([]);

    const {
        addNameValue,
        productInfo,
        vehicle_brand_ids,
        vehicle_motorisation_ids,
        vehicle_model_ids
    } = AddProductPageContext();

    const [filteredModels, setFilteredModels] = useState([]); // Models filtered based on selected brands
    const [filteredMotorisations, setFilteredMotorisations] = useState([]); // Motorisations filtered based on selected models

    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedMotorisations, setSelectedMotorisations] = useState([]);

    const [productCategory, updateProductCategory] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const [productBrand, updateProductBrand] = useState([]);

    const location = useLocation();
    const {id} = location.state || {}

    useEffect(() => {
        if (id) {
            setPaiement(productInfo.payment_type !== "cash")
            const selectedBrands = brands.filter(brand => vehicle_brand_ids.includes(brand.value))
            setSelectedBrands(selectedBrands)
            const selectedModels = models.filter(model => vehicle_model_ids.includes(model.id)).map(model => ({
                value: model.id,
                label: model.name
            }))
            setSelectedModels(selectedModels)
            setSelectedMotorisations(motorisations.filter(motorisation => vehicle_motorisation_ids.includes(motorisation.id)).map(motorisation => ({
                value: motorisation.id,
                label: motorisation.name
            })))
            const filteredModels = models.filter(model => selectedBrands.map(brand => brand.value).includes(model.brandId));
            setFilteredModels(filteredModels.map(model => ({value: model.id, label: model.name})));
            const filteredMotorisation = motorisations.filter(motorisation => selectedModels.map(model => model.value).includes(motorisation.modelId))
            setFilteredMotorisations(filteredMotorisation.map(motorisation => ({
                value: motorisation.id,
                label: motorisation.name
            })))
        }
    }, [brands, id, models, motorisations, productInfo.payment_type, vehicle_brand_ids, vehicle_model_ids, vehicle_motorisation_ids]);

    useEffect(() => {
        getCategoryhasSub().then((response) => {
            if (response.status === 200) {
                const filteredCategories = response?.data?.filter(category => category.type === 'product');
                addNameValue("category_id", filteredCategories[0]?.id);
                updateProductCategory(filteredCategories.map(item => ({
                    value: item.id,
                    label: item.category_name
                })));
            }
        }).catch((error) => {
            toast.error(<ToastError message="Erreur lors de la récupération des catégories"/>, toastStyle);
        });

        getProductBrands()
            .then((response) => {
                if (response.status === 200) {
                    updateProductBrand(response.data.map(item => ({
                        value: item.id,
                        label: item.brand_name
                    })));
                }
            }).catch(() => {
            toast.error(<ToastError message="Erreur lors de la récupération des marques"/>, toastStyle);
        });
    }, []);

    const [loadingVehicles, setLoadingVehicles] = useState(false);

    useEffect(() => {
        if (productInfo.type) {
            setLoadingVehicles(true)
            getVehicules()
                .then((response) => {
                    let fetchedData = [];
                    setSelectedBrands([]);
                    setSelectedModels([]);
                    setSelectedMotorisations([]);
                    if (productInfo.type === "car") {
                        fetchedData = response.data.cars
                    } else if (productInfo.type === "motorcycle") {
                        fetchedData = response.data.motorcycles
                    } else if (productInfo.type === "truck") {
                        fetchedData = response.data.trucks
                    }
                    const brandData = fetchedData.map(item => ({
                        id: item.motorisation.vehicle_model.vehicle_brand.id,
                        name: item.motorisation.vehicle_model.vehicle_brand.brand_name
                    }));

                    const uniqueBrandData = brandData.reduce((acc, current) => {
                        // Check if the current brand ID is already in the accumulator
                        if (!acc.some(item => item.id === current.id)) {
                            acc.push(current); // Add it if it's not there
                        }
                        return acc;
                    }, []);

                    setBrands(uniqueBrandData.map(item => ({value: item.id, label: item.name})));

                    const modelData = fetchedData.map(item => ({
                        id: item.motorisation.vehicle_model.id,
                        name: item.motorisation.vehicle_model.name,
                        brandId: item.motorisation.vehicle_model.vehicle_brand.id
                    }));

                    const motorisationData = fetchedData.map(item => ({
                        id: item.motorisation.id,
                        name: item.motorisation.name,
                        modelId: item.motorisation.vehicle_model.id
                    }));

                    setModels(modelData); // Set raw model data for filtering
                    setMotorisations(motorisationData);
                })
                .catch(() => {
                    toast.error(<ToastError message="Erreur lors de la récupération des véhicules"/>, toastStyle);
                }).finally(() => {
                setLoadingVehicles(false);
            });
        }
    }, [productInfo.type]);

    useEffect(() => {
        addNameValue("brand_id", productBrand[0]?.value);
    }, [addNameValue, productBrand]);

    useEffect(() => {
        if (paiement) {
            addNameValue("payment_type", "cash/online");
        } else {
            addNameValue("payment_type", "cash");
        }
    }, [addNameValue, paiement]);

    const {setVehicle_brand_id, setVehicle_model_id, setVehicle_motorisation_id} = AddProductPageContext();

    const handleBrandChange = (selectedOptions) => {
        setSelectedBrands(selectedOptions);

        const selectedBrandIds = selectedOptions.map(option => option.value);

        const filteredModels = models.filter(model => selectedBrandIds.includes(model.brandId));
        setFilteredModels(filteredModels.map(model => ({value: model.id, label: model.name})));

        const validSelectedModels = selectedModels.filter(model =>
            filteredModels.some(filteredModel => filteredModel.value === model.value)
        );
        setSelectedModels(validSelectedModels);

        const selectedModelIds = validSelectedModels.map(option => option.value);
        const filteredMotorisations = motorisations.filter(motorisation => selectedModelIds.includes(motorisation.modelId));
        setFilteredMotorisations(filteredMotorisations.map(motorisation => ({
            value: motorisation.id,
            label: motorisation.name
        })));

        const validSelectedMotorisations = selectedMotorisations.filter(motorisation =>
            filteredMotorisations.some(filteredMotorisation => filteredMotorisation.value === motorisation.value)
        );
        setSelectedMotorisations(validSelectedMotorisations);

        setVehicle_brand_id(selectedBrandIds);
        setVehicle_model_id(selectedModelIds);
        setVehicle_motorisation_id(validSelectedMotorisations.map(option => option.value));
    };

    const handleModelChange = (selectedOptions) => {
        setSelectedModels(selectedOptions);

        const selectedModelIds = selectedOptions.map(option => option.value);

        const filteredMotorisations = motorisations.filter(motorisation => selectedModelIds.includes(motorisation.modelId));
        setFilteredMotorisations(filteredMotorisations.map(motorisation => ({
            value: motorisation.id,
            label: motorisation.name
        })));

        const validSelectedMotorisations = selectedMotorisations.filter(motorisation =>
            filteredMotorisations.some(filteredMotorisation => filteredMotorisation.value === motorisation.value)
        );
        setSelectedMotorisations(validSelectedMotorisations);

        setVehicle_model_id(selectedModelIds);
        setVehicle_motorisation_id(validSelectedMotorisations.map(option => option.value));
    };

    const handleMotorisationChange = (selectedOptions) => {
        setSelectedMotorisations(selectedOptions);
        setVehicle_motorisation_id(selectedOptions.map(option => option.value));
    };

    const handleCategoryChange = (selectedOption) => {
        addNameValue("category_id", selectedOption.value);

        // Fetch subcategories based on selected category
        getSubCategory(selectedOption.value).then((response) => {
            if (response.status === 200) {
                addNameValue("sub_category_id", response.data[0].id);
                setSubCategories(response.data.map(item => ({value: item.id, label: item.name})));
            }
        }).catch((error) => {
            console.error("Error fetching subcategories: ", error);
            toast.error(<ToastError
                message={error.response?.data?.message}/>, toastStyle);
        });

    };

    useEffect(() => {
        if (productInfo.category_id) {
            getSubCategory(productInfo.category_id).then((response) => {
                if (response.status === 200) {
                    addNameValue("sub_category_id", response.data[0].id);
                    setSubCategories(response.data.map(item => ({value: item.id, label: item.name})));
                }
            }).catch((error) => {
                toast.error(<ToastError
                    message={error.response?.data?.message}/>, toastStyle);
            });
        }
    }, [addNameValue, productInfo.category_id]);

    const handleSubCategoryChange = (selectedOption) => {
        addNameValue("sub_category_id", selectedOption.value);
    };

    return (
        <div className="flex flex-col gap-2 items-start w-full">
            <p className="leading-none pb-4 border-border border-dashed border-b w-full text-xl font-medium">
                Informations Générales
            </p>

            <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black">Nom du produit</p>
                    <input
                        value={productInfo.name}
                        onChange={(e) => addNameValue(e.target.name, e.target.value)}
                        name="name"
                        type="text"
                        disabled={id}
                        className="w-full h-10 border border-border rounded px-4 py-2 focus:outline-primaryLight"
                    />
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black">Marque</p>
                    <GenericSelect
                        inputs={productBrand}
                        defaults={productInfo.brand_id ? [
                                {
                                    value: productInfo.brand_id,
                                    label: productBrand.find(brand => brand.value === productInfo.brand_id)?.label
                                }]
                            : []}
                        isMulti={false}
                        handleChanges={(e) => addNameValue("brand_id", e.value)}
                        setWidth="100%"
                        isDisabled={id}
                    />
                </div>
            </div>
            <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black">Quantité</p>
                    <input value={productInfo.stock_quantity} name={"stock_quantity"} disabled={id}
                           onChange={(e) => addNameValue(e.target.name, e.target.value)} min={0} type="number"
                           className="w-full h-10 border border-border rounded px-4"/>
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black">Prix unitaire</p>
                    <input value={productInfo.price} name={"price"} disabled={id}
                           onChange={(e) => addNameValue(e.target.name, e.target.value)}
                           type="number" min={0} className="w-full h-10 border border-border rounded px-4"/>
                </div>
            </div>
            <div className="flex flex-col items-start justify-between w-full mb-10">
                <p className="text-black">Description</p>
                <ReactQuill
                    className={"w-full h-[150px] focus:outline-primaryLight"}
                    onChange={(value) => addNameValue("description", value)}
                    value={productInfo.description}
                    theme={"snow"}
                />
            </div>

            <div className="flex flex-col gap-2 w-full h-fit">
                <p className="text-black">Vehicules Compatible</p>
                <div className="flex flex-row sm:flex-nowrap flex-wrap items-end gap-4 sm:gap-2 w-full mt-2">
                    <div className="flex flex-col gap-1 items-start w-full max-w-full sm:max-w-[25%]">
                        <p>Type du véhicule</p>
                        <GenericSelect
                            isDisabled={productInfo.vehicle_ids.length > 0}
                            inputs={[{value: "car", label: "Voiture"}, {
                                label: "Moto",
                                value: "motorcycle"
                            }, {label: "Camion", value: "truck"}]}
                            defaults={productInfo.type ?
                                {
                                    value: productInfo.type,
                                    label: productInfo.type === "car" ? "Voiture"
                                        :
                                        productInfo.type === "motorcycle" ?
                                            "Moto"
                                            :
                                            "Camion"
                                } : []}
                            isMulti={false}
                            handleChanges={(e) => addNameValue("type", e.value)}
                            setWidth="100%"
                        />
                    </div>
                    {productInfo.type ? brands.length > 0 ? <>
                                <div className="flex flex-col gap-1 items-start w-full sm:max-w-[25%]">
                                    <p>Marque du véhicule</p>
                                    <GenericSelect
                                        isDisabled={productInfo.vehicle_ids.length > 0}
                                        inputs={brands}
                                        defaults={selectedBrands}
                                        isMulti={true}
                                        handleChanges={handleBrandChange}
                                        setWidth="100%"
                                    />
                                </div>
                                <div className="flex flex-col gap-1 items-start w-full sm:max-w-[25%]">
                                    <p>Modèle de véhicule</p>
                                    <GenericSelect
                                        isDisabled={productInfo.vehicle_ids.length > 0}
                                        inputs={filteredModels}
                                        defaults={selectedModels}
                                        isMulti={true}
                                        handleChanges={handleModelChange}
                                        setWidth="100%"
                                        disabled={selectedBrands.length === 0}
                                    />
                                </div>
                                <div className="flex flex-col gap-1 items-start w-full sm:max-w-[25%]">
                                    <p>Motorisation de véhicule</p>
                                    <GenericSelect
                                        isDisabled={productInfo.vehicle_ids.length > 0}
                                        inputs={filteredMotorisations}
                                        defaults={selectedMotorisations}
                                        isMulti={true}
                                        handleChanges={handleMotorisationChange}
                                        setWidth="100%"
                                        disabled={selectedModels.length === 0}
                                    />
                                </div>
                            </>
                            :
                            <div
                                className="flex flex-row rounded items-center justify-center text-center h-fit sm:h-10 bg-primaryExtraLight w-full sm:w-[75%] px-4 py-2">
                                {loadingVehicles ?
                                    <div className="w-5">
                                        <div className="loader_primary"></div>
                                    </div>
                                    :
                                    <p className="font-medium text-primaryLight">Veuillez contacter l'administrateur pour
                                        ajouter des véhicules du type sélectionné</p>
                                }
                            </div>
                        :
                        <div
                            className="flex flex-row rounded items-center justify-center text-center h-10 bg-primaryExtraLight w-full sm:w-[75%]">
                            {loadingVehicles ?
                                <div className="w-5">
                                    <div className="loader_primary"></div>
                                </div>
                                :
                                <p className="font-medium text-primaryLight">Veuillez sélectionner le type de
                                    véhicule</p>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-2 items-center w-full">
                <div className="flex flex-row items-center gap-2 w-full h-full">
                    <CheckBox checkedValueState={[paiement, setPaiement]} disabled={id}/>
                    <p>Paiement online</p>
                </div>
                <div className="flex flex-col gap-2 w-full h-fit">
                    <p className="text-black">Catégorie</p>
                    <GenericSelect
                        isDisabled={id}
                        inputs={productCategory}
                        defaults={productInfo.category_id ? productCategory.find(product => product.value === productInfo.category_id) : []}
                        isMulti={false}
                        handleChanges={handleCategoryChange}
                        setWidth="100%"
                    />
                </div>

                {subCategories.length > 0 && (
                    <div className="flex flex-col gap-2 w-full h-fit">
                        <p className="text-black">Sous-Catégorie</p>
                        <GenericSelect
                            isDisabled={id}
                            inputs={subCategories}
                            defaults={productInfo.sub_category_id ? subCategories.find(subcategory => subcategory.value === productInfo.sub_category_id) : []}
                            isMulti={false}
                            handleChanges={handleSubCategoryChange}
                            setWidth="100%"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const Characteristics = () => {
    const {productInfo} = AddProductPageContext();
    const [characteristicsData, setCharacteristicsData] = useState([]);

    useEffect(() => {
        setCharacteristicsData(() => [
            {
                characteristic: 'Etat',
                value: productInfo.Etat,
                inputType: 'select',
                editable: true,
                options: ['Neuf', 'Usagé']
            },
            {
                characteristic: 'Couleur',
                value: productInfo.Color,
                inputType: 'text',
                editable: true,
                placeholder: 'Entrer la couleur'
            },
            {
                characteristic: 'Poids (Kg)',
                value: productInfo.Weight,
                inputType: 'text',
                editable: true,
                placeholder: 'Entrer le poids'
            },
            {
                characteristic: 'Origine',
                value: productInfo.Origine,
                inputType: 'select',
                editable: true,
                options: ["Original (marque)", "Chinois"]
            },
            {
                characteristic: 'OEM',
                value: productInfo.OEM,
                inputType: 'text',
                editable: true,
                placeholder: 'Entrer le numéro OEM'
            },
        ])
    }, [productInfo.Color, productInfo.Etat, productInfo.OEM, productInfo.Origine, productInfo.Weight]);

    const [newCharacteristic, setNewCharacteristic] = useState('');
    const [newValue, setNewValue] = useState('');
    const [requiredValue, setRequiredValue] = useState(false);
    const [requiredCharacteristic, setRequiredCharacteristic] = useState(false);

    const {setCharacteristics} = AddProductPageContext();

    useEffect(() => {
        // Update volume value only if length, width, or height changes
        const updatedData = [...characteristicsData];
        setCharacteristics(
            updatedData.reduce((acc, item) => {
                const key = item.characteristic.replace(/ /g, '_').replace(/Ø/, 'Ø');
                acc[key] = item.value;
                return acc;
            }, {})
        );
    }, [characteristicsData, setCharacteristics]);

    const handleAddRow = () => {
        if (!newCharacteristic) {
            setRequiredCharacteristic(true);
            return;
        }
        if (!newValue) {
            setRequiredValue(true);
            return;
        }
        setCharacteristicsData((prev) => [
            ...prev,
            {characteristic: newCharacteristic, value: newValue, inputType: "text", editable: true}
        ]);
        setNewCharacteristic('');
        setNewValue('');
    };

    const OEMref = useRef(false);

    const handleChange = (index, newValue, name) => {
        const updatedData = [...characteristicsData];
        OEMref.current = false;
        if (name === 'OEM') {
            const isValid = /^[A-Za-z0-9]{0,15}$/.test(newValue);
            if (!isValid) {
                OEMref.current = true;
                return;
            }
            const isValid2 = /^[A-Za-z0-9]{6,15}$/.test(newValue);
            if (!isValid2) {
                OEMref.current = true;
            }
        }
        if (name === "Poids (Kg)") {
            const isValid = /^[0-9]{0,10}(\.[0-9]{0,2})?$/.test(newValue);
            if (!isValid) {
                return;
            }
        }

        updatedData[index].value = newValue;
        setCharacteristicsData(updatedData);
    };

    return (
        <div className="flex flex-col gap-2 items-start w-full relative">
            <p className="leading-none pb-4 border-border border-dashed border-b w-full text-xl font-medium">
                Caractéristiques Techniques
            </p>
            <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                <thead className="bg-gray-100 top-0 z-10 min-h-14 h-14 border-b border-border max-h-14">
                <tr>
                    <th className="px-6 py-3 text-gray-500 text-left text-sm font-medium border-b border-gray-300 border-r">
                        Caractéristiques
                    </th>
                    <th className="px-6 py-3 text-gray-500 text-left text-sm font-medium border-b border-gray-300">
                        Valeur
                    </th>
                </tr>
                </thead>
                <tbody>
                {characteristicsData.map((item, index) => (
                    <tr key={index} className="relative">
                        <td className="px-6 py-4 border-b border-gray-300 border-r">
                            {item.characteristic}
                        </td>
                        <td className={`min-h-12 border ${item.characteristic === "OEM" && OEMref.current ? `border-red-500 z-50` : `border-gray-300`}`}>
                            {item.inputType === 'select' ? (
                                <select
                                    value={item.value}
                                    name={item.characteristic}
                                    onChange={(e) => handleChange(index, e.target.value, e.target.name)} // Handle change
                                    className="w-full min-h-full border-none focus:outline-none px-4"
                                >
                                    {item.options.map((option, i) => (
                                        <option key={i} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type={item.inputType}
                                    value={item.value}
                                    name={item.characteristic}
                                    onChange={(e) => handleChange(index, e.target.value, e.target.name)}
                                    min={0}
                                    placeholder={item.placeholder}
                                    className={`w-full min-h-full h-12 border-none ${item.editable ? '' : 'bg-gray-100'} focus:outline-none px-4`}
                                    readOnly={!item.editable}
                                    // Validation only for OEM field
                                    {...(item.characteristic === 'OEM' && {
                                        minLength: 6,
                                        maxLength: 15,
                                        placeholder: 'Enter 6-15 digits only',
                                    })}
                                />
                            )}
                        </td>
                    </tr>
                ))}
                <tr>
                    <td
                        style={{borderColor: requiredCharacteristic ? 'red' : 'inherit'}}
                        className="min-h-12 border-b border-gray-300 border-r">
                        <input
                            style={{borderColor: requiredCharacteristic ? 'red' : 'inherit'}}
                            type="text"
                            placeholder="Nouvelle Caractéristique"
                            value={newCharacteristic}
                            onChange={(e) => {
                                setRequiredCharacteristic(false);
                                setNewCharacteristic(e.target.value);
                            }}
                            className="w-full h-12 min-h-full border-none focus:outline-none px-4"
                        />
                    </td>
                    <td
                        style={{borderColor: requiredValue ? 'red' : 'inherit'}}
                        className="min-h-12 border-b border-gray-300">
                        <input
                            style={{borderColor: requiredValue ? 'red' : 'inherit'}}
                            type="text"
                            value={newValue}
                            placeholder={"Nouvelle Valeur"}
                            className="w-full h-12 min-h-full border-none focus:outline-none px-4"
                            onChange={(e) => {
                                setRequiredValue(false);
                                setNewValue(e.target.value);
                            }} // Allow entering value for new characteristic
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <button
                onClick={handleAddRow}
                className="flex items-center justify-center h-12 w-12 rounded-full border-border border absolute -bottom-6 left-1/2 transform -translate-x-1/2 bg-white">
                <p className="text-lg font-medium leading-none">+</p>
            </button>
        </div>
    );
};

export const AddProductPageContext = create((set) => ({
    promoPopUpVisible: false,
    vehicle_brand_ids: [],
    vehicle_model_ids: [],
    vehicle_motorisation_ids: [],
    productInfo: {
        name: "",
        description: "",
        payment_type: "cash",
        condition: "",
        price: 0,
        stock_quantity: 0,
        tiktok_link: "",
        category_id: null,
        sub_category_id: null,
        brand_id: null,
        vehicle_ids: [],
        photos: [],
        mainPhoto: null,
        characteristics: {},
        type: null,
        Etat: null,
        Color: null,
        Weight: null,
        Origine: "Original (marque)",
        OEM: null,
    },
    clearProductInfo: () => set(() => ({
        productInfo: {
            name: "",
            description: "",
            payment_type: "cash",
            condition: "",
            price: 0,
            stock_quantity: 0,
            tiktok_link: "",
            category_id: null,
            sub_category_id: null,
            brand_id: null,
            vehicle_ids: [],
            photos: [],
            mainPhoto: null,
            characteristics: {},
            type: null,
            Etat: null,
            Color: null,
            Weight: null,
            Origine: "Original (marque)",
            OEM: null,
        }
    })),
    updateProductInfo: (info) => set(() => ({productInfo: info})),
    setVehicle_brand_id: (ids) => set(() => ({vehicle_brand_ids: ids})),
    setVehicle_model_id: (ids) => set(() => ({vehicle_model_ids: ids})),
    setVehicle_motorisation_id: (ids) => set(() => ({vehicle_motorisation_ids: ids})),
    addNameValue: (name, value) => set((state) => ({productInfo: {...state.productInfo, [name]: value}})),
    addPhoto: (photo, isMain = false) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            photos: isMain ? state.productInfo.photos : [...state.productInfo.photos, photo],
            mainPhoto: isMain ? photo : state.productInfo.mainPhoto,
        },
    })),
    setCharacteristics: (caracteristics) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            characteristics: caracteristics,
        },
    })),
    removePhoto: (index) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            photos: state.productInfo.photos.filter((_, i) => i !== index),
        },
    })),
    addCompatibleVehicles: (ids) => set((state) => ({productInfo: {...state.productInfo, vehicle_ids: ids}})),

    updatePromoPopUpVisible: (visible) => set(() => ({promoPopUpVisible: visible})),

}));
