import {create} from "zustand";

export const useCompareContext = create((set) => ({
    compareBannerVisible: localStorage.getItem("compareBannerVisible") === "true",
    compareFlyoutMenuVisible: false,
    showCompareFlyoutMenu: () => {
        set({compareFlyoutMenuVisible: true})
    },
    hideCompareFlyoutMenu: () => {
        set({compareFlyoutMenuVisible: false})
    },
    compareProducts: localStorage.getItem("compareProducts") ? JSON.parse(localStorage.getItem("compareProducts")) : [],
    addProductToCompare: (product) => {
        console.log(product)
        set(state => {
            if (state.compareProducts?.length === 4) return {compareProducts: state.compareProducts};
            console.log(state.compareProducts)
            console.log(state.compareProducts.find(p => Number(p.id) === Number(product.id)))
            if (state.compareProducts.find(p => Number(p.id) === Number(product.id))) return {compareProducts: state.compareProducts}
            console.log("adding product")
            console.log([...state.compareProducts, product])
            localStorage.setItem("compareProducts", JSON.stringify([...state.compareProducts, product]))
            return {compareProducts: [...state.compareProducts, product]}
        })

    },
    removeProductFromCompare: (product) => set(state => {
        localStorage.setItem("compareProducts", JSON.stringify(state.compareProducts.filter(p => Number(p.id) !== Number(product.id))))
        return {compareProducts: state.compareProducts.filter(p => Number(p.id) !== Number(product.id))}
    }),
    clearCompare: () => {
        localStorage.setItem("compareProducts", JSON.stringify([]))
        set({compareProducts: []})
    },
    showBanner: () => {
        localStorage.setItem("compareBannerVisible", "true")
        set({compareBannerVisible: true})
    },
    hideBanner: () => {
        localStorage.setItem("compareBannerVisible", "false")
        set({compareBannerVisible: false})
    },
}))