import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Button,
    CircularProgress,
    Modal,

} from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {
    getBrands,
    getVehicles,
    getModels,
    getMotorisations,
    createVehiculeBrand,
    createModel,
    createMotorisation,
    createVehicle,
    updateMotorisation,
    updateModel,
    updateBrand,
    updateVehicle,
    ImportVehicle,
    deleteBrand,
    deleteModel,
    deleteMotorisation,
    deleteVehicle
} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export default function CamionConfig() {
    const { t } = useTranslation();

    const menuItems = [
        { id: 'brand', label: t('adminPages.vehicleConfig.brands'), icon: '/resources/product.svg' },
        { id: 'model', label: t('adminPages.vehicleConfig.models'), icon: '/resources/model.svg' },
        { id: 'motorisation', label: t('adminPages.vehicleConfig.motorizations'), icon: '/resources/motor.svg' },
        { id: 'vehicle', label: t('adminPages.vehicleConfig.vehicles'), icon: '/resources/car.svg' },
    ];

    const [currentTab, setCurrentTab] = useState('brand');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [motorisations, setMotorisations] = useState([]);
    const [importLoading, setImportLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleDelete = async (item) => {
        try {
            switch (currentTab) {
                case 'brand':
                    await deleteBrand(item.id);
                    toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.brand.success.delete')}/>, toastStyle);
                    break;
                case 'model':
                    await deleteModel(item.id);
                    toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.model.success.delete')}/>, toastStyle);
                    break;
                case 'motorisation':
                    await deleteMotorisation(item.id);
                    toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.motorization.success.delete')}/>, toastStyle);
                    break;
                case 'vehicle':
                    await deleteVehicle(item.id);
                    toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.delete')}/>, toastStyle);
                    break;
            }
            fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.delete')}/>, toastStyle);
        }
    };

    const getDefaultColumns = (tab) => {
        switch(tab) {
            case 'brand':
                return [
                    {
                        field: 'brand',
                        headerName: t('adminPages.vehicleConfig.brand.title'),
                        width: 300,
                        renderCell: (params) => (
                            <Box display="flex" alignItems="center">
                                <Avatar alt={params.row.name} src={params.row.logo} style={{ marginRight: 10 }} />
                                {params.row.name}
                            </Box>
                        )
                    },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 200 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 150,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={2}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            case 'model':
                return [
                    { field: 'name', headerName: t('adminPages.vehicleConfig.model.name'), width: 200 },
                    { field: 'serie', headerName: t('adminPages.vehicleConfig.model.series'), width: 150 },
                    { field: 'brand', headerName: t('adminPages.vehicleConfig.model.brand'), width: 200 },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 200 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 150,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={2}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            case 'motorisation':
                return [
                    { field: 'name', headerName: t('adminPages.vehicleConfig.motorization.name'), width: 150 },
                    { field: 'fuel', headerName: t('adminPages.vehicleConfig.motorization.fuel'), width: 150 },
                    { field: 'model', headerName: t('adminPages.vehicleConfig.motorization.model'), width: 150 },
                    { field: 'brand', headerName: t('adminPages.vehicleConfig.brand.title'), width: 150 },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 150 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 150,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={2}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            case 'vehicle':
                return [
                    { field: 'motorisation', headerName: t('adminPages.vehicleConfig.vehicle.motorization'), width: 150 },
                    { field: 'fuel', headerName: t('adminPages.vehicleConfig.motorization.fuel'), width: 150 },
                    { field: 'model', headerName: t('adminPages.vehicleConfig.model.title'), width: 150 },
                    { field: 'brand', headerName: t('adminPages.vehicleConfig.brand.title'), width: 150 },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 150 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 100,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={2}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            default:
                return [];
        }
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setFormData(item);
        setIsEditing(true);
        setIsModalOpen(true);
    };

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.name.endsWith('.csv')) {
            handleImportCSV(file);
        } else {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.invalidFile')}/>, toastStyle);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        multiple: false
    });

    const handleImportCSV = async (file) => {
        try {
            setImportLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            await ImportVehicle(formData);
            toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.import')}/>, toastStyle);
            fetchData(currentTab);
            setIsUploadModalOpen(false);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.import')}/>, toastStyle);
        } finally {
            setImportLoading(false);
        }
    };

    const fetchData = async (tab) => {
        setLoading(true);
        setError(null);
        setColumns(getDefaultColumns(tab));
        try {
            let data;
            switch (tab) {
                case 'brand':
                    data = await getBrands();
                    data = data.filter(brand => brand.type === 'truck');
                    setRows(data.map(brand => ({
                        id: brand.id,
                        name: brand.brand_name,
                        logo: brand.logo,
                        created_at:new Date(brand.created_at).toLocaleDateString("fr-FR")
                    })));
                    setBrands(data);
                    break;
                case 'model':
                    data = await getModels();
                    // Filter models related to truck brands
                    data = data.filter(model => model.vehicle_brand?.type === 'truck');
                    setRows(data.map(model => ({
                        id: model.id,
                        name: model.name,
                        serie: model.serie,
                        brand: model.vehicle_brand?.brand_name,
                        created_at: new Date(model.created_at).toLocaleDateString("fr-FR")
                    })));
                    break;
                case 'motorisation':
                    data = await getMotorisations();
                    // Filter motorisations related to truck models
                    data = data.filter(motor => motor.vehicle_model?.vehicle_brand?.type === 'truck');
                    setRows(data.map(motor => ({
                        id: motor.id,
                        name: motor.name,
                        fuel: motor.fuel,
                        model: motor.vehicle_model?.name,
                        brand: motor.vehicle_model?.vehicle_brand?.brand_name,
                        created_at: new Date(motor.created_at).toLocaleDateString("fr-FR")
                    })));
                    setMotorisations(data);
                    break;
                case 'vehicle':
                    data = await getVehicles();
                    // Only use trucks data
                    setRows(data.trucks.map(vehicle => ({
                        id: vehicle.id,
                        motorisation: vehicle.motorisation?.name,
                        fuel: vehicle.motorisation?.fuel,
                        model: vehicle.motorisation?.vehicle_model?.name,
                        brand: vehicle.motorisation?.vehicle_model?.vehicle_brand?.brand_name,
                        created_at: new Date(vehicle.created_at).toLocaleDateString("fr-FR")
                    })));
                    // Filter motorisations for trucks only
                    const motorData = await getMotorisations();
                    setMotorisations(motorData.filter(motor => motor.vehicle_model?.vehicle_brand?.type === 'truck'));
                    break;
                default:
                    break;
            }
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.fetch')}/>, toastStyle);
            setError('Failed to fetch data');
            setRows([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchModels = async () => {
        const data = await getModels();
        // Filter models for trucks only
        setModels(data.filter(model => model.vehicle_brand?.type === 'truck'));
    };

    useEffect(() => {
        fetchData(currentTab);
        if (currentTab === 'model' || currentTab === 'motorisation') {
            fetchModels();
        }
    }, [currentTab]);

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
        localStorage.setItem('currentTab', tabId);
        fetchData(tabId);
    };

    const handleAddClick = () => {
        setFormData({});
        setIsEditing(false);
        setSelectedItem(null);
        setIsModalOpen(true);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (isEditing) {
                switch (currentTab) {
                    case 'brand':
                        await updateBrand(selectedItem.id, {...formData, type: "truck"});
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.brand.success.update')}/>, toastStyle);
                        break;
                    case 'model':
                        await updateModel(selectedItem.id, formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.model.success.update')}/>, toastStyle);
                        break;
                    case 'motorisation':
                        await updateMotorisation(selectedItem.id, formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.motorization.success.update')}/>, toastStyle);
                        break;
                    case 'vehicle':
                        await updateVehicle(selectedItem.id, formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.update')}/>, toastStyle);
                        break;
                }
            } else {
                switch (currentTab) {
                    case 'brand':
                        await createVehiculeBrand({...formData, type: "truck"});
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.brand.success.add')}/>, toastStyle);
                        break;
                    case 'model':
                        await createModel(formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.model.success.add')}/>, toastStyle);
                        break;
                    case 'motorisation':
                        await createMotorisation(formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.motorization.success.add')}/>, toastStyle);
                        break;
                    case 'vehicle':
                        await createVehicle(formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.add')}/>, toastStyle);
                        break;
                }
            }
            fetchData(currentTab);
            setIsModalOpen(false);
        } catch (error) {
            const message = isEditing ? 'update' : 'add';
            toast.error(<ToastError message={t(`adminPages.vehicleConfig.errors.${message}`)}/>, toastStyle);
        }
    };

    function handleCancel() {
        setIsModalOpen(false);
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2
    };

    return (
        <Box className="w-full h-full flex flex-col">
            <Box p={2} className="rounded-md">
                <CustomTabs menuItems={menuItems} onTabChange={handleTabChange} />
            </Box>
            <Box p={3} flex="1" display="flex" flexDirection="column">
                {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="loader" />
                    </div>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <DataTable
                            key={currentTab}
                            rows={rows}
                            columns={columns}
                            showExportImport={currentTab === 'vehicle'}
                            showActions={false}
                            showAddButton={true}
                            onAddRow={handleAddClick}
                            handleFileUpload={() => setIsUploadModalOpen(true)}
                            showSelectAll={false}
                            showApproveRejectButtons={false}
                            tabLabel={menuItems.find((item) => item.id === currentTab)?.label}
                        />
                    </>
                )}
            </Box>

            {/* Modal for adding/editing items */}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2" mb={2}>
                        {isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.vehicleConfig.common.add')} {menuItems.find((item) => item.id === currentTab)?.label}
                    </Typography>

                    <form onSubmit={handleFormSubmit}>
                        {/* Form for Brand */}
                        {currentTab === 'brand' && (
                            <>
                                <label className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.vehicleConfig.brand.name')}</label>
                                <input
                                    className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                    value={formData.brand_name || ''}
                                    placeholder={t('adminPages.vehicleConfig.brand.placeholder.name')}
                                    onChange={(e) => setFormData({...formData, brand_name: e.target.value})}
                                />
                                <label className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.brand.logo')}</label>
                                <input
                                    className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                    placeholder={t('adminPages.vehicleConfig.brand.placeholder.logo')}
                                    value={formData.logo || ''}
                                    onChange={(e) => setFormData({...formData, logo: e.target.value})}
                                />
                            </>
                        )}

                        {/* Form for Model */}
                        {currentTab === 'model' && (
                            <>
                                <label
                                    className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.model.brand')}</label>
                                <GenericSelect
                                    isMulti={false}
                                    inputs={brands.map(brand => ({value: brand.id, label: brand.brand_name}))}
                                    defaults={brands.filter(brand => formData.vehicle_brand_id === brand.id).map(brand => ({
                                        value: brand.id,
                                        label: brand.brand_name
                                    }))}
                                    handleChanges={(selectedOption) => setFormData({
                                        ...formData,
                                        vehicle_brand_id: selectedOption.value
                                    })}
                                    setWidth="100%"
                                />
                                <label
                                    className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.model.name')}</label>
                                <input
                                    className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                    value={formData.name || ''}
                                    placeholder={t('adminPages.vehicleConfig.model.placeholder.name')}
                                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                                />
                                <label
                                    className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.model.series')}</label>
                                <input
                                    className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                    value={formData.serie || ''}
                                    placeholder={t('adminPages.vehicleConfig.model.placeholder.series')}
                                    onChange={(e) => setFormData({...formData, serie: e.target.value})}
                                />

                            </>
                        )}

                        {/* Form for Motorisation */}
                        {currentTab === 'motorisation' && (
                            <>
                                <label
                                    className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.motorization.model')}</label>
                                <GenericSelect
                                    isMulti={false}
                                    inputs={models.map(model => ({value: model.id, label: model.name}))}
                                    defaults={models.filter(model => formData.vehicle_model_id === model.id).map(model => ({
                                        value: model.id,
                                        label: model.name
                                    }))}
                                    handleChanges={(selectedOption) => setFormData({
                                        ...formData,
                                        vehicle_model_id: selectedOption.value
                                    })}
                                    setWidth="100%"
                                />
                                <label
                                    className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.motorization.name')}</label>
                                <input
                                    className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                    value={formData.name || ''}
                                    placeholder={t('adminPages.vehicleConfig.motorization.placeholder.name')}
                                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                                />
                                <label
                                    className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.motorization.fuel')}</label>
                                <input
                                    className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                    value={formData.fuel || ''}
                                    placeholder={t('adminPages.vehicleConfig.motorization.placeholder.fuel')}
                                    onChange={(e) => setFormData({...formData, fuel: e.target.value})}
                                />

                            </>
                        )}

                        {/* Form for Vehicle */}
                        {currentTab === 'vehicle' && (
                            <>
                                <label
                                    className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.vehicleConfig.vehicle.motorization')}</label>
                                <GenericSelect
                                    isMulti={false}
                                    inputs={motorisations.map(motor => ({value: motor.id, label: motor.name}))}
                                    defaults={motorisations.filter(motor => formData.motorisation_id === motor.id).map(motor => ({
                                        value: motor.id,
                                        label: motor.name
                                    }))}
                                    handleChanges={(selectedOption) => setFormData({ ...formData, motorisation_id: selectedOption.value })}
                                    setWidth="100%"
                                />
                            </>
                        )}
                    </form>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button onClick={handleCancel}
                                style={{ color: '#f97316' }}
                                variant="text"
                        >
                            {t('adminPages.vehicleConfig.common.cancel')}
                        </Button>
                        <Button
                            onClick={handleFormSubmit}
                            variant="contained"
                            style={{ backgroundColor: '#f97316', color: 'white' }}
                            disabled={loading}
                        >
                            {loading ?
                                (isEditing ? t('adminPages.vehicleConfig.common.loading') : t('adminPages.vehicleConfig.common.adding'))
                                : (isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.vehicleConfig.common.add'))}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Upload Modal */}
            <Modal
                open={isUploadModalOpen}
                onClose={() => setIsUploadModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2" mb={2}>
                        {t('adminPages.vehicleConfig.common.add')}
                    </Typography>

                    <div
                        {...getRootProps()}
                        className={`w-full p-8 border-2 border-dashed rounded-lg text-center transition-all cursor-pointer
                            ${isDragActive ? 'border-orange-500 bg-orange-50' : 'border-gray-300 hover:border-orange-400 hover:bg-orange-50'}`}
                    >
                        <input {...getInputProps()} />
                        <div className="flex flex-col items-center justify-center space-y-4">
                            {importLoading ? (
                                <>
                                    <CircularProgress style={{ color: '#f97316' }} />
                                    <Typography variant="h6" style={{ color: '#f97316' }}>
                                        {t('adminPages.vehicleConfig.common.importing')}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <CloudUploadIcon style={{ fontSize: 48, color: '#f97316' }} />
                                    <Typography variant="h6" style={{ color: '#f97316' }}>
                                        {isDragActive
                                            ? t('adminPages.vehicleConfig.dropzone.active')
                                            : t('adminPages.vehicleConfig.dropzone.inactive')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('adminPages.vehicleConfig.dropzone.click')}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {t('adminPages.vehicleConfig.dropzone.format')}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </div>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => setIsUploadModalOpen(false)} color="primary">
                            {t('adminPages.vehicleConfig.common.close')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}