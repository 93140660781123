import WidgetElement from "../../components/WidgetElement/WidgetElement";
import SellerProductTable from "../../../../components/SellerProductTable/SellerProductTable";
import {useNavigate} from "react-router";
import React, {useEffect, useRef, useState} from "react";
import {
    assignPromoCode,
    getDashboardStats,
    getPromoCodes,
    restockProduct, updateProductPhotos,
    updateProductPrice,
    uploadProductFile
} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {calculatePercentageChange, formatCurrency} from "../../../../utils/utils";
import {create} from "zustand";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import Cookies from "js-cookie";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from "react-i18next";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {AddProductPageContext} from "../AddProducts/AddProduct";

export default function Products() {
    const navigate = useNavigate();
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [importLoading, setImportLoading] = useState(false);

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.name.endsWith('.csv')) {
            await handleImportCSV(file);
        } else {
            toast.error(<ToastError message={"Format de fichier invalide"}/>, toastStyle);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        multiple: false
    });

    const handleImportCSV = async (file) => {
        try {
            setImportLoading(true);
            const formData = new FormData();
            formData.append('csv_file', file);
            const response = await uploadProductFile(formData);
            if (response.status === 200) {
                toast.success("Import réussi");
                setIsUploadModalOpen(false);
            }
        } catch (error) {
            toast.error(<ToastError message={"Une erreur s'est produite lors de l'import"}/>, toastStyle);
        } finally {
            setImportLoading(false);
        }
    };

    const [stats, setStats] = useState([]);
    useEffect(() => {
        try {
            const stats = Cookies.get("stats")
            if (stats) {
                const data = JSON.parse(stats)
                setStats(data)
            }
        } catch (e) {
            getDashboardStats()
                .then((response) => {
                    if (response.status === 200) {
                        Cookies.set("stats", JSON.stringify(response.data));
                        setStats(response.data);
                    }
                }).catch((error) => {
                toast.error(<ToastError
                    message={"une erreur s'est produite lors de la récupération des statistiques"}/>, toastStyle);
            });
        }
    }, []);


    const {editPopUpVisible, token} = useSellerProductContext();

    const [shop, setShop] = useState({})
    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                setShop(JSON.parse(shopData))
            }
        } catch (e) {
            //TODO : handle error
        }
    }, []);

    const [promoCodes, setPromoCodes] = useState([])
    useEffect(() => {
        getPromoCodes().then((response) => {
            if (response.status === 200) {
                setPromoCodes(response.data.promotions)
            }
        }).catch((e) => {
            toast.error(<ToastError
                message={"une erreur s'est produite lors de la récupération des codes promo"}/>, toastStyle);
        })
    }, [token])

    const [showHidden, setShowHidden] = useState(false);
    const {t} = useTranslation();
    return (
        <>
            <div
                className="w-full h-full flex flex-col items-start gap-4 px-1 sm:px-4 py-2 overflow-y-auto noScrollBar max-h-[calc(91vh-40px)]">
                <Dialog
                    open={isUploadModalOpen}
                    onClose={() => setIsUploadModalOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        Importer des produits
                    </DialogTitle>
                    <DialogContent>
                        <div
                            {...getRootProps()}
                            className={`w-full p-8 border-2 border-dashed rounded-lg text-center transition-all cursor-pointer
                                ${isDragActive ? 'border-primaryLight bg-primaryExtraLight' : 'border-gray-300 hover:border-primaryLight hover:bg-primaryExtraLight'}`}
                        >
                            <input {...getInputProps()} />
                            <div className="flex flex-col items-center justify-center space-y-4">
                                {importLoading ? (
                                    <>
                                        <CircularProgress style={{color: '#f97316'}}/>
                                        <Typography variant="h6" style={{color: '#f97316'}}>
                                            {t('adminPages.vehicleConfig.common.importing')}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CloudUploadIcon style={{fontSize: 48, color: '#f97316'}}/>
                                        <Typography variant="h6" style={{color: '#f97316'}}>
                                            {isDragActive
                                                ? t('adminPages.vehicleConfig.dropzone.active')
                                                : t('adminPages.vehicleConfig.dropzone.inactive')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('adminPages.vehicleConfig.dropzone.click')}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {t('adminPages.vehicleConfig.dropzone.format')}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsUploadModalOpen(false)} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className="w-full h-fit flex flex-row items-center justify-between">
                    <p className="hidden sm:block font-semibold text-ellipsis whitespace-nowrap overflow-hidden w-full text-2xl">Mes
                        Produits</p>
                    <div className="flex flex-row items-center justify-end w-full gap-4">
                        <div
                            onClick={() => setShowHidden(!showHidden)}
                            className="w-[140px] bg-primaryExtraLight flex items-center border-primaryLight border justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded cursor-pointer">
                            {showHidden ?
                                (<>
                                    <img src="/resources/eye.svg" className="primary_filter w-4" alt="add icon"/>
                                    <p className="hidden sm:flex text-sm font-semibold text-primaryLight text-ellipsis whitespace-nowrap overflow-hidden">Liste
                                        des produits visibles</p>
                                </>)
                                :
                                (<>
                                    <img src="/resources/eye-slash.svg" className="primary_filter w-4" alt="add icon"/>
                                    <p className="hidden sm:flex text-sm font-semibold text-primaryLight truncate">Liste
                                        des produits cachés</p>
                                </>)
                            }
                        </div>
                        <div
                            onClick={() => setIsUploadModalOpen(true)}
                            className="w-[140px] bg-primaryExtraLight flex items-center border-primaryLight border justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded cursor-pointer"
                        >
                            <img src="/resources/import_icon.svg" className="primary_filter w-4" alt="upload icon"/>
                            <p className="hidden sm:block text-sm font-semibold text-primaryLight text-ellipsis whitespace-nowrap overflow-hidden">
                                Importer des produits
                            </p>
                        </div>
                        <div
                            onClick={() => {
                                if (shop?.status === "subscribed") {
                                    navigate("./addProduct");
                                }
                            }}
                            title={shop?.status === "subscribed" ? "" : "Subscribe to add products"}
                            className={`w-[140px] ${
                                shop?.status === "subscribed" ? "bg-primaryLight cursor-pointer" : "bg-gray-500 cursor-not-allowed"
                            } flex items-center justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded`}
                        >
                            <img src="/resources/add.svg" className="white_filter" alt="add icon"/>
                            <p className="text-sm hidden sm:block font-semibold text-white text-ellipsis whitespace-nowrap overflow-hidden">
                                Ajouter un Produit
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-4 w-full h-fit items-center flex-wrap sm:flex-nowrap">
                    <WidgetElement
                        title="Valeur totale du stock"
                        amount={formatCurrency(stats?.total_stock_value)}
                        percentage={calculatePercentageChange(
                            stats?.total_stock_value,
                            stats?.monthly_stock_value_stats?.length > 1
                                ? stats?.monthly_stock_value_stats[stats?.monthly_stock_value_stats?.length - 2]?.stock_value
                                : 0
                        )}
                    />
                    <WidgetElement title="Nombre total d'articles en stock"
                                   amount={stats?.total_items_in_stock}
                                   percentage={calculatePercentageChange(
                                       stats?.total_items_in_stock,
                                       stats?.yesterday_items_in_stock
                                   )}
                    />
                    <WidgetElement title="Produits en rupture de stock"
                                   amount={stats?.out_of_stock_products}
                                   percentage={
                                       calculatePercentageChange(
                                           stats?.out_of_stock_products,
                                           stats?.yesterday_out_of_stock_count
                                       )}
                    />
                </div>
                <SellerProductTable maxHeight={"62vh"} showOnlyHidden={showHidden}/>
            </div>
            {editPopUpVisible && <EditProductPopUp promoCodes={promoCodes}/>}
        </>
    )
}

const AddProductPictures = () => {
    const [mainImage, setMainImage] = useState(null);
    const [additionalMedia, setAdditionalMedia] = useState([]);
    const [tiktokMedia, setTiktokMedia] = useState([]);
    const [showUrlModal, setShowUrlModal] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [isMainImage, setIsMainImage] = useState(true);
    const [isVideo, setIsVideo] = useState(false);
    const {addPhoto, removePhoto, productInfo} = AddProductPageContext();

    useEffect(() => {
        setMainImage(productInfo.mainPhoto?.photo_link);
        setAdditionalMedia(productInfo.photos.map(photo => ({url: photo.photo_link, type: photo.type})));
    }, [productInfo.mainPhoto?.photo_link, productInfo.photos]);

    const additionalMediaRef = useRef(null);

    const handleMainImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            addPhoto({image_file: file, type: "img"}, true);
        }
    };

    const handleAdditionalMediaUpload = async (e) => {
        const files = Array.from(e.target.files);
        const newMedia = files.map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
            type: "img"
        }));

        setAdditionalMedia(prev => [...prev, ...newMedia]);

        for (const {file} of newMedia) {
            try {
                await addPhoto({image_file: file, type: "img"});
            } catch (error) {
                console.error(`Error uploading image: ${file.name}`, error);
                toast.error(<ToastError message={`Erreur lors de l'upload de l'image ${file.name}.`}/>, toastStyle);
            }
        }
        e.target.value = null;
    };

    const handleUrlMediaSubmit = () => {
        if (mediaUrl) {
            if (isVideo) {
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
                const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

                if (!youtubeRegex.test(mediaUrl) && !tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message="Veuillez entrer une URL YouTube ou TikTok valide."/>, toastStyle);
                    return;
                }
                if (youtubeRegex.test(mediaUrl)) {
                    setAdditionalMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    });
                } else {
                    setTiktokMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        index_at_photos: productInfo.photos.length
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "tiktok"
                    });
                }
            } else if (isMainImage) {
                setMainImage(mediaUrl);
                addPhoto({photo_link: mediaUrl, type: "img"}, true);
            } else {
                setAdditionalMedia(prev => [...prev, {url: mediaUrl, name: mediaUrl, type: "img"}]);
                addPhoto({photo_link: mediaUrl, type: "img"});
            }
            setMediaUrl('');
            setShowUrlModal(false);
        }
    };

    const removeAdditionalMedia = (index) => {
        const updatedMedia = additionalMedia.filter((_, i) => i !== index);
        removePhoto(index);
        setAdditionalMedia(updatedMedia);
    };

    const renderMediaItem = (item, index) => {
        if (item.type === "video") {
            const isYouTube = item.url.includes("youtube.com") || item.url.includes("youtu.be");
            if (isYouTube) {
                return (
                    <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                        <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.url)}`}
                            className="w-full h-full"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <button
                            onClick={() => removeAdditionalMedia(index)}
                            className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                        >
                            <img src="/resources/close.svg" alt="close icon"/>
                        </button>
                    </div>
                )
            }
        } else {
            return (
                <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                    <img src={item.url} alt={`additional-${index}`} className="w-full h-full object-contain"/>
                    <button
                        onClick={() => removeAdditionalMedia(index)}
                        className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                    >
                        <img src="/resources/close.svg" alt="close icon"/>
                    </button>
                </div>
            );
        }
    };

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    const removeTikTokMedia = (indexToRemove, index_at_photos) => {
        setTiktokMedia(prevMedia => prevMedia.filter((_, index) => index !== indexToRemove));
        removePhoto(index_at_photos)
    };

    return (
        <div className="flex flex-col gap-4 w-full pb-4">
            <p className="leading-none pb-4 border-b border-border font-medium text-xl">Images et vidéos du produit</p>

            {/* Main Image Section */}
            <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium truncate max-w-fit">Image principale </p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">Ajouter
                                une image</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis une URL"
                            index={2}
                            handleClick={() => {
                                setIsMainImage(true);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => document.getElementById('mainImageInput').click()}
                        />
                    </GenericDropDown>
                    <input
                        id="mainImageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={handleMainImageUpload}
                    />
                </div>
                <img src={mainImage || "/images/placeholder.png"} alt="product"
                     className="w-full h-[200px] object-contain bg-gray-100"/>
            </div>

            {/* Additional Media Section */}
            <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium truncate max-w-fit">Images et vidéos supplémentaires</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">Ajouter
                                des médias</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis une URL"
                            index={2}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => additionalMediaRef.current.click()}
                        />
                        <GenericDropDownItem
                            value="Vidéo YouTube ou Tiktok"
                            index={4}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(true);
                                setShowUrlModal(true);
                            }}
                        />
                    </GenericDropDown>
                    <input
                        ref={additionalMediaRef}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        style={{display: 'none'}}
                        onChange={handleAdditionalMediaUpload}
                    />
                </div>

                {/* Display additional media or placeholders */}
                <div className="flex flex-row flex-wrap items-center gap-4 w-full">
                    {additionalMedia.map((item, index) => renderMediaItem(item, index))}
                    {Array.from({length: Math.max(0, 3 - additionalMedia.length)}).map((_, index) => (
                        <div key={`placeholder-${index}`} className="relative w-[31%] h-32 bg-gray-100">
                            <img src="/images/placeholder.png" alt="placeholder"
                                 className="w-full h-full object-contain"/>
                        </div>
                    ))}
                </div>
                {tiktokMedia.map((item, index) => (
                    <div key={index} className="flex flex-col border border-border rounded">
                        <div
                            className="flex flex-row items-center min-h-12 border-b-border border-b pl-4 pr-2 justify-between">
                            <p className="font-medium">tiktok video N° {index + 1}</p>
                            <button
                                onClick={() => removeTikTokMedia(index, item.index_at_photos)}
                                className="bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                            >
                                <img src="/resources/close.svg" alt="close icon"/>
                            </button>
                        </div>
                        <iframe
                            key={index}
                            src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.url)}`}
                            className="w-full"
                            width="100%"
                            height="100%"
                            allowFullScreen
                        />
                    </div>
                ))}
            < /div>

            {/* URL Modal */}
            {showUrlModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 border-border border rounded-lg shadow-lg w-[400px]">
                        <h2 className="text-lg font-semibold mb-4">
                            {isVideo ? "Ajouter une vidéo YouTube ou Tiktok" : "Ajouter une image depuis une URL"}
                        </h2>
                        <input
                            type="text"
                            className="w-full border p-2 rounded mb-4 h-10 placeholder:text-sm focus:outline-primaryLight"
                            placeholder={isVideo ? "Entrez l'URL de la vidéo YouTube ou Tiktok" : "Entrez l'URL de l'image"}
                            value={mediaUrl}
                            onChange={(e) => setMediaUrl(e.target.value)}
                        />
                        <div className="flex justify-end gap-4">
                            <button
                                className="border border-border text-black px-4 py-2 rounded font-medium hover:bg-gray-50"
                                onClick={() => setShowUrlModal(false)}
                            >
                                Annuler
                            </button>
                            <div
                                onClick={handleUrlMediaSubmit}
                                className="bg-primaryLight flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer"
                            >
                                <img src="/resources/save.svg" alt="save icon"/>
                                <p className="text-sm font-semibold text-white">Ajouter</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const EditProductPopUp = ({promoCodes}) => {
    const {product, setEditPopUpVisible, updateToken} = useSellerProductContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const [loading, setLoading] = useState(false);

    const [restockQuantity, setRestockQuantity] = useState(0); // Quantity to add
    const [newPrice, setNewPrice] = useState(product.price || 0);
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [oldPromo, setOldPromo] = useState(null);
    const calculateNewTotalStock = () => product.stock + restockQuantity;
    const {addPhoto, productInfo,clearProductInfo} = AddProductPageContext();

    useEffect(() => {
        addPhoto({photo_link: product?.photos?.[0]?.link, type: product?.photos?.[0]?.type}, true)
        product?.photos?.slice(1)?.map((photo) => {
            addPhoto({photo_link: photo.link, type: photo.type})
        })
    }, [addPhoto, product?.photos])

    const handleSaveChanges = async () => {
        setLoading(true);
        try {
            if (restockQuantity > 0) {
                const updatedStock = calculateNewTotalStock();
                const restockResponse = await restockProduct(product.id, updatedStock);
                if (restockResponse.status === 200) {
                    console.log(restockResponse.data);
                } else {
                    console.error(restockResponse.data);
                }
            }

            if (newPrice !== product.price) {
                const priceResponse = await updateProductPrice(product.id, newPrice);
                if (priceResponse.status === 200) {
                    console.log(priceResponse.data);
                } else {
                    console.error(priceResponse.data);
                }
            }

            let data = new FormData();
            let newProductData = {...productInfo}
            if (newProductData.mainPhoto?.image_file) {
                data.append("photos[0][image_file]", newProductData.mainPhoto.image_file);
            } else if (newProductData.mainPhoto?.photo_link) {
                data.append("photos[0][photo_link]", newProductData.mainPhoto.photo_link);
            }
            data.append("photos[0][type]", 'img');

            productInfo.photos.forEach((photo, index) => {
                if (photo.image_file) {
                    data.append(`photos[${index + 1}][image_file]`, photo.image_file);
                }
                if (photo.photo_link) {
                    data.append(`photos[${index + 1}][photo_link]`, photo.photo_link);
                }
                data.append(`photos[${index + 1}][type]`, photo.type || 'img');
            });

            data.append("_method", "PUT");
            updateProductPhotos(product.id, data)
                .then((response) => {
                    if (response.status === 200) {
                        updateToken();
                        setEditPopUpVisible(false);
                        updatePopUpVisible(false);
                        clearProductInfo();
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    setLoading(false);
                })

        } catch (error) {
            setLoading(false);
            console.error('Error during update:', error);
        }
    };

    useEffect(() => {
        const promoWithProduct = promoCodes.find(promo =>
            promo.products.some(promoProduct => promoProduct.id === product.id)
        );
        if (promoWithProduct) {
            console.log(promoWithProduct);
            setOldPromo(promoWithProduct);
            setSelectedPromo(promoWithProduct);
        }
    }, [promoCodes, product.id]);

    return (
        <div
            className="flex w-full sm:w-[40vw] h-fit bg-white rounded z-[99] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex-col">
            <div className="min-h-14 border-b border-b-border px-2 w-full flex flex-row items-center justify-between">
                <p className="font-medium">Gérer le Produit</p>
                <div
                    onClick={() => {
                        updatePopUpVisible(false);
                        setEditPopUpVisible(false);
                    }}
                    className="w-10 h-10 flex items-center justify-center hover:bg-gray-100 cursor-pointer rounded-full"
                >
                    <img src="/resources/close.svg" alt="fermer" className="w-3"/>
                </div>
            </div>
            <div className="w-full h-full flex flex-col items-start">
                <div className="flex flex-row w-full">
                    <div className="w-[40%] min-h-full border-r border-r-border p-2">
                        <div className="flex flex-col gap-2 items-center">
                            <ImageWithFallback src={product.image} alt="produit" className="w-36 h-32 object-contain"/>
                            <div className="flex flex-col gap-2 items-start">
                                <p className="font-medium text-ellipsis line-clamp-2 overflow-hidden">
                                    {product.name}
                                </p>
                                <p className="text-gray-500 text-sm font-medium">{product.sku}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[60%] min-h-full p-4 flex flex-col gap-4">

                        {/* Stock Update */}
                        <div className="flex flex-col gap-2">
                            <label className="font-medium">Quantité à réapprovisionner :</label>
                            <input
                                type="number"
                                value={restockQuantity}
                                min={0}
                                onChange={(e) => setRestockQuantity(Number(e.target.value))}
                                className="border border-gray-300 p-2 rounded-md"
                                placeholder="Entrer la quantité à ajouter"
                            />
                            <p className="text-gray-500">
                                Stock actuel: {product.stock} + Quantité ajoutée: {restockQuantity} =
                                <span className="font-bold"> {calculateNewTotalStock()}</span> (Nouveau stock)
                            </p>
                        </div>

                        {/* Price Update */}
                        <div className="flex flex-col gap-2">
                            <label className="font-medium">Modifier le prix :</label>
                            <input
                                type="number"
                                value={newPrice}
                                min={0}
                                onChange={(e) => setNewPrice(Number(e.target.value))}
                                className="border border-gray-300 p-2 rounded-md"
                                placeholder="Entrer le nouveau prix"
                            />
                        </div>

                        {/* Save Changes Button */}
                        <button
                            onClick={handleSaveChanges}
                            className="bg-primaryLight min-h-10 px-4 flex flex-row gap-2 items-center justify-center rounded-lg hover:bg-primaryDark cursor-pointer"
                        >
                            {loading ? (
                                <div className="w-4">
                                    <div className="loader_white"></div>
                                </div>
                            ) : (
                                <img src="/resources/pen.svg" className="white_filter" alt="save icon"/>
                            )}
                            <p className="font-medium text-white leading-none truncate">Enregistrer les
                                modifications</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const useSellerProductContext = create((set) => ({
    editPopUpVisible: false,
    product: null,
    token: 0,
    setEditPopUpVisible: (value) => set({editPopUpVisible: value}),
    setProduct: (value) => set({product: value}),
    updateToken: () => set({token: Math.floor(Math.random() * 100) + 1}),
}));