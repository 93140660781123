import React, {useEffect, useRef, useState} from "react";
import "../../../../assets/style/NoScrollBar.css"
import {useMarketplaceContext} from "../../index";
import {useCompatibleVehicleContext} from "../../../../components/CompatibleVehicules/CompatibleVehicles";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";


export default function HeroSection({currentSelected}) {
    const {t} = useTranslation();
    const [selected, setSelected] = currentSelected;
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    // Common resize handler for window width
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Content data for hero section
    const contentData = [
        {
            title: t('hero.section1.title'),
            description: t('hero.section1.description'),
            image: "/resources/auto_part_illustration.svg",
            priority: true,
            alt: "Illustration de pièces auto"
        },
        {
            title: t('hero.section2.title'),
            description: t('hero.section2.description'),
            image: "/resources/vehicules_illustration.svg",
            priority: false,
            alt: "Illustration de véhicules neufs et d'occasion"
        },
        {
            title: t('hero.section3.title'),
            description: t('hero.section3.description'),
            image: "/resources/service_illustration.webp",
            priority: false,
            alt: "Illustration des services d'entretien de véhicule"
        }
    ];

    // Helper for safely injecting HTML content
    const createMarkup = (htmlContent) => ({__html: htmlContent});

    return (
        <section className="flex flex-col noScrollBar">
            <div className="px-[11vw]">
                <div
                    className="flex flex-col sm:flex-row items-center justify-between min-h-[564px] sm:min-h-[500px] relative">
                    {contentData.map((content, index) => (
                        <article
                            key={index}
                            style={{
                                left: selected === index ? "0" : "-100%",
                                opacity: selected === index ? 1 : 0,
                            }}
                            className="flex flex-col gap-10 absolute transition-all duration-1000 lg:duration-500 sm:w-[41vw] lg:w-[36.5vw]"
                        >
                            {index === 0 ? (
                                <h1
                                    className="font-semibold text-4xl sm:text-3xl md:text-4xl lg:text-5xl leading-[60px] sm:leading-[45px] md:leading-[55px] lg:leading-[70px]"
                                    dangerouslySetInnerHTML={createMarkup(content.title)}
                                />
                            ) : (
                                <h2
                                    className="font-semibold text-4xl sm:text-3xl md:text-4xl lg:text-5xl leading-[60px] sm:leading-[45px] md:leading-[55px] lg:leading-[70px]"
                                    dangerouslySetInnerHTML={createMarkup(content.title)}
                                />
                            )}
                            <p
                                className="font-medium text-lg text-[#575757]"
                                dangerouslySetInnerHTML={createMarkup(content.description)}
                            />
                        </article>
                    ))}
                    {contentData.map((content, index) => (
                        <img
                            key={index}
                            loading={content.priority ? "eager" : "lazy"}
                            style={{
                                right: selected === index ? "0" : "-100%",
                                opacity: selected === index ? 1 : 0,
                                transform: width > 640 ? "translate(0%,-50%)" : ""
                            }}
                            className="absolute transition-all duration-1000 lg:duration-500 w-[85vw] xs:w-[76vw] sm:w-[45vw] md:w-[38vw] lg:w-[36vw] bottom-0 sm:top-1/2"
                            src={content.image}
                            alt={content.alt}
                        />
                    ))}
                </div>
            </div>
            <CTA selectedState={[selected, setSelected]}/>
            <BrandsStrip/>
        </section>
    );
}

function CTA({selectedState}) {
    const {t} = useTranslation();
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
    const [selected, setSelected] = selectedState;
    const menuItems = [t('hero.menu.autoparts'), t('hero.menu.vehicles'), t('hero.menu.services')];
    const {updatePopupVisible} = useMarketplaceContext();
    const {updateVisible, brand, model, motorisation} = useCompatibleVehicleContext();
    const navigate = useNavigate();

    const handleClick = (condition = false) => {
        if (condition) {
            updatePopupVisible(true);
            updateVisible(true);
        }
    };

    const handleSearch = () => {
        if (brand || model || motorisation) {
            navigate("/search", {state: {category: "products", term: ""}});
        }
    };

    return (
        <nav className="flex flex-col gap-2 items-center justify-center">
            <nav className="w-[380px] sm:w-[422px] h-12 bg-black flex items-center justify-between rounded-full relative">
                {menuItems.map((item, index) => (
                    <button
                        key={index}
                        className="w-1/3 h-full flex items-center justify-center cursor-pointer relative z-10 text-white font-medium text-sm"
                        onClick={() => setSelected(index)}
                    >
                        {item}
                    </button>
                ))}
                <div
                    className="w-[140px] h-12 bg-primaryDark rounded-full absolute top-0 transition-all duration-300 mix-blend-screen"
                    style={{left: `${selected * 34}%`}}
                />
            </nav>
            <div
                className="w-full sm:w-[82vw] lg:w-[67vw] items-center justify-between flex flex-col sm:flex-row gap-4 bg-black sm:rounded-full h-[330px] sm:h-16 p-4 sm:py-0 sm:pl-6 sm:pr-2 ">
                <div
                    className="flex flex-col sm:flex-row w-full sm:w-[60vw] lg:w-[50vw] h-16 px-4 sm:px-0 items-start sm:items-center gap-4 sm:gap-0">
                    <div
                        className="flex flex-row pr-6 border-r-borderDark items-center sm:justify-center sm:border-r w-full sm:w-1/4 min-h-full">
                        <p className="text-xl sm:text-[16px] font-semibold text-white text-ellipsis whitespace-nowrap overflow-hidden">
                            {width > 1024 || width < 640 ? t('hero.search.byVehicle') : t('hero.search.search')}
                        </p>
                    </div>
                    <div
                        onClick={() => handleClick(true)}
                        className="text-white cursor-pointer flex flex-row rounded border-border border sm:border-r sm:border-y-0 sm:border-l-0 sm:rounded-none gap-2 items-center sm:justify-center w-full sm:w-1/4 h-full min-h-10 px-2">
                        <p className="text-sm font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                            {brand === null ? t('hero.search.chooseBrand') : brand.name}
                        </p>
                    </div>
                    <div
                        onClick={() => handleClick(model !== null)}
                        style={{
                            color: model !== null ? "white" : "gray",
                            cursor: model !== null ? "pointer" : "default"
                        }}
                        className="flex flex-row rounded border-border border sm:border-r sm:border-y-0 sm:border-l-0 sm:rounded-none gap-2 items-center sm:justify-center w-full sm:w-1/4 h-full min-h-10 px-2">
                        <p className="text-sm font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                            {model === null ? t('hero.search.chooseModel') : model.name}
                        </p>
                    </div>
                    <div
                        onClick={() => handleClick(motorisation !== null)}
                        style={{
                            color: motorisation !== null ? "white" : "gray",
                            cursor: motorisation !== null ? "pointer" : "default"
                        }}
                        className="flex flex-row rounded border-border border sm:border-none gap-2 items-center sm:justify-center w-full sm:w-1/4 h-full min-h-10 px-2">
                        <p className="text-sm font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                            {motorisation === null ? t('hero.search.chooseEngine') : motorisation.name}
                        </p>
                    </div>
                </div>
                <button
                    disabled={(!brand && !model && !motorisation)}
                    onClick={handleSearch}
                    className="w-full sm:w-[140px] h-12 disabled:bg-gray-200 bg-primaryLight rounded-lg sm:rounded-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium text-white">{t('hero.search.searchButton')}</p>
                </button>
            </div>
        </nav>
    )
}

// Function to detect if the user is on Firefox
const isFirefox = () => typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

function BrandsStrip() {
    const brands = [
        "audi", "mitsubishi", "renault", "peugeot", "toyota", "mercedes",
        "bmw", "hyundai", "w", "citroen", "chevrolet", "fiat", "volvo", "honda", "kia"
    ];

    const scrollContainerRef = useRef(null);
    const animationFrameRef = useRef(null);
    const [scrollDirection, setScrollDirection] = useState(1); // 1 for forward, -1 for backward

    // Function to handle smooth scrolling in a particular direction
    const smoothScroll = (element, amount) => {
        if (!isFirefox()) {
            element.scrollLeft += amount;
        }
    };

    // Function to handle the continuous scrolling logic
    const scrollBrands = () => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            // Check if we've reached the end (scrolling right)
            if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
                setScrollDirection(-1); // Change direction to left (backwards)
            }
            // Check if we've reached the beginning (scrolling left)
            else if (scrollContainer.scrollLeft <= 0) {
                setScrollDirection(1); // Change direction to right (forwards)
            }

            // Scroll by a small amount depending on the current direction
            smoothScroll(scrollContainer, scrollDirection * 1); // Adjust speed if needed

            // Request the next frame for smooth scrolling
            animationFrameRef.current = requestAnimationFrame(scrollBrands);
        }
    };

    useEffect(() => {
        // Start the scroll animation when the component mounts
        animationFrameRef.current = requestAnimationFrame(scrollBrands);

        return () => {
            // Cleanup the animation frame on component unmount
            cancelAnimationFrame(animationFrameRef.current);
        };
    }, [scrollDirection]); // Restart the animation when direction changes

    return (
        <div className="noScrollBar w-full overflow-hidden flex items-center justify-center py-14">
            <div
                ref={scrollContainerRef}
                className="w-full bg-gray-100 flex flex-row items-center overflow-x-auto overflow-y-hidden noScrollBar"
                style={{scrollBehavior: 'smooth'}}  // Handle Firefox separately
            >
                {/* Map through the brands to display the logos */}
                {[...brands, ...brands, ...brands].map((brand, index) => (
                    <div key={index}
                         className="h-24 min-w-[168px] border-r border-gray-300 flex items-center justify-center p-4">
                        <img src={`/resources/${brand}_logo.svg`} alt={`${brand} logo`}
                             className="max-w-full max-h-full"/>
                    </div>
                ))}
            </div>
        </div>
    );
}
