import React, {useEffect, useRef, useState} from "react";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import {
    getConditions,
    getSubCategory,
    addService,
    getCategoryhasSub
} from "../../../../services/SellerSpace/API";
import {useNavigate} from "react-router";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {create} from "zustand";
import Cookies from "js-cookie";

export const AddServicePageContext = create((set) => ({
    serviceInfo: {
        photos: [],
        name: '',
        description: '',
        price: '',
        conditions_ids: [], // Initialize as empty array
        available_cars: '',
        weekend_available: 0, // Initialize as 0
        sub_category_id: null,
        category_id: null
    },

    setServiceInfo: (info) => set((state) => ({
        serviceInfo: {
            ...state.serviceInfo,
            ...info,
            // Ensure conditions_ids is always an array
            conditions_ids: Array.isArray(info.conditions_ids) ? info.conditions_ids : state.serviceInfo.conditions_ids,
            // Convert weekend_available to 1 or 0
            weekend_available: typeof info.weekend_available === 'undefined' ?
                state.serviceInfo.weekend_available :
                info.weekend_available === 'true' || info.weekend_available === true ? 1 : 0
        }
    })),

    addPhoto: (photo) => set((state) => ({
        serviceInfo: {
            ...state.serviceInfo,
            photos: [...state.serviceInfo.photos, photo],
        },
    })),

    removePhoto: (index) => set((state) => ({
        serviceInfo: {
            ...state.serviceInfo,
            photos: state.serviceInfo.photos.filter((_, i) => i !== index),
        },
    })),

}));

export default function AddService() {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [loading, setLoading] = useState(false);

    const {serviceInfo, setServiceInfo} = AddServicePageContext();

    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                if (shopData) {
                    const shopInfo = JSON.parse(shopData);
                    if (shopInfo?.status !== "subscribed") {
                        navigate(-1);
                    }
                }
            }
        } catch (e) {

        }
    }, [navigate]);

    // Fetch categories and conditions
    useEffect(() => {
        getCategoryhasSub()
            .then((response) => {
                console.log("Categories Data:", response.data);
                if (Array.isArray(response.data)) {
                    const filteredCategories = response?.data?.filter(category => category.type === 'service');
                    setCategories(filteredCategories);
                } else {
                    console.error("Error: Expected an array for categories.");
                }
            })
            .catch(() => {
                toast.error(<ToastError message="Erreur lors de la récupération des catégories."/>, toastStyle);
            });

        const fetchConditions = async () => {
            try {
                const data = await getConditions();
                console.log("Conditions Data:", data);
                if (Array.isArray(data?.data)) {
                    setConditions(data?.data);
                } else {
                    console.error("Error: Expected an array for conditions.");
                }
            } catch (error) {
                console.error("Error fetching conditions:", error);
            }
        };

        fetchConditions();
    }, []);

    // Fetch subcategories when a category is selected
    useEffect(() => {
        const fetchSubCategories = async () => {
            if (selectedCategory) {
                try {
                    const response = await getSubCategory(selectedCategory.value);
                    console.log("Subcategories Data:", response);
                    if (Array.isArray(response?.data)) {
                        setSubCategories(response.data);
                    } else {
                        console.error("Error: Expected an array for subcategories.");
                    }
                } catch (error) {
                    toast.error(<ToastError
                        message={error.response?.data?.message}/>, toastStyle);
                }
            }
        };

        fetchSubCategories();
    }, [selectedCategory]);

    // Handle data changes
    const handleServiceDataChange = (key, value) => {

        if (key === 'conditions_ids' && !Array.isArray(value)) {
            value = [];
        }
        if (key === 'weekend_available') {
            // Convert to 1 or 0
            value = Boolean(value) ? 1 : 0;
        }
        setServiceInfo({[key]: value});
    };

    // Submit the form and call the API
    const handleSubmit = () => {
        setLoading(true);

        const formData = new FormData();

        // Add all service info fields to formData
        Object.keys(serviceInfo).forEach(key => {
            if (key === 'photos') {
                serviceInfo.photos.forEach((photo, index) => {
                    if (photo.image_file) {
                        formData.append(`photos[${index}][image_file]`, photo.image_file);
                        formData.append(`photos[${index}][type]`, photo.type);
                        formData.append(`photos[${index}][tiktok_link]`, photo.tiktok_link || '');
                    } else if (photo.photo_link) {
                        formData.append(`photos[${index}][photo_link]`, photo.photo_link);
                        formData.append(`photos[${index}][type]`, photo.type);
                        formData.append(`photos[${index}][tiktok_link]`, photo.tiktok_link || '');
                    }
                });
            } else if (key === 'conditions_ids') {
                // Send conditions_ids as array elements
                serviceInfo[key].forEach((condition, index) => {
                    formData.append(`conditions_ids[${index}]`, condition);
                });
            } else if (key === 'weekend_available') {
                // Send weekend_available as 1 or 0
                formData.append(key, serviceInfo[key]);
            } else {
                formData.append(key, serviceInfo[key]);
            }
        });

        // Add sub_category_id separately
        formData.append('sub_category_id', selectedSubCategory?.value || '');

        addService(formData).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(<ToastSuccess message="Service ajouté avec succès."/>, toastStyle);
                navigate('/seller/services');
            } else {
                toast.error(<ToastError message="Erreur lors de l'ajout du service."/>, toastStyle);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            let firstErrorMessage;
            let firstErrorKey;
            if (error.response.data?.errors) {
                firstErrorKey = Object.keys(error.response.data.errors)[0];
                firstErrorMessage = error.response.data.errors[firstErrorKey];
            } else if (error.response.data?.error) {
                firstErrorMessage = error.response.data.error;
            } else {
                firstErrorMessage = "Une erreur inattendue s'est produite, veuillez réessayer.";
            }
            toast.error(<ToastError message={firstErrorMessage}/>, toastStyle);
        });
    };

    return (
        <div className="w-full h-full flex flex-col bg-white relative">
            <div className="h-16 flex items-center justify-between px-4 border-b border-border">
                <div className="flex flex-row items-center gap-2">
                    <div className="p-4 hover:bg-gray-100 cursor-pointer rounded flex items-center justify-center">
                        <img src="/resources/arrow_right.svg" className="transform -rotate-180" alt="back icon"
                             onClick={() => navigate(-1)}/>
                    </div>
                    <p className="text-lg sm:text-xl md:text-2xl font-semibold truncate">Ajouter un Service</p>
                </div>
                <div
                    onClick={handleSubmit}
                    className="bg-primaryLight flex items-center justify-center gap-2 px-3 sm:px-4 md:px-6 py-2 md:py-3 w-fit h-fit rounded cursor-pointer"
                >
                    {loading ? (
                        <div className="w-4 sm:w-5 md:w-6">
                            <div className="loader_white"></div>
                        </div>
                    ) : (
                        <img src="/resources/save.svg" alt="save icon" className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"/>
                    )}
                    <p className="text-sm font-semibold text-white">Sauvegarder</p>
                </div>
            </div>

            <div
                className='w-full h-full flex flex-col sm:flex-row items-start max-h-[calc(91vh-112px)] min-h-[calc(91vh-112px)] overflow-y-auto'>
                <div
                    className="w-full sm:w-[30%] h-full flex flex-col border-r border-border gap-6 p-4 sm:overflow-y-auto sm:max-h-[calc(91vh-112px)] sm:min-h-[calc(91vh-112px)] items-start">
                    <div className="flex flex-col gap-4">
                        <AddServicePictures/>
                    </div>
                </div>
                <div
                    className="w-full sm:w-[70%] h-full flex flex-col py-4 px-2 sm:px-4 md:px-8 lg:px-16 gap-4 sm:overflow-y-auto sm:max-h-[calc(91vh-112px)] sm:min-h-[calc(91vh-112px)] items-start">
                    <GeneralInformation
                        categories={categories}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        subCategories={subCategories}
                        selectedSubCategory={selectedSubCategory}
                        setSelectedSubCategory={setSelectedSubCategory}
                        handleServiceDataChange={handleServiceDataChange}
                        serviceInfo={serviceInfo}
                        price={serviceInfo.price}
                        handlePriceChange={(price) => handleServiceDataChange('price', price)}
                    />
                    <ServiceAvailability
                        availableCars={serviceInfo.available_cars}
                        handleAvailableCarsChange={(value) => handleServiceDataChange('available_cars', value)}
                        weekendAvailable={serviceInfo.weekend_available}
                        handleWeekendAvailableChange={(value) => handleServiceDataChange('weekend_available', value)}
                    />
                    <ServiceConditions
                        conditions={conditions}
                        selectedOptions={serviceInfo.conditions_ids}
                        handleConditionsChange={(conditions) => handleServiceDataChange('conditions_ids', conditions)}
                    />
                </div>
            </div>
        </div>
    );

}

const ServiceAvailability = ({
                                 availableCars,
                                 handleAvailableCarsChange,
                                 weekendAvailable,
                                 handleWeekendAvailableChange
                             }) => (
    <div className="flex flex-col gap-4 w-full">
        <p className="leading-none pb-4 border-border border-dashed border-b w-full text-lg md:text-xl font-medium">
            Disponibilité du Service
        </p>

        <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full">
                    <p className="text-black mb-2">Nombre de voitures par jour</p>
                    <input
                        type="number"
                        min="0"
                        value={availableCars || ''}
                        onChange={(e) => handleAvailableCarsChange(Number(e.target.value) || 0)} // Parse as number
                        className="w-full h-10 border border-border rounded px-4"
                    />
                </div>
                <div className="flex items-center gap-2 w-full">
                    <input
                        type="checkbox"
                        checked={Boolean(weekendAvailable)}
                        onChange={(e) => handleWeekendAvailableChange(e.target.checked)}
                        className="w-5 h-5"
                    />
                    <p className="text-black">Disponible le week-end</p>
                </div>
            </div>
        </div>
    </div>
);

const AddServicePictures = () => {
    const [mainImage, setMainImage] = useState(null);
    const [additionalMedia, setAdditionalMedia] = useState([]);
    const [tiktokMedia, setTiktokMedia] = useState([]);
    const [showUrlModal, setShowUrlModal] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [isMainImage, setIsMainImage] = useState(true);
    const [isVideo, setIsVideo] = useState(false);
    const {addPhoto, removePhoto, serviceInfo} = AddServicePageContext();

    const additionalMediaRef = useRef(null);

    const handleMainImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            addPhoto({image_file: file, type: "img"}, true);
        }
    };

    const handleAdditionalMediaUpload = async (e) => {
        const files = Array.from(e.target.files);
        const newMedia = files.map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
            type: "img"
        }));

        setAdditionalMedia(prev => [...prev, ...newMedia]);

        for (const {file} of newMedia) {
            try {
                await addPhoto({image_file: file, type: "img"});
            } catch (error) {
                console.error(`Error uploading image: ${file.name}`, error);
                toast.error(<ToastError message={`Erreur lors de l'upload de l'image ${file.name}.`}/>, toastStyle);
            }
        }
        e.target.value = null;
    };

    const handleUrlMediaSubmit = () => {
        if (mediaUrl) {
            if (isVideo) {
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
                const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

                if (!youtubeRegex.test(mediaUrl) && !tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message="Veuillez entrer une URL YouTube ou TikTok valide."/>, toastStyle);
                    return;
                }
                if (youtubeRegex.test(mediaUrl)) {
                    setAdditionalMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    });
                } else {
                    setTiktokMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        index_at_photos: serviceInfo.photos.length
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "tiktok"
                    });
                }
            } else if (isMainImage) {
                setMainImage(mediaUrl);
                addPhoto({photo_link: mediaUrl, type: "img"}, true);
            } else {
                setAdditionalMedia(prev => [...prev, {url: mediaUrl, name: mediaUrl, type: "img"}]);
                addPhoto({photo_link: mediaUrl, type: "img"});
            }
            setMediaUrl('');
            setShowUrlModal(false);
        }
    };

    const removeAdditionalMedia = (index) => {
        const updatedMedia = additionalMedia.filter((_, i) => i !== index);
        removePhoto(index);
        setAdditionalMedia(updatedMedia);
    };

    const renderMediaItem = (item, index) => {
        if (item.type === "video") {
            const isYouTube = item.url.includes("youtube.com") || item.url.includes("youtu.be");
            if (isYouTube) {
                return (
                    <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                        <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.url)}`}
                            className="w-full h-full"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <button
                            onClick={() => removeAdditionalMedia(index)}
                            className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                        >
                            <img src="/resources/close.svg" alt="close icon"/>
                        </button>
                    </div>
                )
            }
        } else {
            return (
                <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                    <img src={item.url} alt={`additional-${index}`} className="w-full h-full object-contain"/>
                    <button
                        onClick={() => removeAdditionalMedia(index)}
                        className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                    >
                        <img src="/resources/close.svg" alt="close icon"/>
                    </button>
                </div>
            );
        }
    };

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    const removeTikTokMedia = (indexToRemove, index_at_photos) => {
        setTiktokMedia(prevMedia => prevMedia.filter((_, index) => index !== indexToRemove));
        removePhoto(index_at_photos)
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <p className="leading-none pb-4 border-b border-border font-medium text-xl">Images et vidéos du service</p>

            {/* Main Image Section */}
            <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium">Image principale </p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">Ajouter
                                une image</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis une URL"
                            index={2}
                            handleClick={() => {
                                setIsMainImage(true);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => document.getElementById('mainImageInput').click()}
                        />
                    </GenericDropDown>
                    <input
                        id="mainImageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={handleMainImageUpload}
                    />
                </div>
                <img src={mainImage || "/images/placeholder.png"} alt="product"
                     className="w-full h-[200px] object-contain bg-gray-100"/>
            </div>

            {/* Additional Media Section */}
            <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium">Images et vidéos supplémentaires</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">Ajouter
                                des médias</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis une URL"
                            index={2}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => additionalMediaRef.current.click()}
                        />
                        <GenericDropDownItem
                            value="Vidéo YouTube ou Tiktok"
                            index={4}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(true);
                                setShowUrlModal(true);
                            }}
                        />
                    </GenericDropDown>
                    <input
                        ref={additionalMediaRef}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        style={{display: 'none'}}
                        onChange={handleAdditionalMediaUpload}
                    />
                </div>

                {/* Display additional media or placeholders */}
                <div className="flex flex-row flex-wrap items-center gap-4 w-full">
                    {additionalMedia.map((item, index) => renderMediaItem(item, index))}
                    {Array.from({length: Math.max(0, 3 - additionalMedia.length)}).map((_, index) => (
                        <div key={`placeholder-${index}`} className="relative w-[31%] h-32 bg-gray-100">
                            <img src="/images/placeholder.png" alt="placeholder"
                                 className="w-full h-full object-contain"/>
                        </div>
                    ))}
                </div>
                {tiktokMedia.map((item, index) => (
                    <div key={index} className="flex flex-col border border-border rounded">
                        <div
                            className="flex flex-row items-center min-h-12 border-b-border border-b pl-4 pr-2 justify-between">
                            <p className="font-medium">tiktok video N° {index + 1}</p>
                            <button
                                onClick={() => removeTikTokMedia(index, item.index_at_photos)}
                                className="bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                            >
                                <img src="/resources/close.svg" alt="close icon"/>
                            </button>
                        </div>
                        <iframe
                            key={index}
                            src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.url)}`}
                            className="w-full"
                            width="100%"
                            height="100%"
                            allowFullScreen
                        />
                    </div>
                ))}
            </div>

            {/* URL Modal */}
            {showUrlModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 border-border border rounded-lg shadow-lg w-[400px]">
                        <h2 className="text-lg font-semibold mb-4">
                            {isVideo ? "Ajouter une vidéo YouTube ou Tiktok" : "Ajouter une image depuis une URL"}
                        </h2>
                        <input
                            type="text"
                            className="w-full border p-2 rounded mb-4 h-10 placeholder:text-sm focus:outline-primaryLight"
                            placeholder={isVideo ? "Entrez l'URL de la vidéo YouTube ou Tiktok" : "Entrez l'URL de l'image"}
                            value={mediaUrl}
                            onChange={(e) => setMediaUrl(e.target.value)}
                        />
                        <div className="flex justify-end gap-4">
                            <button
                                className="border border-border text-black px-4 py-2 rounded font-medium hover:bg-gray-50"
                                onClick={() => setShowUrlModal(false)}
                            >
                                Annuler
                            </button>
                            <div
                                onClick={handleUrlMediaSubmit}
                                className="bg-primaryLight flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer"
                            >
                                <img src="/resources/save.svg" alt="save icon"/>
                                <p className="text-sm font-semibold text-white">Ajouter</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const GeneralInformation = ({
                                categories,
                                selectedCategory,
                                setSelectedCategory,
                                subCategories,
                                setSelectedSubCategory,
                                handleServiceDataChange,
                                serviceInfo,
                                price,
                                handlePriceChange
                            }) => {

    const insurePriceValueRange = (e, min, max) => {
        const value = e.target.value;

        if (value === '' || !isNaN(value)) {
            const numericYear = Number(value);
            if (numericYear < min) {
                e.target.value = min;
                handlePriceChange(min);
            } else if (numericYear > max) {
                e.target.value = max;
                handlePriceChange(max);
            } else {
                handlePriceChange(numericYear);
            }
        }
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col md:flex-row gap-4 w-full">
                <div className="flex flex-col w-full">
                    <p className="text-black mb-2">Nom du service</p>
                    <input
                        type="text"
                        value={serviceInfo.name || ''}
                        onChange={(e) => handleServiceDataChange('name', e.target.value)}
                        className="w-full h-10 border border-border rounded px-4"
                    />
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black">Prix</p>
                    <input
                        type="number"
                        placeholder={'0'}
                        value={price}
                        onChange={(e) => insurePriceValueRange(e, 0, 10000000000)}
                        className="w-full h-10 border border-border rounded px-4"
                    />
                </div>

            </div>

            <div className="flex flex-col md:flex-row gap-4 w-full">
                <div className="flex flex-col w-full">
                    <p className="text-black mb-2">Catégorie</p>
                    <GenericSelect
                        inputs={categories.map((category) => ({
                            value: category.id,
                            label: category.category_name,
                        }))}
                        defaults={
                            selectedCategory ? [selectedCategory] : []
                        }
                        isMulti={false}
                        handleChanges={(selected) => {
                            setSelectedCategory(selected);
                            handleServiceDataChange('category_id', selected.value);
                        }}
                        setWidth="100%"
                    />
                </div>
                {selectedCategory && (
                    <div className="flex flex-col w-full">
                        <p className="text-black mb-2">Sous-catégorie</p>
                        <GenericSelect
                            inputs={subCategories.map((sub) => ({value: sub.id, label: sub.name}))}
                            defaults={
                                serviceInfo.sub_category_id
                                    ? subCategories
                                        .filter((sub) => sub.id === serviceInfo.sub_category_id)
                                        .map((sub) => ({value: sub.id, label: sub.name}))
                                    : []
                            }
                            isMulti={false}
                            handleChanges={(selected) => {
                                setSelectedSubCategory(selected);
                                handleServiceDataChange('sub_category_id', selected.value);
                            }}
                            setWidth="100%"
                        />
                    </div>
                )}
            </div>

            <div className="flex flex-col w-full">
                <p className="text-black mb-2">Description</p>
                <textarea
                    value={serviceInfo.description || ''}
                    onChange={(e) => handleServiceDataChange('description', e.target.value)}
                    className="w-full h-[115px] border border-border rounded px-4 py-2"
                />
            </div>
        </div>
    )
};

const ServiceConditions = ({conditions, selectedOptions, handleConditionsChange}) => {
    const [selectedServices, setSelectedServices] = useState([]);

    const handleAddService = (selected) => {

        const selectedFullObjects = selected.map((selectedOption) => {
            return conditions.find((condition) => condition.id === selectedOption.value);
        });

        setSelectedServices(selectedFullObjects);
        const selectedValues = selectedFullObjects.map((condition) => condition.id);
        handleConditionsChange(selectedValues);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <p className="leading-none pb-4 border-border border-dashed border-b w-full text-xl font-medium">
                Conditions du Service
            </p>
            <div className="relative flex flex-col gap-2 w-full">
                <GenericSelect
                    placeholder="Conditions du Service"
                    inputs={conditions.map((condition) => ({
                        value: condition.id,
                        label: condition.name,
                    }))}
                    defaults={conditions
                        .filter((condition) => selectedOptions.includes(condition.id))
                        .map((condition) => ({
                            value: condition.id,
                            label: condition.name,
                        }))}
                    isMulti={true}
                    handleChanges={handleAddService}
                    setWidth="100%"
                />
            </div>

            {selectedServices.length > 0 &&
                <div className="flex flex-col gap-4 mt-8">
                    <table className="min-w-full table-auto border-collapse">
                        <thead>
                        <tr className="bg-gray-100 text-gray-700">
                            <th className="px-4 py-3 text-left font-medium border-b border-gray-300">Nom</th>
                            <th className="px-4 py-3 text-left font-medium border-b border-gray-300">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedServices.map((service, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                <td className="px-4 py-3 border-b border-gray-200 text-gray-900">{service.name}</td>
                                <td className="px-4 py-3 border-b border-gray-200 text-gray-700">{service.condition_description || "N/A"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};
