import Carousel from "../Carousel/Carousel";
import ProductCard from "../Cards/ProductCard";
import {useEffect, useRef, useState} from "react";
import {
    getSubCategoryByCategoryId, GetNewProducts, GetTopProducts
} from "../../../../services/marketplace/API";
import {useCompatibleVehicleContext} from "../../../../components/CompatibleVehicules/CompatibleVehicles";
import {useTranslation} from "react-i18next";
import {useMarketplaceContext} from "../../index";
import {useNavigate} from "react-router";

export default function CategoryListing({id, img, category_name, type}) {
    return (

        <div
            className="flex flex-col sm:flex-row gap-4 max-w-full py-4 items-start sm:items-center px-6 sm:px-[6vw] lg:px-[10vw]">
            <CategoryCard img={img} category_name={category_name} id={id}/>
            <TabItemListing
                category_id={id}
                type={type}
            />
        </div>

    )
}

const CategoryCard = ({img, category_name, id}) => {
    const {updateCategoryId} = useMarketplaceContext();
    const [subCategories, updateSubCategories] = useState(null);
    const {t} = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        getSubCategoryByCategoryId(id).then((response) => {
            if (response.status === 200) {
                updateSubCategories(response.data)
            }
        }).catch((e) => {
            console.log(e)
        })
    }, []);
    return (
        <div
            className="flex flex-row sm:flex-col items-center gap-2 w-full sm:min-w-[250px] sm:max-w-[250px] group h-16 sm:min-h-[415px] relative rounded-lg overflow-hidden p-4">
            <img src={img} alt="bg" className="absolute top-0 left-0 h-auto object-cover min-w-full min-h-full"
                 loading={"lazy"}/>
            <div
                className="w-full h-full bg-gradient-to-b from-[rgba(255,87,34,0.8)] to-[rgba(255,87,34,0.3)] hidden group-hover:block absolute top-0 left-0"></div>
            <div
                className="w-full sm:w-[250px] h-16 sm:h-[415px] z-50 flex flex-row sm:flex-col items-center justify-between">
                <p className="text-white text-lg font-bold z-50">
                    {category_name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </p>
                <div className="hidden sm:flex flex-col items-start gap-4 z-50">
                    {subCategories?.map((subCategory, index) => (
                        <p key={index}
                           className="text-white font-medium text-center">{subCategory.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                    ))}
                </div>
                <div
                    onClick={() => {
                        navigate("/search");
                        updateCategoryId(id);
                    }}
                    className="w-[150px] h-[40px] bg-white rounded flex items-center justify-center cursor-pointer overflow-hidden relative group"
                >
                    <div
                        className="absolute inset-0 bg-primaryLight rounded-full w-[110%] pb-[110%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform duration-700 ease-[cubic-bezier(0.1,0,0.3,1)] scale-0 group-hover:scale-100"></div>
                    <div
                        className="absolute inset-0 bg-primaryLight opacity-0 transition-opacity duration-700 group-hover:opacity-100 group-hover:duration-[0.01s] group-hover:delay-[0.8s]"></div>
                    <span className="relative z-10 text-primaryLight group-hover:text-white font-medium">{t("Category.discover")}</span>
                </div>
            </div>
        </div>
    )
}

const TabItemListing = ({category_id, type}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const {motorisation, brand, model} = useCompatibleVehicleContext();
    const ref = useRef();
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true)
        setItems([])
        if (type === 0) {
            if (currentIndex === 0) {
                GetNewProducts({
                    vehicle_brand_id: brand?.id,
                    vehicle_model_id: model?.id,
                    motorisation_id: motorisation?.id
                }, category_id).then((response) => {
                    if (response.status === 200) {
                        setItems(response.data)
                    }
                    setLoading(false)
                }).catch((e) => {
                    setLoading(false)
                    console.log(e)
                })
            } else if (currentIndex === 1) {
                GetTopProducts({
                    vehicle_brand_id: brand?.id,
                    vehicle_model_id: model?.id,
                    motorisation_id: motorisation?.id
                }, category_id).then((response) => {
                    if (response.status === 200) {
                        setItems(response.data)
                    }
                    setLoading(false)
                }).catch((e) => {
                    setLoading(false)
                    console.log(e)
                })
            }
        }
    }, [brand?.id, category_id, currentIndex, model?.id, motorisation?.id, type]);
    return (
        <div className="flex flex-col gap-2 py-4 h-fit w-full sm:w-[calc(100%-250px)]">
            <div ref={ref} className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center border-b border-b-border">
                    <div className="flex flex-row items-center">
                        <div
                            onClick={() => setCurrentIndex(0)}
                            style={{borderBottom: currentIndex === 0 ? "1px solid #E44826" : "none"}}
                            className="cursor-pointer px-6 py-1 flex items-center justify-center border-b-[2px] border-b-primaryDark">
                            <p className="font-medium text-ellipsis whitespace-nowrap">{t("Category.new")}</p>
                        </div>
                        <div
                            onClick={() => setCurrentIndex(1)}
                            style={{borderBottom: currentIndex === 1 ? "1px solid #E44826" : "none"}}
                            className="cursor-pointer px-6 py-1 flex items-center justify-center border-b-[2px] border-b-primaryDark">
                            <p className="font-medium text-ellipsis whitespace-nowrap">{t("Category.BestNoted")}</p>
                        </div>
                    </div>
                </div>
                {loading ?
                    <div className="w-full h-full min-h-[416px] flex items-center justify-center">
                        <div className="loader"></div>
                    </div>
                    :
                    items.length === 0 ?
                        <div className="w-full h-full min-h-[416px] flex items-center justify-center">
                            <p>{t('none.article')}</p>
                        </div>
                        :
                        <Carousel
                            ref={ref}
                            items={items}
                            renderItem={(item) => (<ProductCard{...item} first_photo={item.first_photo}/>)}
                        />
                }
            </div>
        </div>
    )
}