import { api } from '../instance';

const baseURL = '/api';

// Helper functions for handling success and error responses
const handleSuccess = (response) => {
    console.log('Success:', response.data);
    return response.data;
};

const handleError = (error) => {
    console.error('Error:', error.response?.data || error.message);
    return error.response?.data || error.message;
};

// Admin Stats
export async function AdminStats() {
    return api.get(`${baseURL}/admin/stats`).then(handleSuccess).catch(handleError);
}

export async function getStatsData() {
    return api.get(`${baseURL}/stats`).then(handleSuccess).catch(handleError);
}

// Category APIs
export const getCategories = async () => {
    return api.get(`${baseURL}/categories`).then(handleSuccess).catch(handleError);
};

export const addCategory = async (data) => {
    return api.post(`${baseURL}/categories`, data).then(handleSuccess).catch(handleError);
};

export const updateCategory = async (id, data) => {
    return api.patch(`${baseURL}/categories/${id}`, data).then(handleSuccess).catch(handleError);
}

export const deleteCategory = async (id) => {
    return api.delete(`${baseURL}/categories/${id}`).then(handleSuccess).catch(handleError);
}

export const toggleMonthlyCategories = async (category_ids, set) => {
    return api.patch(`${baseURL}/toggle-monthly`, { category_ids, set }).then(handleSuccess).catch(handleError);
};

// Subcategory APIs
export const getSubCategories = async () => {
    return api.get(`${baseURL}/sub_categories`).then(handleSuccess).catch(handleError);
};

export const addSubCategory = async (data) => {
    return api.post(`${baseURL}/sub_categories`, data).then(handleSuccess).catch(handleError);
};

export const showSubCategory = async (id) => {
    return api.get(`${baseURL}/sub_categories/${id}`).then(handleSuccess).catch(handleError);
}

export const updateSubCategory = async (id, data) => {
    return api.patch(`${baseURL}/sub_categories/${id}`, data).then(handleSuccess).catch(handleError);
}

export const deleteSubCategory = async (id) => {
    return api.delete(`${baseURL}/sub_categories/${id}`).then(handleSuccess).catch(handleError);
}

// Brand APIs
export const getBrands = async () => {
    return api.get(`${baseURL}/vehicle-brands`).then(handleSuccess).catch(handleError);
};

export const createVehiculeBrand = async (data) => {
    return api.post(`${baseURL}/vehicle-brands`, data).then(handleSuccess).catch(handleError);
};

export const deleteBrand = async (id) => {
    return api.delete(`${baseURL}/vehicle-brands/${id}`).then(handleSuccess).catch(handleError);
}
export const updateBrand = async (id, data) => {
    return api.patch(`${baseURL}/vehicle-brands/${id}`, data).then(handleSuccess).catch(handleError);
}

export const getProductBrands = async () => {
    return api.get(`${baseURL}/brands/products`).then(handleSuccess).catch(handleError);
};

export const addProductBrand = async (data, type) => {
    return api.post(`${baseURL}/brands`, data, type).then(handleSuccess).catch(handleError);
};
export const updateProductBrand = async (id, data) => {
    return api.patch(`${baseURL}/brands/${id}`, data).then(handleSuccess).catch(handleError);
}

export const deleteProductBrand = async (id) => {
    return api.delete(`${baseURL}/brands/${id}`).then(handleSuccess).catch(handleError);
}

// Vehicle Model APIs
export const getModels = async () => {
    return api.get(`${baseURL}/vehicle-models`).then(handleSuccess).catch(handleError);
};

export const createModel = async (data) => {
    return api.post(`${baseURL}/vehicle-models`, data).then(handleSuccess).catch(handleError);
};

export const deleteModel = async (id) => {
    return api.delete(`${baseURL}/vehicle-models/${id}`).then(handleSuccess).catch(handleError);
}
export const updateModel = async (id, data) => {
    return api.patch(`${baseURL}/vehicle-models/${id}`, data).then(handleSuccess).catch(handleError);
}

// Motorisation APIs
export const getMotorisations = async () => {
    return api.get(`${baseURL}/motorisation`).then(handleSuccess).catch(handleError);
};

export const createMotorisation = async (data) => {
    return api.post(`${baseURL}/motorisation`, data).then(handleSuccess).catch(handleError);
};

export const deleteMotorisation = async (id) => {
    return api.delete(`${baseURL}/motorisation/${id}`).then(handleSuccess).catch(handleError);
}
export const updateMotorisation = async (id, data) => {
    return api.patch(`${baseURL}/motorisation/${id}`, data).then(handleSuccess).catch(handleError);
}
// User APIs
export const getUsers = async () => {
    return api.get(`${baseURL}/users`).then(handleSuccess).catch(handleError);
};

export const createUser = async (data) => {
    return api.post(`${baseURL}/users`, data).then(handleSuccess).catch(handleError);
};

export const showUser = async (id) => {
    return api.get(`${baseURL}/users/${id}`).then(handleSuccess).catch(handleError);
};

export const deleteUser = async (id) => {
    return api.delete(`${baseURL}/users/${id}`).then(handleSuccess).catch(handleError);
};

export const changeUserStatus = async (user_ids, status, justification) => {
    return api.patch(`${baseURL}/users/status`, { user_ids, status, justification }).then(handleSuccess).catch(handleError);
};

// Product APIs
export const getProducts = async () => {
    return api.get(`${baseURL}/all-products`).then(handleSuccess).catch(handleError);
};

export const updateProductStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/products/status/products`, { ids, status, justification }).then(handleSuccess).catch(handleError);
};

export const deleteProduct = async (id) => {
    return api.delete(`${baseURL}/products/${id}`).then(handleSuccess).catch(handleError);
}

// Service APIs
export const getServices = async () => {
    return api.get(`${baseURL}/services`).then(handleSuccess).catch(handleError);
};

export const updateServiceStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/services/status/services`, { ids, status, justification }).then(handleSuccess).catch(handleError);
};

export const deleteService = async (id) => {
    return api.delete(`${baseURL}/services/${id}`).then(handleSuccess).catch(handleError);
}

// Vehicle APIs
export const getVehicles = async () => {
    return api.get(`${baseURL}/vehicles`).then(handleSuccess).catch(handleError);
};

export const createVehicle = async (data) => {
    return api.post(`${baseURL}/vehicles`, data).then(handleSuccess).catch(handleError);
};

export const showVehicle = async (id) => {
    return api.get(`${baseURL}/vehicles/${id}`).then(handleSuccess).catch(handleError);
};
export const updateVehicle = async (id, data) => {
    return api.patch(`${baseURL}/vehicles/${id}`, data).then(handleSuccess).catch(handleError);
}

export const deleteVehicle = async (id) => {
    return api.delete(`${baseURL}/vehicles/${id}`).then(handleSuccess).catch(handleError);
}

export const ImportVehicle = async (data) => {
    return api.post(`${baseURL}/vehicles/csv`, data).then(handleSuccess).catch(handleError);
}

// Subscription APIs
export const getSubscriptions = async () => {
    return api.get(`${baseURL}/subscriptions`).then(handleSuccess).catch(handleError);
};

export const getShopSubscriptions = async () => {
    return api.get(`${baseURL}/shop-subscriptions`).then(handleSuccess).catch(handleError);
};

export const createSubscription = async (data) => {
    return api.post(`${baseURL}/subscriptions`, data).then(handleSuccess).catch(handleError);
};

export const deleteSubscription = async (id) => {
    return api.delete(`${baseURL}/subscriptions/${id}`).then(handleSuccess).catch(handleError);
}
export const updateSubscription = async (id,data) => {
    return api.patch(`${baseURL}/subscriptions/${id}`, data).then(handleSuccess).catch(handleError);
}
export const changeShopSubscriptionStatus = async (id, action, justification) => {
    return api.patch(`${baseURL}/subscriptions/manage/${id}`, {id, action, justification }).then(handleSuccess).catch(handleError);
};

export const getSubscriptionCancellations = async () => {
    return api.get(`${baseURL}/subscription_cancellation/admin`).then(handleSuccess).catch(handleError);
};

export const SubscriptionCancellationsStatus = async (id, status, justification) => {
    return api.patch(`${baseURL}/subscription_cancellation/${id}`, { status, justification }).then(handleSuccess).catch(handleError);
};

// Condition APIs
export const getConditions = async () => {
    return api.get(`${baseURL}/conditions`).then(handleSuccess).catch(handleError);
};

export const addCondition = async (data) => {
    return api.post(`${baseURL}/conditions`, data).then(handleSuccess).catch(handleError);
};

export const showCondition = async (id) => {
    return api.get(`${baseURL}/conditions/${id}`).then(handleSuccess).catch(handleError);
}

export const updateCondition = async (data) => {
    return api.patch(`${baseURL}/conditions/${data.id}`, data).then(handleSuccess).catch(handleError);
}

export const deleteCondition = async (id) => {
    return api.delete(`${baseURL}/conditions/${id}`).then(handleSuccess).catch(handleError);
}

// Car APIs
export const getCars = async () => {
    return api.get(`${baseURL}/all-cars`).then(handleSuccess).catch(handleError);
};

export const updateCarStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/cars/status/cars`, { ids, status, justification }).then(handleSuccess).catch(handleError);
};

// Offer APIs
export const getOffers = async () => {
    return api.get(`${baseURL}/all-offers`).then(handleSuccess).catch(handleError);
};

export const updateOfferStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/offers/status/offers`, {ids, status, justification}).then(handleSuccess).catch(handleError);
}

// Promotion APIs
export const getPromotions = async () => {
    return api.get(`${baseURL}/promotions`).then(handleSuccess).catch(handleError);
}

// Engine APIs
export const getEnginBrands = async () => {
    return api.get(`${baseURL}/engins-brands`).then(handleSuccess).catch(handleError);
}
export const updateEnginBrands = async (id,data) => {
    return api.patch(`${baseURL}/engins-brands/${id}`, data).then(handleSuccess).catch(handleError);
}

export const getEnginCategories = async () => {
    return api.get(`${baseURL}/engins-categories`).then(handleSuccess).catch(handleError);
}
export const updateEnginCategories = async (id,data) => {
    return api.patch(`${baseURL}/engins-categories/${id}`, data).then(handleSuccess).catch(handleError);
}

export const getEngins = async () => {
    return api.get(`${baseURL}/engins`).then(handleSuccess).catch(handleError);
}

export const UpdateEnginStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/engins/status/engins`, {ids, status, justification}).then(handleSuccess).catch(handleError);
}

// Boat APIs
export const changeBoatStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/boats/status/boats`, {ids, status, justification}).then(handleSuccess).catch(handleError);
}